import React, { useContext } from "react";
import { LanguageContext } from "../../Context/LanguageContext";
import { useTheme } from "@mui/system";

export default function ErrorMsgComponent({ validationError, id }) {
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  return validationError?.find((x) => x?.key === id) ? (
    <div
      style={{
        color: theme.palette.mode === "light" ? "#BE3144" : "rgb(255 40 69)",
        direction: language === "ar" ? "rtl" : "ltr",
        textAlign: language === "ar" ? "right" : "left",
        paddingRight: "0.5rem",
        fontSize: "0.8rem",
        paddingLeft: "0.5rem",
      }}
    >
      {validationError.find((x) => x.key === id)?.message}
    </div>
  ) : (
    ""
  );
}
