export const Chat_EN_json = {
    delete: {
        done: "Chat Deleted Successfully",
        title: "Delete chat",
        content: "Are you sure you want to delete chat ?",
        cancle: "Cancel",
        delete: "Delete",
    },
    msgdelete: {
        title: "Delete message ?",
        op1: " Delete for everyone",
        op2: "Delete for me",
        op3: "Cancel",
    },
    select: "Select chat to start messaging.",

}
// export const Chat_AR_json = {
//     delete: {
//         done: "تم حذف الدردشة بنجاح",
//         title: "حذف الدردشة",
//         content: "هل أنت متأكد أنك تريد حذف الدردشة؟",
//         cancle: "يلغي",
//         delete: "حذف",
//     },

//     msgdelete: {
//         title: "حذف رسالة ؟",
//         op1: "الحذف للجميع",
//         op2: "حذف بالنسبة لي",
//         op3: "يلغي",

//     },
//     select: "حدد الدردشة لبدء المراسلة",

// }

export const Chat_AR_json = {
    delete: {
        done: "تم حذف الدردشة بنجاح",
        title: "حذف الدردشة",
        content: "هل أنت متأكد أنك تريد حذف الدردشة؟",
        cancel: "إلغاء",
        delete: "حذف",
    },
    msgdelete: {
        title: "حذف رسالة؟",
        op1: "حذف للجميع",
        op2: "حذف بالنسبة لي",
        op3: "إلغاء",
    },
    select: "حدد الدردشة لبدء المراسلة",
};
