import { Box, CircularProgress, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import formValidator from "../../../common/Validator";
import { useTheme } from "@mui/material/styles";
import InputComponent from "../../../component/Input/InputComponentTextField";
import InputComponentOutline from "../../../component/Input/InputComponentOutline";
import apiHelper from "../../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import ErrorMsgComponent from "../../../component/Input/ErrorMsgComponent";
import Path from "../../../common/Path";
import { baseUrl } from "../../../common/Constant";

export default function AgentFormComponent({ googleLoginData }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [validationError, setValidationError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPasword, setShowconfirmPasword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const theme = useTheme();
  const navigate = useNavigate();
  const [agencyList, setAgencyList] = useState([]);
  const location = useLocation();
  const [isGoogleLogin, setIsGoogleLogin] = useState();
  const [phoneValidationError, setphoneValidationError] = useState("");
  const [agencyLoading, setagencyLoading] = useState(true);
  const handleFileChange = (event, property) => {
    const selectedFile = event.target.files[0];
    setFormDetails({ ...formDetails, [property]: selectedFile });
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPasswordConfPass = () =>
    setShowconfirmPasword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initial = {
    role: 1,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    civilId: "",
    confirmPasword: "",
    description: "",
    image: {},
    agency_id: "0",
  };

  const [formDetails, setFormDetails] = useState(initial);

  const getAgencyList = async () => {
    try {
      const result = await apiHelper.fetchAgencyList();
      if (result?.data?.r) {
        setAgencyList(result?.data?.r);
      } else {
        toast.warn(result?.data?.m);
      }
      setagencyLoading(false);
    } catch (error) {
      setagencyLoading(false);
      toast.error(error);
    }
  };

  const handlePhoneChange = (phone, cc) => {
    if (phone) {
      setFormDetails({ ...formDetails, phoneNumber: phone, country_code: cc });
    } else {
      setphoneValidationError(
        language === "en" ? "Please enter valid number" : "رقم غير صالح"
      );
      return;
    }
  };

  const HandleFormSubmittedDetails = async () => {
    try {
      setIsSubmitted(true);
      const validationResult = formValidator(
        formDetails,
        "agent",
        isGoogleLogin,
        t
      );
      if (validationResult.length > 0) {
        setValidationError(validationResult);
        return;
      }
      if (formDetails.phoneNumber === "") {
        setphoneValidationError(
          language === "en"
            ? "Required field number is empty"
            : "رقم الحقل المطلوب فارغ"
        );
        return;
      } else {
        setIsLoading(true);
        setphoneValidationError("");
        setValidationError([]);
        const result = await apiHelper.RegisterAgent(
          formDetails,
          isGoogleLogin
        );
        if (result?.data?.r) {
          const token = result?.data?.r?.token;
          const userInfo = result?.data?.r;
          const status = result?.data?.r?.status;
          localStorage.setItem("token", token);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));

          setFormDetails(initial);
          result.data?.s === 1
            ? toast.success(
                language === "en" ? "Registered Successfully" : "مسجل بنجاح"
              )
            : toast.info(result.data?.m);

          status === 2 ? navigate(Path.APPROVAL) : navigate(Path.PROPERTY);
        } else {
          toast.warn(result?.data?.m);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const handleFormDetailsChanges = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "agent",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  useEffect(() => {
    getAgencyList();
  }, []);

  useEffect(() => {
    const nav = location.search.split("?google=")[1];
    if (nav === "1") {
      setIsGoogleLogin(true);
      setFormDetails({
        ...formDetails,
        email: googleLoginData?.email,
        firstName: googleLoginData?.f_name,
        lastName: googleLoginData?.l_name,
        image: googleLoginData?.profile,
      });
    } //eslint-disable-next-line
  }, [googleLoginData]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  return (
    <Box
      sx={{
        borderRadius: "12px",
        padding: { xs: "0", sm: "1.5rem", md: "1.5em 4em" },
        boxShadow: { xs: "", sm: " 4px 4px 13px 0px rgba(0, 0, 0, 0.15)" },
        bgcolor: { xs: "transparent", sm: theme.palette.bg.main },
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - 280px )`,
        overflow: "auto",
        color: theme.palette.dark.main,
        width: { xs: "95%", md: "85%" },
      }}
      className={"HideScrollBar"}
    >
      <Box>
        <h4 style={{ marginBottom: "0px", marginTop: "0px" }}>
          {" "}
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "600",
              fontSize: "1.5em",
            }}
          >
            {t("regi.agent.tagline")}
          </Typography>
        </h4>
        <Typography
          sx={{ color: theme.palette.paragraph.main, fontSize: "0.83em" }}
        >
          {t("regi.tagline")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            md: "flex",
          },
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.fName")}
            </Typography>
            <InputComponent
              type="text"
              name="firstName"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "firstName" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "firstName")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              id="firstName"
              value={formDetails.firstName}
              placeholder={t("regi.fName")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.lName")}
            </Typography>
            <InputComponent
              value={formDetails.lastName}
              id="lastName"
              type="text"
              name="lastName"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "lastName" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "lastName")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              className="form-control"
              placeholder={t("regi.lNamePlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.email")}
            </Typography>
            <InputComponent
              type="email"
              id="email"
              name="email"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "email" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "email")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              value={formDetails.email}
              className="form-control"
              placeholder={t("regi.emailPlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.phone")}
            </Typography>

            <PhoneInput
              country={"kw"}
              value={formDetails.phoneNumber}
              onChange={(number, cc) => {
                handlePhoneChange(number, cc.dialCode);
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
                autoComplete: "off",
              }}
              fullWidth={true}
              inputStyle={{
                width: "100%",
                height: "50px",
                borderRadius: "12px",
                border: "none",
                textAlign: language === "ar" ? "right" : "left",
                padding: "0 4rem",
              }}
              style={{
                color: "#1f2438",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
              containerClass="react-tel-input"
            />
            {
              <div
                style={{
                  direction: language === "ar" ? "rtl" : "ltr",
                  textAlign: language === "ar" ? "right" : "left",
                  color: "#BE3144",
                  fontSize: "0.8rem",
                  pl: "0.5rem",
                  marginTop: "0.5rem",
                }}
              >
                {phoneValidationError}
              </div>
            }
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agent.civilId")}
            </Typography>
            <InputComponent
              type="text"
              name="civilId"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "civilId" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "civilId")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              value={formDetails.civilId}
              id="civilId"
              className="form-control"
              placeholder={t("regi.agent.civilIdPlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agent.photo")}
            </Typography>
            <InputComponentOutline
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              placeholder={t("regi.agent.photo")}
              value={formDetails?.image?.name}
              required={true}
              id="image"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "image" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "image")?.message
              }
              readOnly={true}
              endAdornment={
                <InputAdornment position="end">
                  <input
                    type="file"
                    id="fileInput"
                    hidden
                    accept="image/*"
                    multiple={false}
                    required
                    onChange={(e) => handleFileChange(e, "image")}
                  />
                  <label htmlFor="fileInput">
                    <Button
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        bgcolor: "#1f2438",
                        color: "white ",
                        borderRadius: "7px",
                        padding: "0.01em 1em",
                        "&:hover": {
                          backgroundColor: "#1f2438",
                        },
                      }}
                    >
                      {t("regi.add")}
                    </Button>
                  </label>
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Box sx={{ mb: "0.8rem", width: "100%" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agent.agencyId")}
            </Typography>
            <Select
              id="agency_id"
              value={formDetails.agency_id}
              name="agency_id"
              // IconComponent={ExpandMoreIcon}
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "agency_id" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "agency_id")?.message
              }
              // defaultText={t("regi.agent.agencyIdPlace")}
              fullWidth
              sx={{
                bgcolor: "white",
                borderRadius: "12px",
                width: "100%",
                "& fieldset": { border: "none" },

                color: `#1f2438 `,
                "& .MuiOutlinedInput-input": {
                  padding: "14px ",
                },
                " .MuiOutlinedInput-input": {
                  textAlign: language === "ar" ? "right" : "left",
                },
                // input: {
                //   "&::placeholder": {
                //     textAlign: language === "ar" ? "right" : "left",
                //   },
                // },

                ".MuiSvgIcon-root ": {
                  fill: theme.palette.mode === "dark" && "#1f2438 !important",
                },
              }}
              style={{
                color: "#1f2438",
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "0.5rem",
                    borderRadius: "12px",
                  },
                },
              }}
              // options={
              //   agencyList.length > 2
              //     ? [...agencyList]
              //     : [{ company_name: "Agency data not available." }]
              // }
              // idkey={"id"}
              // valueKey={"id"}
              // listKey={"company_name"}
              // style={{color:"#1f2438"}}
            >
              <MenuItem value="0" disabled style={{ display: "none" }}>
                <span style={{ color: "grey" }}>
                  {/* {t("addProperty.propertyPlace")} */}
                  {t("regi.agent.agencyIdPlace")}
                </span>
              </MenuItem>
              {agencyList.length > 0
                ? agencyList.map((x, i) => (
                    <MenuItem key={i} value={x.id}>
                      {x.agency_logo ? (
                        <>
                          {
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: "0.5rem",
                              }}
                            >
                              <img
                                style={{
                                  height: "35px",
                                  width: "45px",
                                  aspectRatio: "1/1",
                                  borderRadius: "12px",
                                }}
                                src={`${baseUrl}/uploads/agency/logo/${x.agency_logo}`}
                                alt=""
                              />
                              {x.company_name}
                            </Box>
                          }
                        </>
                      ) : (
                        x.company_name
                      )}
                    </MenuItem>
                  ))
                : !agencyLoading && (
                    <MenuItem
                      value={"null"}
                      disabled
                      style={{ direction: language === "ar" ? "rtl" : "ltr" }}
                    >
                      {language === "ar"
                        ? "الوكالة غير متوفرة."
                        : "Agency not available."}
                    </MenuItem>
                  )}
            </Select>
          </Box>

          <Box sx={{ mb: "0.8rem", display: isGoogleLogin ? "none" : "block" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.pass")}
            </Typography>
            <InputComponentOutline
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              id="password"
              name="password"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "password" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "password")?.message
              }
              type={showPassword ? "text" : "password"}
              value={formDetails.password}
              placeholder={t("regi.passPlace")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: "#1f2438" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>

          <Box sx={{ mb: "0.8rem", display: isGoogleLogin ? "none" : "block" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.cPass")}
            </Typography>
            <Box sx={{ display: "flex", position: "relative" }}>
              <InputComponentOutline
                sx={{
                  bgcolor: "white",
                  width: "100%",
                  borderRadius: "12px",
                }}
                id="confirmPasword"
                name="confirmPasword"
                onChange={handleFormDetailsChanges}
                isError={validationError?.find((x) => {
                  return x.key === "confirmPasword" ? true : false;
                })}
                helperText={
                  validationError.find((x) => x.key === "confirmPasword")
                    ?.message
                }
                value={formDetails.confirmPasword}
                type={showconfirmPasword ? "text" : "password"}
                placeholder={t("regi.cPassPlace")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirmPasword visibility"
                      onClick={handleClickShowPasswordConfPass}
                      onMouseDown={handleClickShowPasswordConfPass}
                      edge="end"
                      sx={{ color: "#1f2438" }}
                    >
                      {showconfirmPasword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.des")}
            </Typography>

            <textarea
              placeholder={t("regi.desPlace")}
              value={formDetails.description}
              name="description"
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                borderRadius: "12px",
                padding: "17px 14px",
                border: "1px solid #ced4da",
                outline: "none",
                width: "100%",
                maxWidth: "100%",
                boxSizing: "border-box",
                fontWeight: "400",
                direction: language === "ar" ? "rtl" : "ltr",
                textAlign: language === "ar" ? "right" : "left",
              }}
              onChange={handleFormDetailsChanges}
              id="description"
              rows={1} // Setting rows to 1 for a single line text area
              cols={formDetails.description.length} // Dynamically adjusting cols based on content length
            ></textarea>

            <ErrorMsgComponent
              id={"description"}
              validationError={validationError}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: "1em" }}>
        <Button
          variant="contained"
          size="large"
          sx={{
            padding: "0.3em 5em",
            textTransform: "capitalize",
            bgcolor: theme.palette.gold.main,
            borderRadius: "12px",
            fontSize: "1.1rem",
            color: "white",
          }}
          onClick={HandleFormSubmittedDetails}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              {" "}
              <CircularProgress
                disableShrink
                sx={{ color: "#1f2438", margin: "0.2rem" }}
                size={20}
              />
            </>
          ) : (
            `${t("regi.button")}`
          )}
        </Button>
      </Box>
    </Box>
  );
}
