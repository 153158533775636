import * as React from "react";
import Box from "@mui/material/Box";
import { baseUrl } from "../../../common/Constant";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSwipeable } from "react-swipeable";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "calc(100vh - 50px)",
  borderRadius: "15px",
  boxShadow: 24,
  width: "calc(100% - 50px)",
  padding: "2rem",
  boxSizing: "border-box",
  p: 2,
};

export default function ImageListModal({ number, data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [activeImgIndex, setActiveImgIndex] = React.useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  const thumbnailRefs = React.useRef([]);

  const handleVideoLoad = () => {
    setLoading(false);
  };

  const handleNext = () => {
    setActiveImgIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % data.length;
      thumbnailRefs.current[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      return newIndex;
    });
  };

  const handlePrev = () => {
    setActiveImgIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + data.length) % data.length;
      thumbnailRefs.current[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      return newIndex;
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div>
      <Box
        onClick={handleOpen}
        sx={{
          position: "absolute",
          color: "#ffffff9e",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: number > 0 ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "12px",
          width: "75%",
          height: "75%",
          fontSize: { xs: "1.2rem", sm: "2rem", md: "3rem", lg: "4rem" },
        }}
      >
        +{number}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ background: "#1f2438" }}
      >
        <Box sx={style} {...handlers}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                padding: "0.3em 1em",
                textTransform: "capitalize",
                // bgcolor: theme.palette.gold.main,
                bgcolor: "#D4AB77",
                borderRadius: "12px",
                fontSize: "1.1rem",
                color: "white",
                "&:hover": {
                  bgcolor: "#D4AB77",
                },
              }}
              onClick={handleClose}
            >
              {t("addProperty.photo.close")}
            </Button>
          </Box>
          <Box
            sx={{
              height: "calc(100vh - 150px)",
              width: "100%",
              display: "flex",
              boxSizing: "border-box",
              padding: { xs: "0", md: "2rem" },
              alignItems: "center",
              justifyContent: "center",

              position: "relative",
            }}
          >
            <IconButton
              onClick={handlePrev}
              sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
                color: "#D4AB77",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Box
              sx={{
                height: "calc(95vh )",
                width: { xs: "100%", md: "75%" },
                zIndex: "-1",
              }}
            >
              {data[0]?.image && data[activeImgIndex]?.image && (
                <img
                  src={`${baseUrl}/uploads/property/image/${data[activeImgIndex]?.image}`}
                  height={"100%"}
                  width={"100%"}
                  alt={`Slider-${data[activeImgIndex]?.id}`}
                  style={{ borderRadius: "15px", objectFit: "contain" }}
                />
              )}
              {/* {data[activeImgIndex].video && (
                <video
                  key={data[activeImgIndex].video}
                  autoPlay
                  height={"100%"}
                  width={"100%"}
                  muted
                  loop
                >
                  <source
                    src={`${baseUrl}/uploads/property/video/${data[activeImgIndex].video}`}
                    style={{ borderRadius: "15px", objectFit: "cover" }}
                  />
                </video>
              )} */}
              {data[0]?.video && data[activeImgIndex]?.video && (
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {loading && (
                    <CircularProgress
                      size={40}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                  <video
                    key={data[activeImgIndex]?.video}
                    autoPlay
                    height={"100%"}
                    width={"100%"}
                    muted
                    loop
                    onCanPlayThrough={handleVideoLoad}
                    style={{
                      display: loading ? "none" : "block",
                      borderRadius: "15px",
                      objectFit: "cover",
                    }}
                  >
                    <source
                      src={`${baseUrl}/uploads/property/video/${data[activeImgIndex]?.video}`}
                    />
                  </video>
                </div>
              )}
            </Box>
            <IconButton
              onClick={handleNext}
              sx={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
                color: "#D4AB77",
                "&:hover": {
                  // transform: "Scale(1.1)",
                  color: "white",
                },
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              height: "20%",
              width: "100%",
              position: 'absolute',
              bottom: '0',
              display: { xs: "none", md: "flex" },
              boxSizing: "border-box",
              padding: { xs: "0", md: "1.5rem" },
              alignItems: "center",
              justifyContent: "start",
              overflowX: "auto",
              overflowY: "hidden",
              gap: "1rem",
            }}
          >
            {data &&
              data?.map((x, i) => (
                <Box
                  {...handlers}
                  key={i}
                  ref={(el) => (thumbnailRefs.current[i] = el)}
                  sx={{
                    height: "100%",
                    width: { xs: "100%", md: "32%", lg: "20%" },
                    flex: "0 0 auto",
                    cursor: "pointer",
                    border: activeImgIndex === i ? `3px solid #D4AB77` : "none",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    setActiveImgIndex(i);
                    thumbnailRefs.current[i].scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }}
                >
                  {x.image && (
                    <img
                      src={`${baseUrl}/uploads/property/image/${x.image}`}
                      height={"100%"}
                      width={"100%"}
                      alt={`Slider-${x.id}`}
                      style={{ borderRadius: "15px", objectFit: "cover" }}
                    />
                  )}
                  {x.video && (
                    <video
                      key={x.video}
                      height={"100%"}
                      width={"100%"}
                      muted
                      controls
                      style={{ objectFit: "cover", borderRadius: "15px" }}
                    >
                      <source
                        src={`${baseUrl}/uploads/property/video/${x?.video}`}
                      />
                    </video>
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
