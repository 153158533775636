export const Footer_EN_json = {
    service: {
        title: "Aldera Service",
        eva: "Property Evaluation",
        rent: "Rent Property",
        sell: "Sell Property",
        agent: "Login as agent also",
        buy: "Buy Property"
    },
    sub: {
        title: "Sign up for newsletter",
        content: "Stay informed and ahead of the curve with our exclusive newsletter! Subscribe now to receive the latest updates. market insights, and special offers directly to your inbox.",
        email: "Email",
        button: "Subscribe",
    },
    tc: "Terms & Conditions",
    pp: "Privacy Policy",

}


// export const Footer_AR_json = {
//     service: {
//         title: "خدمة ألديرا",
//         eva: "تقييم الممتلكات",
//         rent: "إيجار العقارات",
//         sell: "بيع الممتلكات",
//         agent: "تسجيل الدخول كوكيل أيضا",
//         buy: "شراء العقارات",
//     },
//     sub: {
//         title: "الاشتراك في النشرة الإخبارية",
//         content: "ابق على اطلاع وفي الطليعة من خلال النشرة الإخبارية الحصرية لدينا! اشترك الآن للحصول على آخر التحديثات. رؤى السوق، والعروض الخاصة مباشرة إلى صندوق الوارد الخاص بك.",
//         email: "بريد إلكتروني",
//         button: "يشترك",
//     }

// }

export const Footer_AR_json = {
    service: {
        title: "خدمات ألديرا",
        eva: "تقييم الممتلكات",
        rent: "استئجار الممتلكات",
        sell: "بيع الممتلكات",
        agent: "تسجيل الدخول كوكيل أيضًا",
        buy: "شراء الممتلكات",
    },
    sub: {
        title: "اشترك في النشرة الإخبارية",
        content: "ابقَ مطلعًا ومتقدمًا من خلال نشرتنا الإخبارية الحصرية! اشترك الآن لتصلك آخر التحديثات ورؤى السوق والعروض الخاصة مباشرة إلى بريدك الوارد.",
        email: "البريد الإلكتروني",
        button: "اشترك",
    }, tc: "الشروط والأحكام",
    pp: "سياسة الخصوصية",
}
