// English
export const Notification_EN_json = {
    title: "Notification",
    NoData: "No notifications to display.",
    accepted: "Accepted",
    reject: "Rejected",
    propertyAvailable:"Property not available."
};

// Arabic
export const Notification_AR_json = {
    title: "إشعار",
    NoData: "لا توجد إخطارات لعرضها.",
    accepted: "قبلت",
    reject: "مرفوض",
    propertyAvailable:"العقار غير متاح."
};
