export const Registration_EN_json = {

    tagline: "Create an account to continue !",
    fName: "First Name",
    fNamePlace: "Enter first name",
    lName: "Last Name",
    lNamePlace: "Enter last name",
    email: "Email Address",
    emailPlace: "Enter email ",
    pass: "Password",
    passPlace: "Enter password",
    cPass: "Confirm Password",
    cPassPlace: "Enter confirm password",
    phone: "Phone Number",
    des: "Description",
    desPlace: "description",
    button: "Register",
    add: "Browse",

    customer: {
        title: "Customer",
        tagline: "Customer Registration",
    },

    agent: {
        title: "Agent",
        tagline: "Agent Registration",
        civilId: "Civil ID Number*",
        civilIdPlace: "Enter Civil ID Number*",
        photo: "Upload Photo",
        photoPlace: "{{name}}",
        agencyId: "Agency",
        agencyIdPlace: "Select Agency",
    },

    agency: {
        title: "Agency",
        tagline: "Agency Registration",
        cName: "Comapny Name",
        cNamePlace: "Enter comapny name",
        website: "Company Website",
        websitePlace: "Enter company website",
        add: "Company Address",
        addPlace: "Enter company address",
        eNum: "License Electronic Number",
        eNumPlace: " Enter license electronic number",
        cLi: "Commercial License",
        cLiPlace: "Upload License",
        cLiValue: "{{name}}",
        aLi: "Agency License",
        aLiPlace: "Upload Agency License",
        aLiValue: "{{name}}",
        aLogo: "Agency Logo",
        aLogoPlace: "Upload agency Logo",
        aLogoValue: "{{name}}",

    },
}
export const Registration_AR_json = {
    tagline: "قم بإنشاء حساب للمتابعة!",
    fName: "الاسم الأول",
    fNamePlace: "أدخل الاسم الأول",
    lName: "اسم العائلة",
    lNamePlace: "إدخال اسم آخر",
    email: "عنوان البريد الإلكتروني",
    emailPlace: "أدخل البريد الإلكتروني",
    pass: "كلمة المرور",
    passPlace: "أدخل كلمة المرور",
    cPass: "تأكيد كلمة المرور",
    cPassPlace: "أدخل تأكيد كلمة المرور",
    phone: "رقم التليفون",
    des: "وصف",
    desPlace: "وصف",
    button: "يسجل",
    add: "تصفح",

    customer: {
        title: "عميل",
        tagline: "التسجيل العملاء",
    },

    agent: {
        title: "عامل",
        tagline: "تسجيل الوكيل",
        civilId: "رقم البطاقة المدنية",
        civilIdPlace: "أدخل رقم البطاقة المدنية",
        photo: "حمل الصورة",
        photoPlace: "{{name}}",
        agencyId: "وكالة",
        agencyIdPlace: "اختر الوكالة",
    },

    agency: {
        title: "وكالة",
        tagline: "تسجيل الوكالة",
        cName: "اسم الشركة",
        cNamePlace: "أدخل اسم الشركة",
        website: "موقع الشركة",
        websitePlace: "أدخل موقع الشركة",
        add: "عنوان الشركة",
        addPlace: "أدخل عنوان الشركة",
        eNum: "رقم الترخيص الالكتروني",
        eNumPlace: "أدخل رقم الترخيص الإلكتروني",
        cLi: "رخصة تجارية",
        cLiPlace: "تحميل الترخيص",
        cLiValue: "{{name}}",
        aLi: "رخصة الوكالة",
        aLiPlace: "تحميل ترخيص الوكالة",
        aLiValue: "{{name}}",
        aLogo: "شعار الوكالة",
        aLogoPlace: "تحميل شعار الوكالة",
        aLogoValue: "{{name}}",
    },
}