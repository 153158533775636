import React from "react";
import HomeSection from "./HomeSection/HomeSection";
import EvalutionSection from "./EvaluationSection/EvalutionSection";
import CardSection from "./CardSection/CardSection";
import AppSection from "./AppSection/AppSection";
import { Box } from "@mui/material";
import { messaging } from "../../common/Firebase";
import { getToken } from "firebase/messaging";
import apiHelper from "../../common/API/ApiHelper";
import { useLocation } from "react-router-dom";
import RentPropList from "./RentPropList";
import BuyPropList from "./BuyPropList";

export default function HomeScreen(props) {
  const deviceId = localStorage.getItem("deviceID") ?? null;
  const { themeMode } = props;
  const location = useLocation();

  // function for browser push notification
  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BGCRwOri1RA6nHT73Nvzb5AwUcijoqw2Gm6i2udjHBCy3DYCGRH_I7hOW2xL9mhzFk58F8Zwek99uxD-UpX0Qp4",
      });
      if (deviceId) {
        const params = {
          token: token,
          device_id: deviceId,
        };
        await apiHelper.SET_FCM_token(params);
      }
    }
  };

  React.useEffect(() => {
    let userInfo;
    try {
      userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } catch (error) {
      userInfo = null;
    }
    const deviceId = localStorage.getItem("deviceID") ?? null;
    deviceId && userInfo && requestPermission(); //eslint-disable-next-line
  }, [deviceId, location.pathname]);

  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      <HomeSection themeMode={themeMode} />
      <EvalutionSection />
      <BuyPropList />
      <RentPropList />
      <CardSection />
      <AppSection />
    </Box>
  );
}
