import { useTheme } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Path from "../../../common/Path";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

export default function CardSection() {
  const { t } = useTranslation();
  const theme = useTheme();

  const CardData = [
    {
      nav: `${Path.PROPERTY}?value=2`,
      img: "/assets/image/home/buy-house.svg",
      title: `${t("landingPage.card.buy.title")}`,
      desc: `${t("landingPage.card.buy.heading")}`,
      btn: `${t("landingPage.card.buy.button")}`,
      animate: "animate__backInLeft",
    },
    {
      nav: Path.ADD_PROPERTY,
      img: "/assets/image/home/sell-house.svg",
      title: `${t("landingPage.card.sell.title")}`,
      desc: `${t("landingPage.card.sell.heading")}`,
      btn: `${t("landingPage.card.sell.button")}`,
      animate: "animate__backInUp",
    },
    {
      nav: `${Path.PROPERTY}?value=1`,
      img: "/assets/image/home/rent-house.svg",
      title: `${t("landingPage.card.rent.title")}`,
      desc: `${t("landingPage.card.rent.heading")}`,
      btn: `${t("landingPage.card.rent.button")}`,
      animate: "animate__backInRight",
    },
  ];

  return (
    <Box
      sx={{
        justifyContent: "center",
        padding: {
          xs: "2em 1rem",
          md: "2em",
          lg: "3em",
        },
        display: "flex ",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "2rem",
        bgcolor: theme.palette.homeCard.main,
      }}
    >
      <Grid container spacing={3} justifyContent={"center"}>
        {CardData?.map((data, i) => (
          <Grid
            key={i}
            justifyContent={"center"}
            item
            xs={12}
            sm={7}
            md={4}
            lg={3}
          >
            <ScrollAnimation
              // animateIn="animate__zoomInUp"
              animateIn={data.animate}
              duration={1.5}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "12px",
                  maxWidth: "400px",

                  p: "2rem",
                  bgcolor: theme.palette.bg.main,
                  boxShadow: "2px 2px 9px 1px rgba(0, 0, 0, 0.15)",
                  transition:
                    "transform 0.1s ease-in-out, box-shadow 0.5s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "2px 2px 9px 1px #1e243869",
                  },
                }}
              >
                <Link className="linkHover" to={data.nav}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img
                          src={data.img}
                          width={"100%"}
                          style={{ aspectRatio: "1/1" }}
                          alt=""
                          srcSet=""
                        />
                      </Box>
                      <Box>
                        <h4
                          style={{
                            textAlign: "center",
                            color: theme.palette.dark.main,
                            textTransform: "capitalize",
                          }}
                        >
                          {data.title}
                        </h4>
                      </Box>
                      <Box
                        variant={"text"}
                        sx={{ color: theme.palette.paragraph.main }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          {data.desc}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        mt: "1em",
                        textTransform: "capitalize",
                        width: "100%",
                        borderRadius: "12px",
                      }}
                    >
                      {data.btn}
                    </Button>
                  </Box>
                </Link>
              </Box>
            </ScrollAnimation>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
