import React from "react";
import { Box, Typography } from "@mui/material";
import { baseUrl } from "../../common/Constant";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import { toast } from "react-toastify";

export default function NotificationCard({ data, handleRequst }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // display:
        //     !data?.message.includes("Requested by") &&
        //         data.is_accept === 0
        //         ? "none"
        //         : "flex",
        alignItems: "center",
        m: 1,
        gap: "1rem",
        display: "flex",
        width: "98%",
        padding: "1rem",
        boxSizing: "border-box",
        boxShadow: `3px 3px 3px 0px ${theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.15)" : "#DBDBDB25"
          }`,
        borderRadius: "12px",
        cursor: "pointer",
        // border: "solid red",
      }}
      onClick={() => {
        if (data.is_property_delete === 1)
        {
          navigate(`${Path.PROPERTY_DETAILS}/${data.type_id}`);
        } else
        {
          toast.warn(`${t("notf.propertyAvailable")}`);
        }
      }}
    >
      <Box sx={{ width: "100px", height: "70px" }}>
        <img
          src={
            data.image === undefined
              ? "/assets/image/home/home.jpg"
              : `${baseUrl}/uploads/property/image/${data.image}`
          }
          alt="property"
          style={{
            width: "100px",
            height: "70px",
            objectFit: "cover",
            borderRadius: "12px",
            boxShadow: "5px 5px 6px -4px grey",
          }}
        />
      </Box>

      <Box
        sx={{
          width: `calc(100% - 100px)`,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: `calc(100% - 120px)` },
            fontSize: "1.1rem",
          }}
          key={data.id}
          id="modal-modal-description"
        >
          {data?.message}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#828282",
          }}
        >
          <Typography style={{ fontSize: "0.85rem" }}>{data.date}.</Typography>
          <Typography
            sx={{
              display: data?.message.includes("Requested by") ? "flex" : "none",
              color:
                (data.is_accept === 1 && "#014a01") ||
                (data.is_accept === 0 && "#828282") ||
                (data.is_accept === -1 && "#760505"),
            }}
            style={{ fontSize: "0.85rem" }}
          >
            {data.is_accept === 1
              ? `${t("notf.accepted")}`
              : data.is_accept === -1 && `${t("notf.reject")}`}
          </Typography>
          <Box
            sx={{
              display:
                data?.message.includes("Requested by") && data.is_accept === 0
                  ? "flex"
                  : "none",
              gap: "1rem",
            }}
          >
            <CheckCircleOutlinedIcon
              style={{
                color: "green",
                fontSize: "1.75rem",
                cursor: "pointer",
              }}
              onClick={() => {
                handleRequst(1, data.user_req_id);
              }}
            />
            <HighlightOffOutlinedIcon
              style={{
                color: "red",
                fontSize: "1.75rem",
                cursor: "pointer",
              }}
              onClick={() => {
                handleRequst(0, data.user_req_id);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
