import React, { createContext, useEffect, useState } from "react";
export const LanguageContext = createContext();

export default function LanguageContextProvider({ children }) {
  const [language, setLanguage] = useState("ar");
  const [mapModal, setMapModal] = useState(false);
  const [GlatLng, setGLatLng] = useState();
  const [SOckeT, setSOcket] = useState();
  const [chatUserData, setchatUserData] = useState({});
  const [ChatFunction, setChatFunction] = useState();
  const [chatInView, setChatInView] = useState("");
  const [NewChatCount, setNewChatCount] = useState(false);

  // to handle Google map modal

  const handleOpen_MapModal = (e) => setMapModal(true);

  const handleClose_MapModal = (e) => setMapModal(false);

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng") ?? "en";
    const newLang = lang === "ar" ? "ar" : "en";
    newLang && setLanguage(newLang);
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        chatInView,
        setNewChatCount,
        NewChatCount,
        setChatInView,
        language,
        setLanguage,
        mapModal,
        handleOpen_MapModal,
        handleClose_MapModal,
        setGLatLng,
        GlatLng,
        setSOcket,
        SOckeT,
        setchatUserData,
        chatUserData,
        setChatFunction,
        ChatFunction,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
