import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import { LanguageContext } from "../../../Context/LanguageContext";

function RejectScreen({ handleOpen }) {
  const theme = useTheme();
  const nav = useNavigate();
  const { language } = useContext(LanguageContext);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: `calc(100vh)`,
        bgcolor: theme.palette.navbarBG.main,
      }}
    >
      <Box sx={{ width: { xs: "80%", md: "40%" } }}>
        <img
          src="/assets/image/sign/reject.svg"
          height={"100%"}
          width={"100%"}
          alt="Application rejected."
          srcSet=""
        />
        <Typography
          textAlign={"center"}
          color={"grey"}
          fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.4rem" }}
        >
          {" "}
          {language === "ar"
            ? "لقد تم رفض التحقق من حسابك. يرجى الاتصال بنا للحصول على مزيد من التفاصيل."
            : "Your account verification has been rejected. Please contact us for further details."}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              padding: "0.5rem",
              borderRadius: "12px",
              background: "#1f2438",
              color: "#EEE",
              textAlign: "center",
              cursor: "pointer",
              mt: "1rem",
              boxSizing: "border-box",
              width: "200px",
              fontSize: "1.1rem",
            }}
            onClick={() => {
              nav(Path.CONTACT);
            }}
          >
            {language === "ar" ? "اتصل بنا" : "Contact Us"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default RejectScreen;
