import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import apiHelper from "../../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { Box, useTheme } from "@mui/material";
import { socket } from "../../../App";

export default function MsgDeleteModal({ setDeletedMsgData, msgID, me }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const query = location.search;
  const params = new URLSearchParams(query);
  const chatId = params.get("chatId");
  const userId = params.get("uId");

  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMsg = async (type) => {
    try {
      const data = {
        chat_id: Number(chatId),
        message_id: Number(msgID),
        ...(type === "all" && { is_delete: 1 }),
      };
      const res = await apiHelper.Delete_MSG(data);
      if (res?.data?.s === 1) {
        const render = {
          chat_id: Number(chatId),
          id: msgID,
          reciever_id: Number(userId),
          ...(type === "all" && { is_delete: 1 }),
        };
        setDeletedMsgData(render);
        handleClose();

        type === "all" && socket && socket?.emit("send-msg", render);

        toast.success("Message deleted successfully");
      } else {
        toast.error(res?.data?.m);
      }
    } catch (error) {}
  };

  return (
    <div>
      <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ cursor: "pointer", filter: "opacity(0.5)" }}
      >
        <MoreVertIcon sx={{ color: theme.palette.dark.main }} />
      </span>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            borderRadius: "10px",
          },
        }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "12px",
          },
        }}
      >
        <MenuItem>
          <Box
            sx={{
              textAlign: "start",
              color: `${theme.palette.dark.main}75`,
            }}
          >
            {t("chat.msgdelete.title")}
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ mt: 1, display: me ? "block" : "none" }}
          onClick={() => {
            handleDeleteMsg("all");
          }}
        >
          {t("chat.msgdelete.op1")}
        </MenuItem>
        <MenuItem
          sx={{ mt: 1 }}
          onClick={() => {
            handleDeleteMsg("me");
          }}
        >
          {t("chat.msgdelete.op2")}
        </MenuItem>
      </Menu>
    </div>
  );
}
