import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropertyCard from "../../PropertyCard";
import apiHelper from "../../../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { NoData } from "../../../../common/Svg";
import Loader from "../../../../common/Loader";

import { useLocation } from "react-router-dom";

export default function SellPropertyHistoryScreen() {
  const [fetchProperty, SetFetchedProperty] = useState([]);
  const [loading, setloading] = useState(true);
  const location = useLocation();

  const getPropertyList = async () => {
    try {
      const result = await apiHelper.Get_User_Property_History_sell_List();
      if (result?.data?.r.length > 0 && result.status === 200) {
        SetFetchedProperty(result.data.r);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error?.response?.data?.m);
    }
  };

  useEffect(() => {
    getPropertyList();
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  return (
    <>
      {loading && fetchProperty.length === 0 ? (
        <Box
          sx={{
            minHeight: `calc( 100vh - 120px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : fetchProperty.length === 0 && !loading ? (
        <Box
          sx={{
            height: `calc(100vh - 120px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoData />
        </Box>
      ) : (
        <Box sx={{ padding: "1.5rem" }}>
          <Grid
            container
            spacing={"1.5rem"}
            sx={{ minHeight: `calc( 100vh - 144px )`, pb: "1rem" }}
          >
            {fetchProperty &&
              fetchProperty.map((x, i) => {
                return (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <PropertyCard x={x} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}
    </>
  );
}
