import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { SearchHomeSec } from "../../../common/Svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";
import LocationSearchField from "../../../component/GoogleMap/LocationSearchField";
import apiHelper from "../../../common/API/ApiHelper";

export default function HomeSection(props) {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [address, setAddress] = useState();
  const [loading, setloading] = useState(true);
  const [clicked, setclicked] = useState(false);
  const [latlng, setLatLng] = useState({
    lat: null,
    lng: null,
  });

  setTimeout(() => {
    setclicked(false);
  }, 4500);

  const [searchDetails, setSearchDetails] = useState({
    category: "0",
    location: "",
    type: "0",
  });

  const handleAddress = (address) => {
    setAddress(address);
  };

  const updateLatLng = (latLngs) => {
    setLatLng({ ...latlng, lat: latLngs.lat, lng: latLngs.lng });
    // console.log("lat",latLng.lat, "lng",latLng.lng);
  };

  const fetchCategory = async () => {
    try {
      const res = await apiHelper.fetchPropertyTypeList();
      setCategory(res?.data?.r);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const handleSearchDetails = (e) => {
    setSearchDetails({ ...searchDetails, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    searchDetails.category === "0" ||
    latlng.lat === "" ||
    searchDetails.type === "0"
      ? toast.info(
          language === "ar"
            ? "يُرجى ملء جميع الحقول!"
            : "Please fill all the fields!"
        )
      : navigate(
          `${Path.SEARCH_PROPERTY}/${searchDetails.category}/${latlng.lat}/${latlng.lng}/${searchDetails.type}`
        );
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <Box
      sx={{
        fontSize: { md: 22 },
        position: "relative",
        background:
          theme.palette.mode === "light"
            ? 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url("/assets/image/home/main.webp") center / cover'
            : 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url("/assets/image/home/dmain.webp") center / cover',

        width: "100%",
        height: "100vh",
        paddingTop: "30px",
      }}
    >
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <center>
            <ScrollAnimation animateIn="  animate__zoomIn animate__slower">
              <Box className={"anim"}>
                <Box sx={{ padding: "1rem" }}>
                  <Typography
                    sx={{
                      color: theme.palette.default.main,
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      letterSpacing: "1px",
                      mt: {
                        xs: "22em",
                      },
                    }}
                    variant="mainHeading"
                  >
                    {t("landingPage.homeSection.MainTitle")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column-reverse",
                      sm: "row",
                    },
                    gap: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "20px",
                    backdropFilter: "blur(3px)",
                    padding: "20px",
                    background: theme.palette.tbg.main,
                    m: { xs: "1rem", lg: "0" },
                  }}
                >
                  {/*----------------------- search Svg----------------------- */}
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        sm: "flex",
                      },
                      cursor: "pointer",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (!clicked) {
                        handleSearch();
                        setclicked(true);
                      }
                    }}
                  >
                    <SearchHomeSec
                      color={theme.palette.dark.main}
                      text={theme.palette.light.main}
                    />
                  </Box>

                  {/*  --------------- Search for small screens ----------------*/}
                  <Box
                    sx={{
                      padding: "14px",
                      width: {
                        xs: "100%",
                      },
                      boxSizing: "border-box",
                      display: {
                        xs: "block",
                        sm: "none",
                      },
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "12px",
                      bgcolor: theme.palette.dark.main,
                      cursor: "pointer",
                    }}
                    onClick={handleSearch}
                  >
                    <Box
                      variant={"button"}
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        color: theme.palette.light.main,
                        textAlign: language === "en" ? "center" : "right",
                        // paddingLeft: "1rem",
                      }}
                    >
                      {/* Search Property */}
                      {t(
                        "landingPage.homeSection.searchPropertyFilters.search"
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: {
                        xl: "30%",
                        md: "33%",
                        xs: "100%",
                      },
                    }}
                  >
                    <Select
                      id="category"
                      value={searchDetails.category}
                      name="category"
                      onChange={handleSearchDetails}
                      fullWidth
                      IconComponent={ExpandMoreIcon}
                      className="HSMenu"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "0.5rem",
                            borderRadius: "12px",
                          },
                        },
                      }}
                      sx={{
                        bgcolor: theme.palette.dark.main,
                        width: "100%",
                        textAlign: language === "ar" ? "right" : "left",
                        borderRadius: "12px",
                        color:
                          searchDetails.category === "0"
                            ? theme.palette.darkField
                            : theme.palette.light.main,
                        "& .MuiSvgIcon-root": {
                          color: theme.palette.light.main,
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "14px ",
                        },
                        // "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                        //   mt: "1rem",
                        // },
                      }}
                    >
                      <MenuItem value={0} disabled style={{ display: "none" }}>
                        {t(
                          "landingPage.homeSection.searchPropertyFilters.category"
                        )}
                      </MenuItem>

                      {loading ? (
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "grey",
                          }}
                        >
                          Loading...
                        </Box>
                      ) : category?.length > 0 ? (
                        category?.map((x) => (
                          <MenuItem key={x.id} id={x.id} value={x.id}>
                            {/* <img src={`${baseUrl}/uploads/property/icon/${x.icon}`} style={{ borderRadius: "11px", height: '25px', width: '25px' }} alt="" srcset="" /> */}
                            {x.name}
                          </MenuItem>
                        ))
                      ) : (
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "grey",
                          }}
                        >
                          Not found.
                        </Box>
                      )}
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      width: {
                        xl: "30%",
                        md: "33%",
                        xs: "99%",
                      },

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LocationSearchField
                      address={address}
                      handleAddress={handleAddress}
                      updateLatLng={updateLatLng}
                      width={"100%"}
                      textColor={theme.palette.light.main}
                      color={theme.palette.dark.main}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: {
                        xl: "30%",
                        md: "33%",
                        xs: "100%",
                      },
                    }}
                  >
                    <Select
                      id="type"
                      value={searchDetails.type}
                      name="type"
                      onChange={handleSearchDetails}
                      fullWidth
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "0.5rem",
                            borderRadius: "12px",
                          },
                        },
                      }}
                      sx={{
                        bgcolor: theme.palette.dark.main,
                        width: "100%",
                        textAlign: language === "ar" ? "right" : "left",
                        borderRadius: "12px",
                        color:
                          searchDetails.type === "0"
                            ? theme.palette.darkField
                            : theme.palette.light.main,
                        "& .MuiSvgIcon-root": {
                          color: theme.palette.light.main,
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "14px",
                        },
                      }}
                      IconComponent={ExpandMoreIcon}
                    >
                      <MenuItem value={0} disabled style={{ display: "none" }}>
                        {t(
                          "landingPage.homeSection.searchPropertyFilters.type"
                        )}
                      </MenuItem>
                      <MenuItem value={1}>
                        {" "}
                        {t(
                          "landingPage.homeSection.searchPropertyFilters.sell"
                        )}
                      </MenuItem>
                      <MenuItem value={2}>
                        {" "}
                        {t(
                          "landingPage.homeSection.searchPropertyFilters.rent"
                        )}
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Box>
            </ScrollAnimation>
          </center>
        </Box>
      </Box>
    </Box>
  );
}
