import React, { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import InputComponent from "../../../component/Input/InputComponentTextField";
import formValidator from "../../../common/Validator";
import { toast } from "react-toastify";
import ErrorMsgComponent from "../../../component/Input/ErrorMsgComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LanguageContext } from "../../../Context/LanguageContext";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

window.scrollTo({ behavior: "instant", top: 0 });

export default function ResidentEvaluation({ setData, setIsCalculated }) {
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [formDetails, setFormDetails] = useState({
    property: "0",
    propertySize: "0",
    noOfBedroom: "",
    noOfWashroom: "",
    location: "",
    ageOfProperty: "",
    materialsAndQuality: "",
    propertyValue: "",
    renovation: "",
    majorRoad: "",
  });

  const HandleFormSubmittedDetails = () => {
    try
    {
      setIsSubmitted(true);
      const validationResult = formValidator(
        formDetails,
        "residentEvalution",
        "",
        t
      );
      if (validationResult.length > 0)
      {
        return setValidationError(validationResult);
      }
      if (!formDetails.renovation)
        return toast.error(
          language === "ar" ? "حدد مجالات التجديد" : "select renovation fields"
        );
      if (!formDetails.majorRoad)
        return toast.error(
          language === "ar" ? "حدد حقول الطريق" : "select Road fields"
        );
      setData(formDetails);
      setIsCalculated(true)
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted)
    {
      const validationResult = formValidator(
        updatedFormDetails,
        "residentEvalution",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  return (
    <ScrollAnimation animateIn="fadeIn">
      <div id="resident" style={{ padding: "1rem" }}>
        <Typography
          sx={{
            fontWeight: "600",
            color: theme.palette.primary.main,
          }}
          variant="text"
        >
          {/* Determine your home's worth in an instant. */}
          {t("eval.msg")}
        </Typography>
        <Box sx={{ pt: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: { xs: "0", sm: "1rem" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <Select
                  id="property"
                  name="property"
                  value={formDetails.property}
                  onChange={handleFormDetails}
                  required
                  fullWidth
                  IconComponent={ExpandMoreIcon}
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                    "& fieldset": { border: "none" },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px ",
                    },
                    " .MuiOutlinedInput-input": {
                      textAlign: language === "ar" ? "right" : "left",
                    },
                    // input: {
                    //   "&::placeholder": {
                    //     textAlign: language === "ar" ? "right" : "left",
                    //     color: `${theme.palette.dark.main}75`,
                    //   },
                    // },
                  }}
                  style={{
                    color: theme.palette.dark.main,
                  }}
                >
                  <MenuItem value={0} disabled>
                    {t("eval.resident.property")}
                  </MenuItem>
                  <MenuItem value={"One"}>One</MenuItem>
                  <MenuItem value={"Two"}>Two</MenuItem>
                  <MenuItem value={"Three"}>Three </MenuItem>
                </Select>
                <ErrorMsgComponent
                  id={"property"}
                  validationError={validationError}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="number"
                  name="noOfBedroom"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "noOfBedroom" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "noOfBedroom")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="noOfBedroom"
                  value={formDetails.noOfBedroom}
                  placeholder={t("eval.resident.bed")}
                // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="location"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "location" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "location")?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="location"
                  value={formDetails.location}
                  placeholder={t("eval.resident.location")}
                // style={{ color: "red" }}
                />
              </Box>
            </Box>

            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Box sx={{ mb: "0.7em", width: { xs: "100%" } }}>
                <Select
                  id="propertySize"
                  value={formDetails.propertySize}
                  name="propertySize"
                  onChange={handleFormDetails}
                  fullWidth
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                    "& fieldset": { border: "none" },
                    "& .MuiOutlinedInput-input": {
                      padding: "14px ",
                    },
                    " .MuiOutlinedInput-input": {
                      textAlign: language === "ar" ? "right" : "left",
                    },
                    // input: {
                    //   "&::placeholder": {
                    //     textAlign: language === "ar" ? "right" : "left",
                    //     color: `${theme.palette.dark.main}75`,
                    //   },
                    // },
                  }}
                  style={{
                    color: theme.palette.dark.main,
                  }}
                  defaultText={t("eval.resident.size")}
                >
                  <MenuItem value={0} disabled>
                    {t("eval.resident.size")}
                  </MenuItem>

                  <MenuItem value={"One"}>One</MenuItem>
                  <MenuItem value={"Two"}>Two</MenuItem>
                  <MenuItem value={"Three"}>Three </MenuItem>
                </Select>
                <ErrorMsgComponent
                  id={"propertySize"}
                  validationError={validationError}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="number"
                  name="noOfWashroom"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "noOfWashroom" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "noOfWashroom")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="noOfWashroom"
                  value={formDetails.noOfWashroom}
                  placeholder={t("eval.resident.washroom")}
                // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="number"
                  name="ageOfProperty"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "ageOfProperty" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "ageOfProperty")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="ageOfProperty"
                  value={formDetails.ageOfProperty}
                  placeholder={t("eval.resident.age")}
                // style={{ color: "red" }}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="text"
              name="materialsAndQuality"
              onChange={handleFormDetails}
              isError={validationError?.find((x) => {
                return x.key === "materialsAndQuality" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "materialsAndQuality")
                  ?.message
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: {
                  xs: "100%",
                },
                borderRadius: "12px",
              }}
              id="materialsAndQuality"
              value={formDetails.materialsAndQuality}
              placeholder={t("eval.resident.mq")}
            />
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="number"
              name="propertyValue"
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: {
                  xs: "100%",
                },
                borderRadius: "12px",
              }}
              id="propertyValue"
              onChange={handleFormDetails}
              isError={validationError?.find((x) => {
                return x.key === "propertyValue" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "propertyValue")?.message
              }
              value={formDetails.propertyValue}
              placeholder={t("eval.resident.value")}
            />
          </Box>

          <Box
            sx={{
              width: { xs: "100%" },
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: {
                xs: "center",
                md: "space-between",
              },
            }}
          >
            <Box>
              <Box> {t("eval.resident.reno.title")}</Box>
              <Box
                sx={{
                  mt: "0.5rem",
                  display: "flex",
                  justifyContent: { xs: "space-around" },
                }}
              >
                <Box>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="renovation"
                      id="yes"
                      // onChange={handleFormDetails}
                      // isError={validationError?.find((x) => {
                      //   return x.key === "renovation" ? true : false;
                      // })}
                      // helperText={
                      //   validationError.find((x) => x.key === "renovation")?.message
                      // }
                      onChange={handleFormDetails}
                      value={"yes"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.resident.reno.yes")}
                  </label>
                </Box>

                <Box sx={{ display: "inline-block", m: "0 1rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="renovation"
                      id="no"
                      onChange={handleFormDetails}
                      value={"no"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.resident.reno.no")}
                  </label>
                </Box>
              </Box>
            </Box>

            <Box sx={{ mt: { xs: "0.5rem", sm: "0" } }}>
              <Box>{t("eval.resident.road.title")}</Box>
              <Box
                sx={{
                  mt: "0.5rem",
                  display: "flex",
                  justifyContent: { xs: "space-around" },
                }}
              >
                <Box>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="majorRoad"
                      onChange={handleFormDetails}
                      value={"yes"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.resident.road.yes")}
                  </label>
                </Box>

                <Box sx={{ display: "inline-block", ml: "1rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="majorRoad"
                      onChange={handleFormDetails}
                      value={"no"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.resident.road.no")}
                  </label>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: "100%" },
              display: "flex",
              justifyContent: "center",
              mt: "1rem",
            }}
          >
            <Button
              onClick={HandleFormSubmittedDetails}
              variant="contained"
              sx={{
                borderRadius: "12px",
                padding: "0.3em 5em",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                bgcolor: theme.palette.gold.main,
                mt: "1rem",
                color: "#fff",
              }}
            >
              {t("eval.button")}
            </Button>
          </Box>
        </Box>
      </div>
    </ScrollAnimation>
  );
}
