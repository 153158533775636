export const Gmap_EN_Trans = {
    close: "close",
    selectedLoc: "selected location",
    confirm: "confirm",
};

export const Gmap_AR_Trans = {
    close: "يغلق",
    selectedLoc: "الموقع المحدد",
    confirm: "يتأكد",
};
