export const Evaluation_EN_json = {
    title: "Property evaluation",
    tagline: "know the value of your home within second",
    subHead: "Select the respected Filed",
    res: "Residential",
    com: "Commercial",
    ind: "Industrial",
    agri: "Agricultural",
    msg: "Determine your home's worth in an instant.",
    button: "Calculate",
    //------------------------------resident---------------------------------------
    resident: {
        property: "Select property",
        size: "Property size",
        bed: "No. of bedroom",
        washroom: "No. of washroom",
        location: "Location",
        age: "Property age",
        mq: "Buliding materials and quality of contruction",
        value: "Surrounding property values",
        reno: {
            title: "Recent Renovation",
            yes: "Yes",
            no: "No",
        },
        road: {
            title: "Access to major road",
            yes: "Yes",
            no: "No",
        },
    },

    comm: {
        area: "Property area",
        rentIncom: "Property rental income",
        pSpace: "Parking Space",
        location: "Location",
        oRate: "Property occupancy rates",
        economy: "Property economy factors",
        rp: "Zoning regulations and permitted use",
        condition: {
            title: "Building Condition",
            bad: "Bad",
            good: "Good",
            exc: "Excellent",
        },
        comp: {
            title: "Competition in the area",
            high: "High",
            low: "Low",
        }
    },

    indu: {
        area: "Property area",
        cHeight: "Ceiling height",
        location: "Property location",
        capa: "Loading capacity",
        rp: "Buliding materials and quality of contruction",
        value: "Surrounding property values",
        condi: {
            title: "Condition & Property",
            good: "Good",
            bad: "Bad",
        },
        ind: {
            title: "Industrial sector trens",
            high: "High",
            low: "Low"
        },
        tran: {
            title: " Near by transportation",
            high: "Highways",
            bus: "Bus",
            air: "Airports",
            port: "Ports",
        }

    },

    agr: {
        area: "Floor area",
        yh: "Crop yield history",
        qua: "Soil quality & type",
        gr: "Goverment regulations",
        pm: "Proximity to markets and distribution centers",
        demand: "Local and Global demand for specific crops",
        pote: "Potential for future development or land use changes",
        water: {
            title: "Water availability",
            ir: "Irrigation",
            ns: "Natural Sources",

        }




    },

    calc: {
        title: "Total value of the properety",
        detail: "KD",
        agri: {
            pSize: "Property Size",
            sQua: "Soil quality 7 type",
            cYh: "Crop yield history",
            GR: "Government regulations",
            md: "Proximity to markets and distribution centers",
            lgCrop: "Local and Global demand for specific crops",
            potential: "Potential for future development or land use changes",
            water: "Water availability",
        },
        com: {
            pSize: "Property Size",
            location: "Location",
            rInc: "Rental Income",
            hor: "Historical occupancy rates",
            pSpace: "Parking Space",
            bc: "Building Condition",
            zrp: "Zoning regulation permitted use",
            eco: "Local economy in the area",
            compi: "Competition in the area"
        },
        ind: {
            pSize: "Property Size",
            location: "Location",
            zrp: "Zoning regulation permitted use",
            trans: "Near by transportation",
            uti: "Availability of utilities",
            cHeight: "Ceiling Height",
            loading: "Loading Capacity",
            rent: "Historical rental or lease rates",
            cp: "Condition & Property",
            trend: "Industrial sector trends",
        },
        resi: {
            opt: "Select option",
            pSize: "Property Size",
            bed: "Bedroom",
            wash: "Washroom",
            location: "Location",
            age: "Age of Property",
            reno: "Recent Renovation",
            road: "Access to major road",
            bmc: "Building materials and quality of construction",
            surr: "Surrounding property values",
        },
    }
};
export const Evaluation_AR_json = {
    title: "تقييم العقارات",
    tagline: "يمكنك تقييم قيمة منزلك في لحظة.",
    subHead: "اختر الحقل المعني",
    res: "سكني",
    com: "تجاري",
    ind: "صناعي",
    agri: "زراعي",
    msg: "تحديد قيمة منزلك في لحظة.",
    button: "حساب",
    resident: {
        property: "اختر العقار",
        size: "حجم العقار",
        bed: "عدد غرف النوم",
        washroom: "عدد الحمامات",
        location: "الموقع",
        age: "عمر العقار",
        mq: "مواد البناء وجودة البناء والتشطيب",
        value: "قيمة الملكية المحيطة",
        reno: {
            title: "التجديدات الأخيرة",
            yes: "نعم",
            no: "لا",
        },
        road: {
            title: "الوصول إلى الطريق الرئيسي",
            yes: "نعم",
            no: "لا",
        },
    },

    comm: {
        area: "مساحة العقار",
        rentIncom: "دخل تأجير العقارات",
        pSpace: "أماكن وقوف السيارات",
        location: "الموقع",
        oRate: "معدلات إشغال العقارات",
        economy: "عوامل اقتصاد العقارات",
        rp: "تنظيمات الزونة والاستخدام المسموح به",
        condition: {
            title: "حالة المبنى",
            bad: "سيء",
            good: "جيد",
            exc: "ممتاز",
        },
        comp: {
            title: "المنافسة في المنطقة",
            high: "عالية",
            low: "منخفضة",
        }
    },

    indu: {
        area: "مساحة العقار",
        cHeight: "ارتفاع السقف",
        location: "موقع العقار",
        capa: "قدرة التحميل",
        rp: "مواد البناء وجودة البناء والتشطيب",
        value: "قيمة الملكية المحيطة",
        condi: {
            title: "الحالة والممتلكات",
            good: "جيد",
            bad: "سيء",
        },
        ind: {
            title: "اتجاهات القطاع الصناعي",
            high: "عالية",
            low: "منخفضة"
        },
        tran: {
            title: "المواصلات القريبة",
            high: "طرق سريعة",
            bus: "محطات الحافلات",
            air: "مطارات",
            port: "موانئ",
        }

    },

    agr: {
        area: "مساحة الأرض",
        yh: "تاريخ إنتاج المحاصيل",
        qua: "جودة التربة ونوعها",
        gr: "اللوائح الحكومية",
        pm: "القرب من الأسواق ومراكز التوزيع",
        demand: "الطلب المحلي والعالمي على المحاصيل المحددة",
        pote: "الإمكانيات للتطوير المستقبلي أو تغييرات في استخدام الأراضي",
        water: {
            title: "توفر مصادر المياه",
            ir: "الري",
            ns: "مصادر طبيعية",
        }
    },

    calc: {
        title: "القيمة الإجمالية للعقار",
        detail: "دينار كويتي",
        agri: {
            pSize: "حجم العقار",
            sQua: "جودة التربة ونوعها",
            cYh: "تاريخ إنتاج المحاصيل",
            GR: "اللوائح التنظيمية الحكومية",
            md: "القرب من الأسواق ومراكز التوزيع",
            lgCrop: "الطلب المحلي والعالمي على المحاصيل المحددة",
            potential: "الإمكانيات للتطوير المستقبلي أو تغييرات في استخدام الأراضي",
            water: "توفر مصادر المياه",
        },
        com: {
            pSize: "مساحة العقار",
            location: "الموقع",
            rInc: "دخل التأجير",
            hor: "معدلات الإشغال التاريخية",
            pSpace: "أماكن وقوف السيارات",
            bc: "حالة المبنى",
            zrp: "تنظيمات الزونة والاستخدام المسموح به",
            eco: "الاقتصاد المحلي في المنطقة",
            compi: "المنافسة في المنطقة"
        },
        ind: {
            pSize: "مساحة العقار",
            location: "الموقع",
            zrp: "تنظيمات الزونة والاستخدام المسموح به",
            trans: "المواصلات القريبة",
            uti: "توفر الخدمات",
            cHeight: "ارتفاع السقف",
            loading: "قدرة التحميل",
            rent: "معدلات الإيجار أو التأجير التاريخية",
            cp: "الحالة والممتلكات",
            trend: "اتجاهات القطاع الصناعي",
        },
        resi: {
            opt: "حدد خيارًا",
            pSize: "حجم العقار",
            bed: "غرف النوم",
            wash: "الحمامات",
            location: "الموقع",
            age: "عمر العقار",
            reno: "التجديدات الأخيرة",
            road: "الوصول إلى الطريق الرئيسي",
            bmc: "مواد البناء وجودة البناء والتشطيب",
            surr: "قيمة الملكية المحيطة",
        },
    }
};


// export const Evaluation_AR_json = {
//     title: "تقييم العقار",//n
//     tagline: "يمكنك تقييم العقار حسب الفئات التالية.",//n
//     subHead: "حدد الحقل المعني",
//     res: "سكني",//n
//     com: "استثماري",//n
//     ind: "صناعي",//n
//     agri: "زراعي",//n
//     msg: "تحديد قيمة منزلك في لحظة.",
//     button: "حساب",//n
//     //------------------------------resident---------------------------------------
//     resident: {
//         property: "اختر خاصية",
//         size: "حجم العقار",
//         bed: "رقم غرفة النوم",
//         washroom: "رقم الحمام",
//         location: "موقع",
//         age: "عمر العقار",
//         mq: "مواد البناء و جودة البناء والتشطيب",//n
//         value: "قيمة محيط العقار",//n
//         reno: {
//             title: "تجديدات حديثة",//n
//             yes: "نعم",//n
//             no: "لا",//n
//         },
//         road: {
//             title: "مخرج/مدخل إلى طريق رئيسي",//n
//             yes: "نعم",//n
//             no: "لا",//n
//         },
//     },

//     comm: {
//         area: "منطقة العقار",
//         rentIncom: "دخل تأجير العقارات",
//         pSpace: "أماكن لوقوف السيارات",
//         location: "موقع",
//         oRate: "معدلات إشغال العقارات",
//         economy: "عوامل الاقتصاد العقاري",
//         rp: "نوع القسيمة و تصاريح البناء (مثال: سكني ، استثماري)",//n
//         condition: {
//             title: "حالة المبنى",//n
//             bad: "سيء",//n
//             good: "جيد",//n
//             exc: "ممتاز",//n
//         },
//         comp: {
//             title: "المنافسة في المنطقة",//n
//             high: "عالي",
//             low: "قليل",
//         }
//     },

//     indu: {
//         area: "منطقة العقار",
//         cHeight: "ارتفاع السقف",
//         location: "موقع العقار",
//         capa: "قدرة التحميل",
//         rp: "مواد البناء و جودة البناء والتشطيب",//n
//         value: "قيمة محيط العقار",//n
//         condi: {
//             title: "الحالة والممتلكات",
//             good: "جيد",//n
//             bad: "سيء",//n
//         },
//         ind: {
//             title: "اتجاهات القطاع الصناعي",
//             high: "عالي",
//             low: "قليل"
//         },
//         tran: {
//             title: "قرب المواصلات",//n
//             high: "طريق سريع",//n
//             bus: "محطة باصات",//n
//             air: "مطارات",//n
//             port: "موانئ",//n
//         }

//     },

//     agr: {
//         area: "المساحة الأرضية",
//         yh: "تاريخ إنتاجية المحاصيل",
//         qua: "نوعية التربة ونوعها",
//         gr: "اللوائح الحكومية",
//         pm: "القرب من السوق ومراكز التوزيع",//n
//         demand: "الطلب العالمي والمحلي على محاصيل محددة",//n
//         pote: "إمكانية التطوير المستقبلي أو تغييرات استخدام الأراضي",
//         water: {
//             title: "توافر مصدر للمياه",//n
//             ir: "الري",//n
//             ns: "مصادر طبيعية",//n
//         }
//     },

//     calc: {
//         title: "القيمة الإجمالية للعقار",//n
//         detail: "دينار كويتي",
//         agri: {
//             pSize: "مساحة العقار",//n
//             sQua: "نوعية التربة 7 نوع",
//             cYh: "تاريخ إنتاجية المحاصيل",//n
//             GR: "اللوائح التنظيمية الحكومية",//n
//             md: "القرب من السوق ومراكز التوزيع",//n
//             lgCrop: "الطلب العالمي والمحلي على محاصيل محددة",//n
//             potential: "فرصة التطوير المستقبلي أو تغيير تصريح استخدام الأرض",//n
//             water: "توافر مصدر للمياه",//n
//         },
//         com: {
//             pSize: "مساحة العقار",//n
//             location: "الموقع",//n
//             rInc: "مدخول الإيجار",//n
//             hor: "معدلات السكن التاريخية",//n
//             pSpace: "مساحة لمواقف السيارات",//n
//             bc: "حالة المبنى",//n
//             zrp: "نوع القسيمة و تصاريح البناء (مثال: سكني ، استثماري)",//n
//             eco: "الوضع الاقتصادي في المنطقة",//n
//             compi: "المنافسة في المنطقة"///
//         },
//         ind: {
//             pSize: "مساحة العقار",//n
//             location: "الموقع",//n
//             zrp: "نوع القسيمة و تصاريح البناء (مثال: سكني ، استثماري)",//n
//             trans: "قرب المواصلات",//n
//             uti: "توافر الخدمات",//n
//             cHeight: "ارتفاع السقف",//n
//             loading: "قدرة التحميل",//n
//             rent: "معدلات القيمة الإيجارية التاريخية",//n
//             cp: "الحالة والممتلكات",
//             trend: "اتجاهات القطاع الصناعي",
//         },
//         resi: {
//             opt: "حدد اختياراً",//n
//             pSize: "مساحة العقار",//n
//             bed: "حمامات",//n
//             wash: "مغاسل",//n
//             location: "الموقع",//n
//             age: "عمر العقار",//n
//             reno: "تجديدات حديثة",//n
//             road: "مخرج/مدخل إلى طريق رئيسي",//n
//             bmc: "مواد البناء و جودة البناء والتشطيب",//n
//             surr: "قيمة محيط العقار:",//n
//         },
//     }
// };
