import { useTheme, styled } from "@mui/material/styles";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import Loader from "../../../common/Loader";
import { NoData } from "../../../common/Svg";
import AllPropertyHistoryScreen from "./All/AllPropertyHistoryScreen";
import RentPropertyHistoryScreen from "./Rent/RentPropertyHistoryScreen";
import SellPropertyHistoryScreen from "./Sell/SellPropertyHistoryScreen";

export default function PropertyHistoryScreen({ themeMode }) {
  const StyledTab = styled(Tab)(({ theme, themeMode, selected }) => ({
    borderRadius: "12px",
    textTransform: "capitalize",
    margin: "0.2rem 1rem 0.2rem 0.1rem",
    borderBottom: "none",
    height: "100%",
    minWidth: "60px",
    padding: "0px 8px",
    boxSizing: "border-box",
    minHeight: "44px",
    borderL: "solid red",
    // width: "60px",
    backgroundColor:
      themeMode === "light"
        ? selected
          ? theme.palette.primary.main
          : theme.palette.secondary.main
        : selected
        ? theme.palette.gold.main
        : theme.palette.secondary.main,
    color:
      themeMode === "light"
        ? selected
          ? `${theme.palette.secondary.main} !important`
          : theme.palette.primary.main
        : selected
        ? theme.palette.dark.main
        : theme.palette.primary.main,
    boxShadow: `3px 3px 3px 0px ${
      theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.15)" : "#DBDBDB25"
    }`,
    "&:hover": {
      backgroundColor:
        themeMode === "light"
          ? selected
            ? theme.palette.primary.main
            : theme.palette.secondary.main
          : selected
          ? theme.palette.gold.main
          : theme.palette.secondary.main,
      color:
        themeMode === "light"
          ? selected
            ? theme.palette.secondary.main
            : theme.palette.primary.main
          : selected
          ? theme.palette.dark.main
          : theme.palette.primary.main,
      border: "none",
      outline: !selected && `1px solid ${theme.palette.dark.main}`,
    },
  }));
  const location = useLocation();
  const [pathname, setPathname] = useState();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const id = Number(location?.search?.split("?value=")[1]);
    id && setValue(id);
  }, [location]);

  return (
    <Box sx={{ minHeight: `calc(100vh)`, position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          height: "60px",
          bgcolor: theme.palette.secondary.main,
          alignItems: "center",
          width: "100%",
          position: "sticky",
          zIndex: 1,
          top: "0px",
        }}
      >
        <Box
          sx={{
            width: "50px",
            display: "flex",
            justifyContent: "end",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosIcon sx={{ color: theme.palette.dark.main }} />
        </Box>
        <Box sx={{ width: `calc(100% - 50px)` }}>
          <Typography
            variant="text"
            sx={{
              fontWeight: "600",
              display: "flex",
              color: theme.palette.dark.main,
              justifyContent: "center",
              // fontSize:"2rem"
            }}
          >
            {t("navbar.menu.userProfile.hScreen.title")}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: theme.palette.secondary.main,
          display: isDataAvailable ? "block" : "none",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { xs: "start", md: "space-between" },
            alignItems: "center",
            overflowX: "auto",
            position: "sticky",
            top: "60px",
            zIndex: 1,
            bgcolor: theme.palette.secondary.main,
          }}
        >

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0.3rem 0",
              height: "100%",
              ml: "1rem",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor={theme.palette.secondary.main}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                width: "100%",
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledTab
                label={t("navbar.menu.userProfile.hScreen.all")}
                selected={value === 0}
                themeMode={themeMode}
              />
              <StyledTab
                label={t("navbar.menu.userProfile.hScreen.sell")}
                selected={value === 1}
                themeMode={themeMode}
              />
              <StyledTab
                label={t("navbar.menu.userProfile.hScreen.rent")}
                selected={value === 2}
                themeMode={themeMode}
              />
            </Tabs>
          </Box>
        </Box>
        <Box>
          <Box>
            {value === 0 && <AllPropertyHistoryScreen setIsDataAvailable={setIsDataAvailable} setisLoading={setisLoading}/>}
            {value === 1 && <RentPropertyHistoryScreen />}
            {value === 2 && <SellPropertyHistoryScreen />}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: theme.palette.secondary.main,
          display: !isDataAvailable ? "block" : "none",
          width: "100%",
          height: `calc(100vh - 60px)`,
          // border:'solid red'
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              minHeight: `calc( 100vh - 117px)`,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: theme.palette.secondary.main,
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box
            sx={{
              minHeight: `calc( 100vh - 117px)`,
              width: "100%",
              display: !isDataAvailable ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <NoData />
            <Button
              variant="contained"
              size="large"
              sx={{
                padding: "0.3em 5em",
                textTransform: "capitalize",
                bgcolor: theme.palette.gold.main,
                borderRadius: "12px",
                fontSize: "1.1rem",
                color: "white",
              }}
              onClick={() => navigate(Path.ADD_PROPERTY)}
            >
              Add Property
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
