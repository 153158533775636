import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Tab,
  Tabs,
  useMediaQuery,
  Fade,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  AgricultureSVG,
  CommercialSVG,
  IndusrialSVG,
  ResidentSVG,
} from "../../common/Svg";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Context/LanguageContext";
import ResidentEvaluation from "./Resident/ResidentEvaluation";
import CommercialEvaluation from "./Commercial/CommercialEvaluation";
import IndustrialEvaluation from "./Indusrial/IndustrialEvaluation";
import AgriculturalEvaluation from "./Agricultural/AgriculturalEvaluation";
import ResidentCalculation from "./Resident/ResidentCalculation";
import CommercialCalculation from "./Commercial/CommercialCalculation";
import IndustrialCalculation from "./Indusrial/IndustrialCalculation";
import AgriculturalCalculation from "./Agricultural/AgriculturalCalculation";

export default function PropertyEvaluationScreen(props) {
  const { themeMode } = props;
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState({})
  const [isCalculated, setIsCalculated] = useState(false)


  const tabData = [
    {
      label: t("eval.res"),
      renderIcon: (color) => <ResidentSVG color={color} />,
      content: isCalculated ? <ResidentCalculation data={data} /> : <ResidentEvaluation setData={setData} setIsCalculated={setIsCalculated} />,
    },
    {
      label: t("eval.com"),
      renderIcon: (color) => <CommercialSVG color={color} />,
      content: isCalculated ? <CommercialCalculation data={data} /> : <CommercialEvaluation setData={setData} setIsCalculated={setIsCalculated} />,
    },
    {
      label: t("eval.ind"),
      renderIcon: (color) => <IndusrialSVG color={color} />,
      content: isCalculated ? <IndustrialCalculation data={data} /> : <IndustrialEvaluation setData={setData} setIsCalculated={setIsCalculated} />,
    },
    {
      label: t("eval.agri"),
      renderIcon: (color) => <AgricultureSVG color={color} />,
      content: isCalculated ? <AgriculturalCalculation data={data} /> : <AgriculturalEvaluation setData={setData} setIsCalculated={setIsCalculated} />,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsCalculated(false)
  };


  const getColor = (index) =>
    themeMode === "light"
      ? index === value
        ? theme.palette.bg.main
        : theme.palette.primary.main
      : index === value
        ? theme.palette.dark.main
        : theme.palette.primary.main;

  return (
    <Container
      maxWidth="xxl"
      sx={{
        bgcolor: {
          xs: theme.palette.bg.main,
          sm: theme.palette.secondary.main,
        },
      }}
    >
      <Box>
        <Box
          sx={{
            paddingTop: { xs: "2rem", sm: "0" },
            paddingBottom: { xs: "1rem", sm: "0" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              minHeight: "calc(100vh - 144px)",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "7rem 0 2rem 0",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "99%",
                  md: "85%",
                },
              }}
            >
              <Box sx={{ mb: "1rem" }}>
                <Typography
                  variant="subHeading"
                  sx={{
                    fontWeight: "600",
                    ml: "0.5rem",
                    color: theme.palette.dark.main,
                    direction: language === "ar" ? "rtl" : "ltr",
                    // textAlign: language === "ar" ? "right" : "left",
                  }}
                >
                  {t("eval.title")}
                </Typography>
                <Typography
                  sx={{ color: theme.palette.paragraph.main, ml: "0.5rem" }}
                >
                  {t("eval.tagline")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: {
                  xs: "99%",
                  md: "85%",
                },
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "flex-start",
                gap: "1rem",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "220px",
                  maxWidth: { md: '250px' },
                  bgcolor: theme.palette.bg.main,
                  borderRadius: "20px",
                  padding: { md: "1rem" },
                  width: '100%'
                }}
              >
                <Box sx={{ mb: { md: "1rem" } }}>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      textAlign: { xs: "start", md: "center" },
                      ml: "0.5rem",
                      fontWeight: "600",
                    }}
                  >
                    {t("eval.subHead")}
                  </Typography>
                </Box>
                <Tabs
                  orientation={isMobile ? "horizontal" : "vertical"}
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  sx={{
                    borderRight: `1px solid ${theme.palette.divider}`,
                    overflow: "scroll",
                  }}
                >
                  {tabData.map((tab, index) => (
                    <Tab
                      key={index}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            padding: { md: "0.5rem" },
                            alignItems: "center",
                            gap: "0.5rem",
                            color:
                              themeMode === "light"
                                ? index === value
                                  ? theme.palette.bg.main
                                  : theme.palette.primary.main
                                : index === value
                                  ? theme.palette.dark.main
                                  : theme.palette.primary.main,
                          }}
                        >
                          {tab.renderIcon(getColor(index))}

                          <Typography
                            sx={{
                              textTransform: "none",
                              fontSize: "1.1rem",
                              fontWeight: index === value && "500",
                            }}
                          >
                            {tab.label}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        m: "1rem",
                        bgcolor:
                          themeMode === "light"
                            ? index === value
                              ? theme.palette.primary.main
                              : theme.palette.bg.main
                            : index === value
                              ? theme.palette.gold.main
                              : theme.palette.bg.main,
                        color:
                          themeMode === "light"
                            ? index === value
                              ? theme.palette.bg.main
                              : theme.palette.primary.main
                            : index === value
                              ? theme.palette.dark.main
                              : theme.palette.primary.main,
                        borderBottom:
                          themeMode === "light"
                            ? "1px solid #1F2438BF"
                            : "1px solid #E8E8E850",
                        display: "flex",
                        alignItems: "center",
                        padding: "15px",
                        borderRadius: "12px",
                        cursor: "pointer",
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: theme.palette.bg.main,
                  borderRadius: "20px",
                  padding: { xs: "0", md: "1.2rem" },
                }}
              >
                <Fade in={true} timeout={1500} key={value}>
                  <Box sx={{ minHeight: "525px", color: theme.palette.dark.main }}>
                    {tabData[value].content}
                  </Box>
                </Fade>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
