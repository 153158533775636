function AppURL() {
  return {

    SIGN_UP: "/sign-up",
    AGENCY_LIST: "/agency-list",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot_password",
    FCN_TOKEN: "/auth/fcm/add-update",
    USERDETAILS_BY_ID: "/auth/users/get-by-id",

    //notification
    NOTIFICATION: "/auth/property/request/notification-list",
    LOCALIZATION: "/auth/users/update-lang",

    // PROPERTY
    GET_PROPERTY_LIST: "/auth/property/get-list",
    GET_PROPERTY_LIST_RENT: "/auth/property/get-list?property_for=2",
    GET_PROPERTY_LIST_SELL: "/auth/property/get-list?property_for=1",
    PROPERTY_DETAILS: "/auth/property/get-details",
    SEND_PROPERTY_REQUEST: "/auth/property/request/send-request",
    PROPERTY_CATEGORY_LIST: "/auth/property/category/get-list",
    PROPERTY_TYPE_LIST: "/auth/property/get-type",
    PROPERTY_CHAT_REQUEST: "/auth/property/request/request-accept-reject",
    PROPERTY_STATUS: "/auth/property/update-status",

    //ADD NEW  PROPERTY 
    PROPERTY_ADD: "/auth/property/add",
    ADD_PROPERTY_MEDIA: "/auth/property/add-media",

    // USER-PROFILE
    UPADATE_USER_PROFILE: "/auth/users/update",
    LOGIN_USER_DETAILS: "/auth/users/get-details",
    DELETE_ACCOUNT: "/auth/users/delete-account",

    // History property List for user  
    GET_USER_PROPERTY_LIST: "/auth/property/get-my-properties",
    GET_USER_PROPERTY_RENT_LIST: "/auth/property/get-my-properties?property_for=2",
    GET_USER_PROPERTY_SELL_LIST: "/auth/property/get-my-properties?property_for=1",
    GET_USER_REQUESTED_PROPERTY_LIST: "/auth/property/request/get-request-by-filter",
    REQUEST_TO_USERS_PROPERTY: "/auth/property/get-my-property-request-list",
    SEND_REQUEST: "/auth/property/request/send-request",

    // GENEREAL
    REGENERAT_TOKEN: "/auth/users/generate-jwt-token",
    LOGIN_AS_GUEST: "//login-as-guest-user",

    //contact / Help form api 
    CONTACT_HELP_FORM: "/contact-help",

    // Search property with filter 
    SEARCH_PROPERTY_WITH_FILTER: "/auth/property/property-by-filter",


    //newsLetter
    NEWSLETTER_SUBSCRIBE: "/newsletter",

    //chat
    GET_USER_ID: "/auth/chat/get-id",
    GET_CHAT_LIST: "/auth/chat/get-list",
    SEND_MSG: "/auth/chat/send-msg",
    GET_MSG: "/auth/chat/get-msg",
    DELETE_MSG: "/auth/chat/delete-msg",
    DELETE_CHAT: "/auth/chat/delete-chat",
    UNREAD_MSG_COUNT: "/auth/chat/read-last-msg",
  }
}

export default AppURL;
