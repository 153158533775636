import {
  Box,
  CircularProgress,
  Container,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import PlaceIcon from "@mui/icons-material/Place";
import Typography from "@mui/material/Typography";
import { Button, InputAdornment } from "@mui/material";
import InputComponent from "../../component/Input/InputComponentTextField";
import formValidator from "../../common/Validator";
import InputComponentOutline from "../../component/Input/InputComponentOutline";
import { useNavigate } from "react-router-dom";
import apiHelper from "../../common/API/ApiHelper";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorMsgComponent from "../../component/Input/ErrorMsgComponent";
import MediaUploadModal from "./MediaUploadModal";
import VideoUploadModal from "./VideoUploadModal";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext } from "../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";
import GMapComponent from "../GoogleMap/GMap";
import { baseUrl } from "../../common/Constant";

export default function AddPropertyScreen({ verifyUserLogin }) {
  const { language, handleOpen_MapModal, GlatLng } =
    useContext(LanguageContext);
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [loading, setloading] = useState(false);
  const [category, setCategory] = useState([])

  const { t } = useTranslation();

  const initial = {
    property: "0",
    province: "",
    city: "",
    location: "",
    image: [],
    video: [],
    Price: 0,
    description: "",
    bedroom: 0,
    bathroom: 0,
    parking: 0,
    // tv: 0,
    condition: 0,
    renovation: 0,
    majorRoad: 0,
    propertyFor: 0,
    transportation: "",
  };

  const [formDetails, setFormDetails] = useState(initial);
  const imageCount = formDetails.image.length;
  const videoCount = formDetails.video.length;


  const fetchCategory = async () => {
    try
    {
      const res = await apiHelper.fetchPropertyTypeList();
      setCategory(res?.data?.r);
      setloading(false);
    } catch (error)
    {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, [])

  const navigate = useNavigate();

  const removeImage = (id) => {
    setFormDetails((prevData) => {
      const updatedImages = [...prevData.image];
      updatedImages.splice(id, 1);
      return { ...prevData, image: updatedImages };
    });
  };

  const removeVideo = (id) => {
    setFormDetails((prevData) => {
      const updatedVideos = [...prevData.video];
      updatedVideos.splice(id, 1);
      return { ...prevData, video: updatedVideos };
    });
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const checkboxValue = e.target.value;

    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      transportation: isChecked
        ? prevFormDetails.transportation
          ? `${prevFormDetails.transportation},${checkboxValue}`
          : checkboxValue
        : prevFormDetails?.transportation
          .split(",")
          .filter((x) => x !== checkboxValue)
          .join(","),
    }));
  };

  // if (role === 2 || role === 1) {
  //   formDetails.autoGeneratedID = "";
  // }

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const vdoThumbGenerator = async (file, handFle = () => { }) => {
    if (file)
    {
      try
      {
        const video = document.createElement("video");
        video.setAttribute("src", URL.createObjectURL(file));
        video.addEventListener("loadeddata", async () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            const thumbnailFile = new File([blob], "thumbnail.png", {
              type: "image/png",
              lastModified: Date.now(),
            });
            handFle(thumbnailFile);
          });
        });
        await video.play(); // Ensure video is playing to capture a frame
      } catch (error)
      {
        console.error("Error generating thumbnail:", error);
      }
    }
  };

  const handleVideoChange = async (event, type, id) => {
    const selectedFile = event.target.files[0];

    await vdoThumbGenerator(selectedFile, (i) => {
      const obj = {
        video: selectedFile,
        categories_id: id,
        video_thumb: i,
      };

      // Update the state based on the previous state
      setFormDetails((prevData) => {
        let updatedData;
        if (prevData[type] && Array.isArray(prevData[type]))
        {
          updatedData = [...prevData[type], obj];
        } else
        {
          updatedData = [obj];
        }
        // Update the formDetails state
        return { ...prevData, [type]: updatedData };
      });
    });
  };

  const handleFileChange = (event, type) => {
    const selectedFile = event.target.files;
    const BigImg = Array.from(selectedFile).some((x) => x.size < 2140000);

    if (BigImg)
    {
      if (Array.from(selectedFile).length > 0)
      {
        if (isSubmitted)
        {
          const validationResult = formValidator(
            formDetails,
            "addProperty",
            "",
            t
          );
          setValidationError(validationResult);
        }
        setFormDetails((prevData) => {
          let updatedImages;
          if (prevData.image && Array.isArray(prevData.image))
          {
            updatedImages = [...prevData.image, ...selectedFile];
            updatedImages.length > 4 &&
              setValidationError((data) =>
                data.filter((item) => item.key !== "image")
              );
          } else
          {
            updatedImages = Array.from(selectedFile);
          }
          return { ...prevData, image: updatedImages };
        });
      } else
      {
        setFormDetails({ ...formDetails, [type]: selectedFile });
      }
    } else
    {
      return toast.warn(
        language === "ar"
          ? "يجب أن يكون حجم الصورة أقل."
          : "Image size should be less."
      );
    }
  };

  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted)
    {
      const validationResult = formValidator(
        updatedFormDetails,
        "addProperty",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  const GetAddressFromLng = async (lat, lng) => {
    try
    {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDQA_QHDdWzhTzvutj_tV6IVGoyIu1PXBM`
      );
      const data = await response.json();
      if (data?.status === "OK")
      {
        setFormDetails({
          ...formDetails,
          location: data.results[0].formatted_address,
          lat: lat,
          lng: lng,
        });
      }
    } catch (error)
    {
      console.log(error.message);
    }
  };

  useEffect(() => {
    GlatLng && GetAddressFromLng(GlatLng.lat, GlatLng.lng); //eslint-disable-next-line
  }, [GlatLng]);

  const HandleFormSubmittedDetails = async () => {
    try
    {
      if (isLoggedIn.id === 0 && isLoggedIn.role === 4)
      {
        return verifyUserLogin();
      }
      setIsSubmitted(true);
      const validationResult = formValidator(formDetails, "addProperty", "", t);
      if (validationResult.length > 0)
      {
        console.log(validationResult);
        setValidationError(validationResult);
        return;
      }

      if (formDetails.propertyFor === 0)
      {
        toast.error(
          language === "en"
            ? "Required field property for is empty "
            : "خاصية الحقل المطلوبة فارغة"
        );
        return;
      }

      if (formDetails.transportation.length === 0)
      {
        toast.error(
          language === "en"
            ? "Required field transportation is empty "
            : "النقل الميداني المطلوب فارغ"
        );
        return;
      }
      if (formDetails.condition === 0)
      {
        toast.error(
          language === "en"
            ? "Required field building condition is empty "
            : "حالة بناء الحقل المطلوب فارغة"
        );
        return;
      }
      if (formDetails.renovation === 0)
      {
        toast.error(
          language === "en"
            ? "Required field recent renovation is empty "
            : "الحقل المطلوب التجديد الأخير فارغ"
        );
        return;
      }
      if (formDetails.majorRoad === 0)
      {
        toast.error(
          language === "en"
            ? "Required field access to major Road is empty "
            : "حقل الوصول المطلوب إلى الطريق الرئيسي فارغ"
        );
        return;
      }
      setValidationError([]);
      setloading(true);
      const res = await apiHelper.AddProperty(formDetails);
      if (res?.data?.s === 1)
      {
        toast.success(res?.data?.m);
        setloading(false);
        setFormDetails(initial);
        navigate(-1);
      } else if (res?.data?.s === 0 && res?.data?.m)
      {
        toast.error(res?.data?.m);
        setFormDetails(initial);
      }
      setloading(false);
    } catch (error)
    {
      setloading(false);
      error?.response?.data?.message
        ? console.log(error?.response?.data?.message)
        : console.log(error.message);
    }
  };

  useEffect(() => {
    let userInfo;
    try
    {
      userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } catch (error)
    {
      userInfo = null;
    }
    if (
      !userInfo ||
      (userInfo.role === 4 && userInfo.email === "guestuser@gmail.com") ||
      userInfo.status === 2 ||
      userInfo.status === -1 ||
      userInfo.status === 0 ||
      (userInfo.agency.length === 0 && userInfo.role === 1)
    )
    {
      setisLoggedIn(false);
      verifyUserLogin();
      navigate(-1);
    } else
    {
      setisLoggedIn(true);
    } //eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        mt: "84px",
        bgcolor: theme.palette.navbarBG.main,
        height: `calc(100vh - 84px)`,
        overflowX: "auto",
      }}
      className={"HideScrollBar"}
    >
      <ScrollAnimation animateIn="fadeIn">
        <Container
          maxWidth={"xl"}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: { xs: "10px", sm: "20px 5px" } }}>
            <Typography
              variant="text"
              sx={{
                fontWeight: "500",
                textAlign: "start",
                mt: "2rem",
                color: theme.palette.dark.main,
              }}
            >
              {t("addProperty.tagline")}
            </Typography>
          </Box>

          <Box sx={{ width: { xs: "100%", sm: "90%", md: "95%" } }}>
            <Box
              sx={{
                borderRadius: "12px",
                padding: { xs: "0.5rem", sm: "1.5rem", md: "2rem 3rem" },
                boxShadow: {
                  xs: "",
                  sm: " 4px 4px 13px 0px rgba(0, 0, 0, 0.15)",
                },
                bgcolor: { xs: "transparent", sm: theme.palette.bg.main },
                color: theme.palette.dark.main,
                height: `calc(100vh - 260px)`,
                display: "flex",
                flexDirection: "column",
                overflowX: "auto",
              }}
            >
              <Box>
                <h4
                  style={{
                    marginBottom: "0px",
                    marginTop: "0px",
                    direction: language === "ar" ? "rtl" : "ltr",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "1.5em",
                      ml: "0.1rem",
                    }}
                  >
                    {t("addProperty.title")}
                  </Typography>
                </h4>
                <Typography
                  sx={{
                    color: theme.palette.paragraph.main,
                    fontSize: "0.9em",
                    ml: "0.1rem",
                    direction: language === "ar" ? "rtl" : "ltr",
                  }}
                >
                  {t("addProperty.subhead")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    md: "flex",
                  },
                  gap: "3rem",
                  mt: "1rem",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      md: "50%",
                    },
                  }}
                >
                  <Box sx={{ mb: "0.8rem", width: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.property")}
                    </Typography>
                    <Select
                      id="property"
                      name="property"
                      value={formDetails?.property}
                      onChange={handleFormDetails}
                      required
                      IconComponent={ExpandMoreIcon}
                      fullWidth
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "0.5rem",
                            borderRadius: "12px",
                          },
                        },
                      }}
                      sx={{
                        bgcolor: "white",
                        borderRadius: "12px",
                        width: "100%",
                        "& fieldset": { border: "none" },

                        color: `#1f2438 `,
                        "& .MuiOutlinedInput-input": {
                          padding: "14px ",
                        },
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },

                        ".MuiSvgIcon-root ": {
                          fill:
                            theme.palette.mode === "dark" &&
                            "#1f2438 !important",
                        },
                      }}
                      style={{
                        color: "#1f2438",
                      }}
                    >
                      <MenuItem value="0" disabled style={{ display: "none" }}>
                        <span style={{ color: "grey" }}>
                          {t("addProperty.propertyPlace")}
                        </span>
                      </MenuItem>
                      {loading ? (
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "grey",
                          }}
                        >
                          Loading...
                        </Box>
                      ) : category?.length > 0 ? (
                        category?.map((x) => (
                          <MenuItem key={x.id} id={x.id} value={x.id}>
                            {/* <img src={`${baseUrl}/uploads/property/icon/${x.icon}`} style={{ borderRadius: "11px", height: '25px', width: '25px' }} alt="" srcset="" /> */}
                            {/* &nbsp;&nbsp; */}
                            {x.name}
                          </MenuItem>
                        ))
                      ) : (
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "grey",
                          }}
                        >
                          Not found.
                        </Box>
                      )}
                    </Select>
                    <ErrorMsgComponent
                      validationError={validationError}
                      id={"property"}
                    />
                  </Box>

                  <Box
                    sx={{
                      // mb: "0.8rem",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", md: "row" },
                      gap: { xs: "0", md: "1rem" },
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", md: "50%" },
                        mb: "0.4rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: "3px",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.bed")}
                      </Typography>
                      <InputComponent
                        type="number"
                        name="bedroom"
                        onChange={handleFormDetails}
                        inputMode="numeric"
                        isError={validationError?.find((x) => {
                          return x.key === "bedroom" ? true : false;
                        })}
                        helperText={
                          validationError?.find((x) => x.key === "bedroom")
                            ?.message
                        }
                        sx={{
                          bgcolor: "white",
                          " .MuiOutlinedInput-input": {
                            textAlign: language === "ar" ? "right" : "left",
                          },
                          // input: {
                          //   "&::placeholder": {
                          //     textAlign: language === "ar" ? "right" : "left",
                          //   },
                          // },
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        id="bedroom"
                        value={formDetails?.bedroom}
                        placeholder={t("addProperty.bedPlace")}
                        style={{ color: "#1f2438" }}
                      />
                      {/* <ErrorMsgComponent
                      validationError={validationError}
                      id={"bedroom"}
                    /> */}
                    </Box>

                    <Box
                      sx={{
                        width: { xs: "100%", md: "50%" },
                        mb: "0.4rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: "3px",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.bath")}
                      </Typography>
                      <InputComponent
                        type="number"
                        name="bathroom"
                        onChange={handleFormDetails}
                        isError={validationError?.find((x) => {
                          return x.key === "bathroom" ? true : false;
                        })}
                        helperText={
                          validationError?.find((x) => x.key === "bathroom")
                            ?.message
                        }
                        sx={{
                          bgcolor: "white",
                          " .MuiOutlinedInput-input": {
                            textAlign: language === "ar" ? "right" : "left",
                          },
                          // input: {
                          //   "&::placeholder": {
                          //     textAlign: language === "ar" ? "right" : "left",
                          //   },
                          // },
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        id="bathroom"
                        value={formDetails?.bathroom}
                        placeholder={t("addProperty.bathPlace")}
                        style={{ color: "#1f2438" }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      // mb: "0.8rem",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", md: "row" },
                      gap: { xs: "0", md: "1rem" },
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%" },
                        mb: "0.4rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: "3px",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.parking")}
                      </Typography>
                      <InputComponent
                        type="number"
                        name="parking"
                        onChange={handleFormDetails}
                        isError={validationError?.find((x) => {
                          return x.key === "parking" ? true : false;
                        })}
                        helperText={
                          validationError?.find((x) => x.key === "parking")
                            ?.message
                        }
                        sx={{
                          bgcolor: "white",
                          " .MuiOutlinedInput-input": {
                            textAlign: language === "ar" ? "right" : "left",
                          },

                          width: "100%",
                          borderRadius: "12px",
                        }}
                        id="parking"
                        value={formDetails?.parking}
                        placeholder={t("addProperty.parkingPlace")}
                        style={{ color: "#1f2438" }}
                      />
                    </Box>

                    {/* <Box
                      sx={{
                        width: { xs: "100%", md: "50%" },
                        mb: "0.4rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: "3px",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.tv")}
                      </Typography>
                      <InputComponent
                        type="number"
                        name="tv"
                       onChange={handleFormDetails}
                        isError={validationError?.find((x) => {
                          return x.key === "tv" ? true : false;
                        })}
                        helperText={
                          validationError?.find((x) => x.key === "tv")?.message
                        }
                        sx={{
                          bgcolor: "white",
                          " .MuiOutlinedInput-input": {
                            textAlign: language === "ar" ? "right" : "left",
                          },
                          // input: {
                          //   "&::placeholder": {
                          //     textAlign: language === "ar" ? "right" : "left",
                          //   },
                          // },
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        id="tv"
                        value={formDetails?.tv}
                        placeholder={t("addProperty.tvPlace")}
                        style={{ color: "#1f2438" }}
                      />
                    </Box> */}
                  </Box>

                  <Box sx={{ mb: "0.8rem", width: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.provience")}
                    </Typography>

                    <InputComponent
                      type="text"
                      onChange={handleFormDetails}
                      isError={validationError?.find((x) => {
                        return x.key === "province" ? true : false;
                      })}
                      required={true}
                      helperText={
                        validationError?.find((x) => x.key === "province")
                          ?.message
                      }
                      sx={{
                        bgcolor: "white",
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                        width: "100%",
                        borderRadius: "12px",
                      }}
                      id="province"
                      name="province"
                      value={formDetails?.province}
                      placeholder={t("addProperty.proviencePlace")}
                      style={{ color: "#1f2438" }}
                    />
                  </Box>

                  <Box sx={{ mb: "0.8rem", width: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.city")}
                    </Typography>

                    <InputComponent
                      type="text"
                      onChange={handleFormDetails}
                      isError={validationError?.find((x) => {
                        return x.key === "city" ? true : false;
                      })}
                      required={true}
                      helperText={
                        validationError?.find((x) => x.key === "city")?.message
                      }
                      sx={{
                        bgcolor: "white",
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                        width: "100%",
                        borderRadius: "12px",
                      }}
                      id="city"
                      name="city"
                      value={formDetails?.city}
                      placeholder={t("addProperty.cityPlace")}
                      style={{ color: "#1f2438" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      mb: "1rem",
                      width: "100%",
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                      justifyContent: {
                        xs: "center",
                        sm: "space-between",
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: "3px",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.for.title")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "space-around", sm: "start" },
                          gap: "1rem",
                        }}
                      >
                        <Box>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="propertyFor"
                              value={1}
                              onChange={handleFormDetails}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.for.sell")}
                          </label>
                        </Box>
                        <Box sx={{ display: "inline-block" }}>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="propertyFor"
                              value={2}
                              onChange={handleFormDetails}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.for.rent")}
                          </label>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        mt: { xs: "0.5rem", sm: "0" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: " 3px 0",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.condition.title")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "space-around", sm: "start" },
                          direction: language === "ar" ? "rtl" : "ltr",
                          gap: "1rem",
                        }}
                      >
                        <Box sx={{ display: "inline-block" }}>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="condition"
                              value={1}
                              onChange={handleFormDetails}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.condition.bad")}
                          </label>
                        </Box>
                        <Box sx={{ display: "inline-block" }}>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="condition"
                              value={2}
                              onChange={handleFormDetails}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.condition.good")}
                          </label>
                        </Box>
                        <Box sx={{ display: "inline-block" }}>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="condition"
                              value={3}
                              onChange={handleFormDetails}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.condition.exce")}
                          </label>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ mb: "0.8rem", width: "100%" }}>
                    <GMapComponent />
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.location")}
                    </Typography>
                    <InputComponent
                      onClick={() => {
                        handleOpen_MapModal();
                      }}
                      id="location"
                      name="location"
                      readOnly={true}
                      placeholder={t("addProperty.locationPlace")}
                      value={formDetails?.location}
                      isError={validationError?.find((x) => {
                        return x.key === "location" ? true : false;
                      })}
                      required={true}
                      helperText={
                        validationError?.find((x) => x.key === "location")
                          ?.message
                      }
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PlaceIcon sx={{ mr: "1rem" }} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      sx={{
                        color: `#1f2438`,
                        bgcolor: "white",
                        width: "100%",
                        borderRadius: "12px",
                        "& fieldset": { border: "none" },
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                        "& .MuiOutlinedInput-input": {
                          padding: "14px ",
                        },
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#1f2438",
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      md: "50%",
                    },
                  }}
                >
                  <Box sx={{ mb: "0.8rem" }}>
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.photo.title")}
                    </Typography>
                    <InputComponentOutline
                      sx={{
                        bgcolor: "white",
                        width: "100%",
                        borderRadius: "12px",
                        color: "#1f2438",
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                      }}
                      placeholder="Upload Photos "
                      value={`${t("addProperty.photo.place", {
                        count: imageCount,
                      })}`}
                      id="image"
                      name="image"
                      isError={validationError?.find((x) => {
                        return x.key === "image" ? true : false;
                      })}
                      helperText={
                        validationError?.find((x) => x.key === "image")?.message
                      }
                      readOnly
                      endAdornment={
                        <InputAdornment position="end">
                          <MediaUploadModal
                            removeImage={removeImage}
                            data={formDetails.image}
                            InputMode={"image"}
                            handleFileChange={handleFileChange}
                          />
                        </InputAdornment>
                      }
                    />
                  </Box>

                  <Box sx={{ mb: "0.8rem" }}>
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.video.title")}
                    </Typography>
                    <InputComponentOutline
                      sx={{
                        bgcolor: "white",
                        width: "100%",
                        color: "#1f2438",
                        borderRadius: "12px",
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                      }}
                      placeholder="Upload Videos"
                      value={`${t("addProperty.video.place", {
                        count: videoCount,
                      })}`}
                      id="video"
                      name="video"
                      isError={validationError?.find((x) => {
                        return x.key === "video" ? true : false;
                      })}
                      helperText={
                        validationError?.find((x) => x.key === "video")?.message
                      }
                      readOnly
                      endAdornment={
                        <InputAdornment position="end">
                          <VideoUploadModal
                            removeImage={removeVideo}
                            data={formDetails?.video}
                            handleVideoChange={handleVideoChange}
                          />
                        </InputAdornment>
                      }
                    />
                  </Box>

                  <Box sx={{ mb: "0.8rem" }}>
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.price")}
                    </Typography>
                    <InputComponent
                      type="number"
                      name="Price"
                      onChange={handleFormDetails}
                      isError={validationError?.find((x) => {
                        return x.key === "Price" ? true : false;
                      })}
                      helperText={
                        validationError?.find((x) => x.key === "Price")?.message
                      }
                      sx={{
                        bgcolor: "white",
                        width: "100%",
                        borderRadius: "12px",
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                      }}
                      id="Price"
                      value={formDetails.Price}
                      placeholder={t("addProperty.pricePlace")}
                      style={{ color: "#1f2438" }}
                    />
                  </Box>

                  <Box sx={{ width: "100%", mb: "0.8rem" }}>
                    <Typography
                      sx={{
                        fontSize: "0.9em",
                        m: "0 3px",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {t("addProperty.descr")}
                    </Typography>

                    <textarea
                      placeholder={t("addProperty.descrPlace")}
                      value={formDetails.description}
                      name="description"
                      draggable="false"
                      style={{
                        textAlign: language === "ar" ? "right" : "left",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        lineHeight: "23px",
                        borderRadius: "12px",
                        padding: "14px 14px",
                        border: "1px solid #ced4da",
                        outline: "none",
                        width: "100%",
                        maxWidth: "100%",
                        boxSizing: "border-box",
                        overflow: "hidden",
                      }}
                      onChange={handleFormDetails}
                      id="description"
                      rows={1}
                    // cols={formDetails.description.length}
                    ></textarea>

                    <ErrorMsgComponent
                      validationError={validationError}
                      id={"description"}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                      justifyContent: {
                        xs: "space-around",
                        sm: "space-between",
                      },
                      mt: "1rem",
                      mb: { xs: "0", md: "0.8rem" },
                      direction: language === "ar" ? "rtl" : "ltr",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: "0.9em", ml: "3px" }}>
                        {t("addProperty.trans.title")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: "1rem",
                          }}
                        >
                          <Box sx={{ display: "inline-block", m: "0.5rem 0" }}>
                            <label className="custom-radio">
                              <input
                                type="checkbox"
                                value={1}
                                onChange={handleCheckboxChange}
                              />
                              <span className="checkmark"></span>
                              {t("addProperty.trans.highway")}
                            </label>
                          </Box>

                          <Box
                            sx={{
                              display: "inline-block",
                              m: { xs: "0.5rem 0 ", sm: "0.5rem" },
                            }}
                          >
                            <label className="custom-radio">
                              <input
                                type="checkbox"
                                value={2}
                                onChange={handleCheckboxChange}
                              />
                              <span className="checkmark"></span>
                              {t("addProperty.trans.bus")}
                            </label>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",

                            flexDirection: { xs: "column", sm: "row" },
                          }}
                        >
                          <Box sx={{ display: "inline-block", m: "0.5rem" }}>
                            <label className="custom-radio">
                              <input
                                type="checkbox"
                                value={3}
                                onChange={handleCheckboxChange}
                              />
                              <span className="checkmark"></span>
                              {t("addProperty.trans.airports")}
                            </label>
                          </Box>
                          <Box
                            sx={{
                              display: "inline-block",
                              m: "0.5rem",
                            }}
                          >
                            <label className="custom-radio">
                              <input
                                type="checkbox"
                                value={4}
                                onChange={handleCheckboxChange}
                              />
                              <span className="checkmark"></span>
                              {t("addProperty.trans.ports")}
                            </label>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: { xs: "100%" },
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                      justifyContent: {
                        xs: "center",
                        sm: "space-between",
                      },
                      mt: "1rem",
                      direction: language === "ar" ? "rtl" : "ltr",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: "3px",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.reno.title")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "space-around", sm: "start" },
                          gap: "1rem",
                        }}
                      >
                        <Box>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="renovation"
                              id="yes"
                              onChange={handleFormDetails}
                              value={1}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.reno.yes")}
                          </label>
                        </Box>

                        <Box sx={{ display: "inline-block" }}>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="renovation"
                              id="no"
                              onChange={handleFormDetails}
                              value={0}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.reno.no")}
                          </label>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ mt: { xs: "0.5rem", sm: "0" } }}>
                      <Typography
                        sx={{
                          fontSize: "0.9em",
                          m: "3px",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("addProperty.road.title")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "space-around", sm: "start" },
                          gap: "1rem",
                        }}
                      >
                        <Box>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="majorRoad"
                              onChange={handleFormDetails}
                              value={1}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.road.yes")}
                          </label>
                        </Box>

                        <Box sx={{ display: "inline-block" }}>
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="majorRoad"
                              onChange={handleFormDetails}
                              value={0}
                            />
                            <span className="checkmark"></span>
                            {t("addProperty.road.no")}
                          </label>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "1.5em" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "0.3em 5em",
                    textTransform: "capitalize",
                    bgcolor: theme.palette.gold.main,
                    borderRadius: "12px",
                    fontSize: "1.1rem",
                    color: "white",
                  }}
                  onClick={HandleFormSubmittedDetails}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      {" "}
                      <CircularProgress
                        disableShrink
                        sx={{ color: "#1f2438", margin: "0.2rem" }}
                        size={20}
                      />
                    </>
                  ) : (
                    `${t("addProperty.button")}`
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </ScrollAnimation>
    </Box>
  );
}
