import { Box, Container, Grid, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";

export default function AboutScreen() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);



  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);
  return (
    <Box
      sx={{
        bgcolor: theme.palette.navbarBG.main,
        color: theme.palette.dark.main,
        overflow: "hidden"
        // minHeight: `calc(100vh - 550px)`,
      }}
    >
      <Container
        maxWidth={"xl"}
        sx={{
          padding: { xs: "1rem 2rem", sm: "2rem 3rem", md: "2rem 3rem 0 3rem" },
          mt: "85px",
        }}
      >
        <Box sx={{ color: theme.palette.paragraph.main }}>
          <Box>
            <h2
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
                color: theme.palette.dark.main,
              }}
            >
              {t("about.title")}
              <br />
            </h2>
          </Box>
          {/* p1 */}
          <ScrollAnimation animateIn="animate__pulse">
            <Box
              sx={{
                mt: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5rem",
                flexDirection: { sm: "column-reverse", md: "row" },
                // height: `calc( 100vh - 350px)`,
              }}
            >
              {/* Image */}
              <Box
                sx={{
                  width: { sm: "70%", md: "40%" },
                  display: { xs: "none", sm: "block" },
                }}
              >
                <img
                  src="/assets/image/AP/main.svg"
                  alt=""
                  style={{ width: "100%", height: "100%", }}
                  srcSet=""
                />
              </Box>
              {/* content\ */}
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                  textAlign: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                }}
              >
                <h3 sx={{ fontWeight: "600", mb: "0.5rem" }}>
                  {t("about.content.welcomeHeading")}
                </h3>
                <Typography>{t("about.content.welcomeContent")}</Typography>
              </Box>
            </Box>
          </ScrollAnimation>

          <Box
            sx={{
              mt: "3rem",
              direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
              textAlign: language === "ar" ? "right" : { xs: "justify", sm: "left" },
              mb: "3rem",
            }}
          >
            <Box
              sx={{
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  background:
                    "linear-gradient(14deg, rgba(152,151,157,1) 0%, rgba(31,36,56,1) 56%, rgba(230,236,237,1) 97%)",
                  height: "2px",
                  borderRadius: "12px",
                  display: { xs: "none", sm: "block" },
                }}
              ></Box>{" "}
              <Box
                sx={{
                  width: { xs: "100%", sm: "650px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.2rem",
                }}
              >
                {t("about.content.aldera")}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  background:
                    "linear-gradient(14deg, rgba(230,236,237,1) 0%, rgba(31,36,56,1) 56%, rgba(152,151,157,1) 97%)",
                  height: "2px",
                  borderRadius: "12px",
                  display: { xs: "none", sm: "block" },
                }}
              ></Box>
            </Box>
          </Box>

          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={12} lg={5.9} xl={5.9}>
              <ScrollAnimation animateIn="animate__slideInRight">
                <Box
                  sx={{
                    // border: "1px solid red",
                    boxShadow: "2px 2px 9px 1px rgba(0, 0, 0, 0.15)",
                    borderRadius: "12px",
                    padding: "1rem",
                    boxSizing: "border-box",
                    transition: "all 0.5s ease",
                    ":hover": {
                      scale: "1.015",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "12px",
                        width: { xs: "100%", sm: "200px" },
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/assets/image/AP/us.svg"
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                        srcSet=""
                      />
                    </Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        width: { xs: "100%", sm: `calc( 100% - 250px)` },
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "start",
                        flexDirection: "column",
                        padding: "0.5rem",
                        direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                        textAlign: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                      }}
                    >
                      <h3>{t("about.content.alderaP1T")}</h3>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `calc( 100% )`,
                        }}
                      >
                        {t("about.content.alderaP1")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ScrollAnimation>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5.9} xl={5.9}>
              <ScrollAnimation animateIn="animate__slideInLeft">
                <Box
                  sx={{
                    // border: "1px solid red",
                    boxShadow: "2px 2px 9px 1px rgba(0, 0, 0, 0.15)",
                    borderRadius: "12px",
                    padding: "1rem",
                    boxSizing: "border-box",
                    transition: "all 0.5s ease",
                    ":hover": {
                      scale: "1.015",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "12px",
                        width: { xs: "100%", sm: "200px" },
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/assets/image/AP/es.svg"
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                        srcSet=""
                      />
                    </Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        width: { xs: "100%", sm: `calc( 100% - 250px)` },
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "start",
                        flexDirection: "column",
                        padding: "0.5rem",
                        direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                        textAlign: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                      }}
                    >
                      <h3>{t("about.content.alderaP3T")}</h3>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `calc( 100% )`,
                        }}
                      >
                        {t("about.content.alderaP3")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ScrollAnimation>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5.9} xl={5.9}>
              <ScrollAnimation animateIn="animate__slideInRight">
                <Box
                  sx={{
                    // border: "1px solid red",
                    boxShadow: "2px 2px 9px 1px rgba(0, 0, 0, 0.15)",
                    borderRadius: "12px",
                    padding: "1rem",
                    boxSizing: "border-box",
                    transition: "all 0.5s ease",
                    ":hover": {
                      scale: "1.015",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "12px",
                        width: { xs: "100%", sm: "200px" },
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/assets/image/AP/eg.svg"
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                        srcSet=""
                      />
                    </Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        width: { xs: "100%", sm: `calc( 100% - 250px)` },
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "start",
                        flexDirection: "column",
                        padding: "0.5rem",
                        direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                        textAlign: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                      }}
                    >
                      <h3>{t("about.content.alderaP2T")}</h3>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `calc( 100% )`,
                        }}
                      >
                        {t("about.content.alderaP2")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ScrollAnimation>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5.9} xl={5.9}>
              <ScrollAnimation animateIn="animate__slideInLeft">
                <Box
                  sx={{
                    // border: "1px solid red",
                    boxShadow: "2px 2px 9px 1px rgba(0, 0, 0, 0.15)",
                    borderRadius: "12px",
                    padding: "1rem",
                    boxSizing: "border-box",
                    transition: "all 0.5s ease",
                    ":hover": {
                      scale: "1.015",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "12px",
                        width: { xs: "100%", sm: "200px" },
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/assets/image/AP/it.svg"
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                        srcSet=""
                      />
                    </Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        width: { xs: "100%", sm: `calc( 100% - 250px)` },
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "start",
                        flexDirection: "column",
                        padding: "0.5rem",
                        direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                        textAlign: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                      }}
                    >
                      <h3>{t("about.content.alderaP4T")}</h3>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `calc( 100% )`,
                        }}
                      >
                        {t("about.content.alderaP4")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ScrollAnimation>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={11.8}>
              <ScrollAnimation animateIn="animate__slideInRight">
                <Box
                  sx={{
                    // border: "1px solid red",
                    boxShadow: "2px 2px 9px 1px rgba(0, 0, 0, 0.15)",
                    borderRadius: "12px",
                    padding: "1rem",
                    boxSizing: "border-box",
                    transition: "all 0.5s ease",
                    ":hover": {
                      scale: "1.015",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "12px",
                        width: { xs: "100%", sm: "200px" },
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/assets/image/AP/cc.svg"
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                        srcSet=""
                      />
                    </Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        width: { xs: "100%", sm: `calc( 100% - 250px)` },
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "start",
                        flexDirection: "column",
                        padding: "0.5rem",
                        direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                        textAlign: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                      }}
                    >
                      <h3>{t("about.content.alderaP5T")}</h3>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `calc( 100% )`,
                        }}
                      >
                        {t("about.content.alderaP5")}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ScrollAnimation>
            </Grid>
          </Grid>

          <ScrollAnimation animateIn="animate__zoomIn">
            <Box
              sx={{
                // m: "3rem 0 0rem 0",
                display: {
                  xs: "block",
                  sm: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                },
              }}
            >
              <Box
                sx={{
                  mt: "1rem",
                  width: { xs: "100%", md: "50%" },
                  direction: language === "ar" ? "right" : { xs: "justify", sm: "left" },
                  textAlign: language === "ar" ? "right" : "justify",
                }}
              >
                <h3 style={{ fontWeight: "600", fontSize: "1.1rem" }}>
                  {t("about.content.journeyTitle")}
                </h3>
                <Typography>{t("about.content.journeyP1")}</Typography>
                <Typography>{t("about.content.journeyP2")}</Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  width: { xs: "100%", sm: "40%", md: "35%", lg: "25%" },
                }}
              >
                <img
                  src="/assets/image/AP/st.svg"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                  srcSet=""
                />
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
      </Container>
    </Box>
  );
}
