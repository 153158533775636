import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import PropertyCard from "../PropertyCard";
import apiHelper from "../../../common/API/ApiHelper";
import { NoData } from "../../../common/Svg";
import Loader from "../../../common/Loader";
import { useTranslation } from "react-i18next";

export default function RequestedPropertyScreen({ themeMode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const [fetchProperty, SetFetchedProperty] = useState([]);
  const [loading, setloading] = useState(false);

  const getPropertyList = async () => {
    try {
      setloading(true);
      const result = await apiHelper.Get_User_Property_Requested_History_List();

      if (result?.data?.r) {
        SetFetchedProperty(result.data.r);
      } else {
        toast.warn(result?.data?.m);
      }
      setloading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getPropertyList();
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  return (
    <Box sx={{ minHeight: `calc(100vh)`, position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          height: "60px",
          bgcolor: theme.palette.secondary.main,
          alignItems: "center",
          // p: "1.5rem",
          width: "100%",
          position: "sticky",
          zIndex: 1,
          top: "0px",
          // border:"1px solid red"
        }}
      >
        <Box
          sx={{
            width: "50px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon sx={{ color: theme.palette.dark.main }} />
        </Box>
        <Box sx={{ width: `calc(100% - 50px)` }}>
          <Typography
            variant="text"
            sx={{
              fontWeight: "600",
              display: "flex",
              color: theme.palette.dark.main,
              justifyContent: "center",
            }}
          >
            {t("navbar.menu.userProfile.requested")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ bgcolor: theme.palette.secondary.main }}>
        <>
          {loading ? (
            <Box
              sx={{
                minHeight: `calc( 100vh - 60px)`,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          ) : fetchProperty.length === 0 && !loading ? (
            <Box
              sx={{
                minHeight: `calc( 100vh - 60px)`,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NoData />
            </Box>
          ) : (
            <Box sx={{ padding: "1.5rem" }}>
              <Grid
                container
                spacing={"1.5rem"}
                sx={{ minHeight: `calc( 100vh - 84px )` }}
              >
                {fetchProperty &&
                  fetchProperty.map((x, i) => {
                    return (
                      <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <PropertyCard x={x} />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          )}
        </>
      </Box>
    </Box>
  );
}
