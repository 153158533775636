import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";

export default function AgriculturalCalculation() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          direction: language === "ar" ? "rtl" : "ltr",
        }}
      >
        <Box sx={{ width: "300px" }}>
          <Typography
            sx={{
              bgcolor: theme.palette.gold.main,
              color: theme.palette.dark.main,
              padding: "9px 22px",
              borderRadius: "12px",
            }}
          >
            <span
              style={{
                display: "block",
                color: "white",
                textAlign: "center",
              }}
            >
              {t("eval.calc.title")}
            </span>
            <span
              style={{
                display: "block",
                color: "white",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {t("eval.calc.detail")} : 483,000
            </span>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.pSize")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>200 SQ.M</Box>
        </Box>
        <Divider />

        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.sQua")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
        </Box>
        <Divider />

        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.cYh")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
        </Box>
        <Divider />

        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.GR")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
        </Box>
        <Divider />

        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.md")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
        </Box>
        <Divider />

        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.lgCrop")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
        </Box>
        <Divider />

        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.potential")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
        </Box>
        <Divider />

        <Box
          sx={{
            color: theme.palette.paragraph.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0.8rem",
          }}
        >
          <Box>{t("eval.calc.agri.water")} :</Box>
          <Box sx={{ fontWeigth: "600" }}>Irrigation</Box>
        </Box>

        <Divider />
      </Box>
    </Box>
  );
}
