import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Path from "../../common/Path";
import { baseUrl } from "../../common/Constant";
import { useTranslation } from "react-i18next";

export default function PropertyCard({ x }) {
  const [newPrice, setnewPrice] = React.useState();
  const { t } = useTranslation();
  const theme = useTheme();

  function formatUSD(value) {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toFixed(1);
    }
  }

  React.useEffect(() => {
    const formattedPrice = formatUSD(x?.price);
    setnewPrice(formattedPrice); //eslint-disable-next-line
  }, []);

  return (
    // <ScrollAnimation animateIn="fadeIn">
    <Link to={`${Path.PROPERTY_DETAILS}/${x?.id}`}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          className="propertyCard"
          sx={{
            borderRadius: "11px",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              padding: "7px 15px ",
              borderRadius: "9px",
              // bgcolor: x?.status === 1 ? theme.palette.gold.main : "#e59606",
              bgcolor: x?.status === 1 ? theme.palette.gold.main : "#d00101",
              // color: x?.status === 1 ? "#FFF" : "#000",
              color: "#FFF",
              fontSize: "11px",
              fontWeight: "400",
              letterSpacing: "0.5px",
            }}
          >
            {x.property_for && x?.property_for === 1
              ? x?.status === 1
                ? `${t("property.card.sell")}`
                : `${t("property.card.sold")}`
              : x?.status === 1
              ? `${t("property.card.rent")}`
              : `${t("property.card.rented")}`}
          </Box>
          <CardMedia
            component="img"
            alt="Property image"
            height="256px"
            sx={{
              objectFit: "cover",
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 30.47%, #000000 100%), url(/assets/image/property/detail/d.png) center top / cover no-repeat`,
            }}
            width={"100%"}
            image={
              x?.image[0]?.image === undefined
                ? "/assets/image/property/detail/d.png"
                : `${baseUrl}/uploads/property/image/${x?.image[0]?.image}`
            }
          />
          <CardContent sx={{ bgcolor: theme.palette.cardbg.main }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: "1.1rem",
                display: "flex",
                alignItems: "center",
                color: "#1f2438",
              }}
            >
              <img src="/assets/image/property/lctn.svg" alt="" srcSet="" />
              &nbsp;<span>{x?.city}</span>
            </Typography>
            <Box
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "13px", mt: "15px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "500",
                  color: "#1f2438",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.1rem",
                  }}
                >
                  <img src="/assets/image/property/bad.svg" alt="" />
                  <span>&nbsp;{x?.no_bed}&nbsp;</span>
                  <span>{t("property.card.bed")}</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.1rem",
                  }}
                >
                  <img src="/assets/image/property/bath.svg" alt="" />
                  <span>&nbsp;{x?.no_bath}&nbsp;</span>
                  <span>{t("property.card.bath")}</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.1rem",
                  }}
                >
                  <img src="/assets/image/property/car.svg" alt="" />
                  <span>&nbsp;{x?.no_parking}&nbsp;</span>
                  <span>{t("property.card.parking")}</span>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                bgcolor: theme.palette.cardbg.main,
                padding: "15px 0 0 0",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "1.1rem",
                  bgcolor: "#1f2438",
                  color: "#EEE",
                  borderRadius: "11px",
                  "&:hover": {
                    bgcolor: "#1f2438",
                    fontWeight: "normal",
                  },
                }}
              >
                {t("property.card.button")}
              </Button>
              <span
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  color: "#1f2438",
                  marginRight: "0.1rem",
                }}
              >
                KWD {newPrice}
              </span>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Link>
    // </ScrollAnimation>
  );
}
