import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment, useState } from "react";
import { LogoutSVG } from "../../../common/Svg";
import { useTranslation } from "react-i18next";

export default function UserLogoutDialogue({
  handleToggleDrawer,
  handleLogOut,
  setIsImageError,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setisLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Box
        onClick={() => {
          setOpen(true);
          handleToggleDrawer && handleToggleDrawer();
        }}
      >
        {t("navbar.menu.userProfile.logout.mainTitle")}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 5, // Adjusted border radius
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            backdropFilter: "blur(3px)",
          },
        }}
      >
        <Box sx={{ bgcolor: "#FFF", borderRadius: "12px" }}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "colomn",
              }}
            >
              <LogoutSVG color={theme.palette.gold.main} />
            </Box>
            <Typography
              sx={{
                pr: "1rem",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "22px",
                color: "#1f2438",
              }}
            >
              {t("navbar.menu.userProfile.logout.title")}
            </Typography>
            <Typography
              sx={{ textAlign: "center", pr: "5px", color: "#1f2438" }}
            >
              {t("navbar.menu.userProfile.logout.msg")}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Box
              sx={{
                minWidth: {
                  xs: "100%",
                  sm: " 280px",
                },
                display: {
                  xs: "block",
                  md: "flex",
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <Box
                  sx={{
                    mb: "1em",
                    gap: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    variant="outlined"
                    sx={{
                      bgcolor: "#EEE",
                      color: "#1f2438",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#EEE",
                      },
                      borderRadius: "12px",
                      width: "160px",
                    }}
                  >
                    {t("navbar.menu.userProfile.logout.cancle")}
                  </Button>
                  <Button
                    onClick={async () => {
                      setisLoading(true);
                      const res = await handleLogOut();
                      if (res === "done") {
                        setIsImageError(false);
                        setisLoading(false);
                        setOpen(false);
                      }
                    }}
                    variant="outlined"
                    sx={{
                      bgcolor: theme.palette.gold.main,
                      color: "#FFF",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: theme.palette.gold.main,
                      },
                      borderRadius: "12px",
                      width: "160px",
                    }}
                  >
                    {isLoading ? (
                      <>
                        <CircularProgress
                          disableShrink
                          sx={{ color: "white", margin: "0.2rem" }}
                          size={20}
                        />
                      </>
                    ) : (
                      `${t("navbar.menu.userProfile.logout.title")}`
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
}
