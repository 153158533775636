import { Box, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import ChattingUserMsgHandler from "./ChattingUserMsgHandler";
import { useLocation, useOutletContext } from "react-router-dom";
import { baseUrl } from "../../common/Constant";
import DeleteChatModal from "./delete/DeleteChatModal";
import { LanguageContext } from "../../Context/LanguageContext";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import defaultImg from "../../common/assets/image/User.svg";
import Path from "../../common/Path";
export default function ChatByIdScreen() {
  const theme = useTheme();
  const [setselectedChatId] = useOutletContext();
  const location = useLocation();
  const query = location.search;
  const params = new URLSearchParams(query);
  const img = params.get("img");
  const name = params.get("name");
  const uId = params.get("uId");
  const role = params.get("role");
  const { setChatInView } = useContext(LanguageContext);
  const [isImageError, setIsImageError] = useState(false);
  window.onpopstate = function (event) {
    if (
      window.location.pathname.includes("/chat/chats") ||
      window.location.pathname.includes("/chat")
    ) {
      window.location.pathname = `${Path.PROPERTY}`;
    }
  };

  useEffect(() => {
    setChatInView(uId); //eslint-disable-next-line
  }, [uId]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "15px" },
        borderRadius: "12px",
        height: `calc(100vh - 110px)`,
        bgcolor: theme.palette.bg.main,
        mt: "0px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "start",
        gap: "1rem",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: { xs: "block", md: "none" }, cursor: "pointer" }}>
            <ArrowBackIosIcon
              onClick={() => {
                setselectedChatId("");
              }}
              sx={{ color: theme.palette.dark.main }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: "45px",
              height: "45px",
              margin: "0 10px",
              // border: "solid red",
            }}
          >
            {isImageError ? (
              <Box
                width="100%"
                height={"100%"}
                borderRadius={"50%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2rem",
                  fontWeight: "600",
                  opacity: "0.7",
                  border: "0.5px solid grey",
                }}
              >
                {name?.at(0)?.toUpperCase()}
              </Box>
            ) : (
              <img
                src={
                  role === "2"
                    ? img === "null"
                      ? defaultImg
                      : `${baseUrl}/uploads/agency/logo/${img}`
                    : img === "null"
                    ? defaultImg
                    : `${baseUrl}/uploads/user/profile/${img}`
                }
                style={{ borderRadius: "50%", aspectRatio: "1 / 1" }}
                width={"100%"}
                alt=""
                srcSet=""
                onError={() => setIsImageError(true)}
              />
            )}
          </Box>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "600",
              fontSize: "18px",
              // border: "solid red",
            }}
          >
            {name}
          </Typography>{" "}
        </Box>
        <Box>
          <DeleteChatModal color={theme.palette.primary.main} setselectedChatId={setselectedChatId}/>
        </Box>
      </Box>
      <Divider />

      <ChattingUserMsgHandler themeMode={theme.palette.mode} />
    </Box>
  );
}
