import { useTheme } from "@mui/material/styles";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  BadSVG,
  BathSVG,
  CarSVG,
  ChatTextSVG,
  LocationSVG,
  NoData,
  // TVSVG,
} from "../../../common/Svg";
import { useParams, useNavigate } from "react-router-dom";
import apiHelper from "../../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { baseUrl } from "../../../common/Constant";
import { LanguageContext } from "../../../Context/LanguageContext";
import Loader from "../../../common/Loader";
import ScrollAnimation from "react-animate-on-scroll";
import Path from "../../../common/Path";
import ImageListModal from "./ImageListModal";
import defaultImg from "../../../common/assets/image/User.svg";

export default function PropertyDetailScreen({ verifyUserLogin }) {
  const { id } = useParams();
  const [isLoggedIn, setisLoggedIn] = useState();
  const theme = useTheme();
  const [propertyDetails, setPropertyDetails] = useState();
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const [CurrentUserInfo, setCurrentUserInfo] = useState();
  const navigate = useNavigate();
  // const userInfo = JSON.parse(localStorage.getItem("userInfo")) ?? {};
  const [trans, setTrans] = useState();
  const [newPrice, setnewPrice] = useState();
  const [reqLoading, setreqLoading] = useState(false);
  const [modalData, setmodalData] = useState([]);
  let userInfo;
  try
  {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error)
  {
    userInfo = null;
  }

  const fetchDetails = async () => {
    try
    {
      setLoading(true);
      const result = await apiHelper.getPropertyDetailsByID(id);
      setPropertyDetails(result?.data?.r);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const agencyList =
    propertyDetails && propertyDetails?.agency?.map((x) => x?.company_name);

  async function handlePropertyrequest() {
    if (!isLoggedIn)
    {
      return verifyUserLogin();
    } else
    {
      try
      {
        setreqLoading(true);
        const res = await apiHelper.SendPropertyRequest(id);
        res?.data?.s === 1 &&
          res?.data?.m === "Success" &&
          toast.success(
            language === "en"
              ? "Your request has been sent successfully!"
              : "تم ارسال طلبك بنجاح!"
          );
        setPropertyDetails((prevPropertyDetails) => ({
          ...prevPropertyDetails,
          is_i_requested: {
            ...prevPropertyDetails.is_i_requested,
            status: 1,
          },
        }));
        setreqLoading(true);
      } catch (error)
      {
        setreqLoading(false);
        console.log(error);
      }
    }
  }

  const handleTransportation = () => {
    const transportationOptions = {
      1: "Highway",
      2: "Bus",
      3: "Airport",
      4: "Port",
    };

    const data = propertyDetails?.near_by_transport?.split(",") || [];
    const newTrans = data?.map(
      (option) => transportationOptions[option] || transportationOptions.default
    );
    setTrans(newTrans);
  };

  useEffect(() => {
    if (propertyDetails)
    {
      handleTransportation();
    } //eslint-disable-next-line
  }, [propertyDetails]);

  // useEffect(() => {
  //   propertyDetails?.lat && GetAddressFromLng(propertyDetails?.lat, propertyDetails?.lng);
  // }, [propertyDetails]);

  useEffect(() => {
    setCurrentUserInfo(userInfo);

    if (
      !userInfo ||
      (userInfo.id === 0 &&
        userInfo.role === 4 &&
        userInfo.email === "guestuser@gmail.com")
    )
    {
      setisLoggedIn(false);
    } else
    {
      setisLoggedIn(true);
    } //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchDetails(); //eslint-disable-next-line
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setmodalData([
      ...(propertyDetails?.image ?? []),
      ...(propertyDetails?.video ?? []),
    ]);
  }, [propertyDetails]);

  const HandleChatNav = async (prop) => {
    const { data } = await apiHelper.GetChatUserId(prop.user_id);
    navigate(
      propertyDetails.user.role === 2
        ? `${Path.CHATBYID}?chatId=${data.r}&img=${prop?.user?.agency_logo
        }&name=${`${prop?.user?.company_name}`}&uId=${prop.user_id}&role=${prop.role
        }`
        : `${Path.CHATBYID}?chatId=${data.r}&img=${prop?.user?.profile
        }&name=${`${prop?.user?.f_name} ${prop?.user?.l_name}`}&uId=${prop.user_id
        }&role=${prop.role}`
    );
    // navigate(
    //   propertyDetails.user.role === 2
    //     ? `${Path.CHATBYID}/${data.r}/${
    //         prop?.user?.agency_logo
    //       }/${`${prop?.user?.company_name}`}/${prop.user_id}/${prop.role}`
    //     : `${Path.CHATBYID}/${data.r}/${
    //         prop?.user?.profile
    //       }/${`${prop?.user?.f_name} ${prop?.user?.l_name}`}/${prop.user_id}/${
    //         prop.role
    //       }`
    // );
  };

  const handlePropertyStatus = async () => {
    try
    {
      const res = await apiHelper.updatePropertyStatus(propertyDetails?.id);
      if (res?.data?.s === 1)
      {
        setPropertyDetails({ ...propertyDetails, status: 2 });
        toast.success("Property Status Updated Successfully");
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  function formatUSD(value) {
    if (value >= 1000000000)
    {
      return (value / 1000000000)?.toFixed(1) + "B";
    } else if (value >= 1000000)
    {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000)
    {
      return (value / 1000).toFixed(1) + "K";
    } else
    {
      return value.toFixed(1);
    }
  }

  useEffect(() => {
    if (propertyDetails)
    {
      const formattedPrice = formatUSD(propertyDetails?.price);
      setnewPrice(formattedPrice); //eslint-disable-next-line
    }
  }, [propertyDetails]);

  return (
    <Container
      maxWidth={"xxl"}
      sx={{
        position: "relative",
        top: "76px",
        minHeight: `calc(100vh - 76px )`,
        padding: "1.15rem 1rem",
        bgcolor: theme.palette.navbarBG.main,
        color: theme.palette.dark.main,
      }}
    >
      {loading ? (
        <Box
          sx={{
            minHeight: `calc( 100vh - 113px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : propertyDetails === undefined ? (
        <Box
          sx={{
            minHeight: `calc( 100vh - 115px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.dark.main,
          }}
        >
          <NoData />
        </Box>
      ) : (
        <>
          <ScrollAnimation animateIn="animate__fadeIn">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subHeading">
                <Box
                  sx={{
                    ml: "0.5rem",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textTransform: "capitalize",
                    mb: "8px",
                  }}
                >
                  {propertyDetails?.type_name}
                  <Button
                    sx={{
                      background: theme.palette.gold.main,
                      color: "#FFF",
                      mt: "0.5rem",
                      borderRadius: "12px",
                      textTransform: "none",
                      display:
                        propertyDetails?.user_id !== userInfo.id
                          ? "none"
                          : "block",
                    }}
                    disabled={propertyDetails?.status === 2 ? true : false}
                    variant="contained"
                    onClick={handlePropertyStatus}
                  >
                    {propertyDetails?.status === 1 &&
                      propertyDetails?.property_for === 1
                      ? "Mark as sold"
                      : propertyDetails?.status === 2 &&
                        propertyDetails?.property_for === 1
                        ? "Sold"
                        : propertyDetails?.status === 1 &&
                          propertyDetails?.property_for === 2
                          ? "Mark as a rented"
                          : propertyDetails?.status === 2 &&
                          propertyDetails?.property_for === 2 &&
                          "Rented"}
                  </Button>
                </Box>
              </Typography>

              <Typography
                variant="smallText"
                sx={{
                  display: "flex",
                  ml: "0.5rem",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <span>
                  <LocationSVG color={theme.palette.dark.main} />
                </span>
                &nbsp;
                <Box
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#00009d",
                    },
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.google.com/maps/search/" +
                      propertyDetails?.location
                    );
                    // window.open(
                    //   "https://maps.google.com?q=" +
                    //     propertyDetails?.lat +
                    //     "," +
                    //     propertyDetails?.lng
                    // );
                  }}
                >
                  {propertyDetails?.location}.{/* {address}. */}
                </Box>
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: "1rem" }}>
              <Grid container spacing={2.4}>
                <Grid item xs={12} sm={6}>
                  <img
                    src={
                      propertyDetails?.image[0]?.image === undefined
                        ? "/assets/image/property/detail/d.png"
                        : `${baseUrl}/uploads/property/image/${propertyDetails?.image[0]?.image}`
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "530px",
                      aspectRatio: "3/2",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                    alt=""
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2.4}>
                    <Grid item style={{ p: 0, m: 0 }} xs={3} sm={6} lg={6}>
                      <img
                        src={
                          propertyDetails?.image[0]?.image === undefined
                            ? "/assets/image/property/detail/d.png"
                            : `${baseUrl}/uploads/property/image/${propertyDetails?.image[1]?.image}`
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "253px",
                          aspectRatio: "3/2",
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                        alt=""
                      />
                    </Grid>
                    <Grid item style={{ p: 0, m: 0 }} xs={3} sm={6} lg={6}>
                      <img
                        src={
                          propertyDetails?.image[0]?.image === undefined
                            ? "/assets/image/property/detail/d.png"
                            : `${baseUrl}/uploads/property/image/${propertyDetails?.image[2]?.image}`
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "253px",
                          aspectRatio: "3/2",
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                        alt=""
                      />
                    </Grid>
                    <Grid item style={{ p: 0, m: 0 }} xs={3} sm={6} lg={6}>
                      <img
                        src={
                          propertyDetails?.image[0]?.image === undefined
                            ? "/assets/image/property/detail/d.png"
                            : `${baseUrl}/uploads/property/image/${propertyDetails?.image[3]?.image}`
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "253px",
                          aspectRatio: "3/2",
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                        alt=""
                      />
                    </Grid>

                    <Grid item xs={3} sm={6} lg={6}>
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            propertyDetails?.image[0]?.image === undefined
                              ? "/assets/image/property/detail/d.png"
                              : `${baseUrl}/uploads/property/image/${propertyDetails?.image[4]?.image}`
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: "253px",
                            aspectRatio: "3/2",
                            objectFit: "cover",
                            borderRadius: "12px",
                            filter:
                              propertyDetails?.image.length > 4 &&
                              "brightness(0.3)",
                          }}
                          alt=""
                        />
                        <ImageListModal
                          data={modalData}
                          number={Number(
                            propertyDetails?.video?.length +
                            propertyDetails?.image?.length -
                            4
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  m: "1rem 0px",
                }}
              >
                <Typography
                  variant="proHead"
                >
                  <span style={{ textTransform: "capitalize" }}>
                    Available&nbsp;
                  </span>
                  for&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {propertyDetails?.property_for === 1 ? "Buy" : "Rent"}
                  </span>
                </Typography>
                <Typography variant="proHead">
                  <span style={{ fontWeight: "600" }}>KWD {newPrice}</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <Box
                  sx={{
                    borderTop: `1px solid ${theme.palette.divider} `,
                    width: { xs: "100%", lg: "60%" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "row",
                        sm: "row",
                      },
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        width: {
                          xs: "48%",
                          sm: "32%",
                        },
                        borderRadius: "12px",
                        "&:hover": {
                          bgcolor: theme.palette.primary.main,
                        },
                        mt: "1rem",
                        padding: { xs: "0.5rem 2rem", sm: "0.5rem 5rem" },
                        textTransform: "capitalize",
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.light.main,
                        display: "flex",
                        justifyContent: { xs: "center", sm: "center" },
                        alignItems: "center",
                        cursor: "default",
                        pointerEvents: "none",
                      }}
                      variant="outlined"
                    >
                      <Box
                        sx={{
                          m: "0 0.4rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BadSVG color={theme.palette.light.main} />
                      </Box>
                      <span style={{ marginRight: "0.2rem" }}>
                        {propertyDetails?.no_bed}
                      </span>
                      <span>Bedroom</span>
                    </Button>

                    <Button
                      sx={{
                        width: {
                          xs: "48%",
                          sm: "32%",
                        },
                        borderRadius: "12px",
                        "&:hover": {
                          bgcolor: theme.palette.primary.main,
                        },
                        mt: "1rem",
                        padding: { xs: " 0.5rem 2rem", sm: "0.5rem 5rem" },
                        textTransform: "capitalize",
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.light.main,
                        display: "flex",
                        justifyContent: { xs: "center", sm: "center" },
                        alignItems: "center",
                        cursor: "default",
                        pointerEvents: "none",
                      }}
                      variant="outlined"
                    >
                      <Box
                        sx={{
                          m: "0 0.4rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BathSVG color={theme.palette.light.main} />
                      </Box>
                      <span style={{ marginRight: "0.2rem" }}>
                        {propertyDetails?.no_bath}
                      </span>
                      <span>Bath</span>
                    </Button>

                    <Button
                      sx={{
                        borderRadius: "12px",
                        width: {
                          xs: "48%",
                          sm: "32%",
                        },
                        "&:hover": {
                          bgcolor: theme.palette.primary.main,
                        },
                        mt: "1rem",
                        padding: { xs: " 0.5rem 2rem", sm: "0.5rem 5rem" },
                        textTransform: "capitalize",
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.light.main,
                        display: "flex",
                        justifyContent: { xs: "center", sm: "center" },
                        alignItems: "center",
                        cursor: "default",
                        pointerEvents: "none",
                      }}
                      variant="outlined"
                    >
                      <Box
                        sx={{
                          m: "0 0.4rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CarSVG color={theme.palette.light.main} />
                      </Box>
                      <span style={{ marginRight: "0.2rem" }}>
                        {propertyDetails?.no_parking}
                      </span>
                      <span>Parking</span>
                    </Button>
                  </Box>

                  <Box sx={{ mt: "1rem" }}>
                    <Typography>
                      <span style={{ fontWeight: "bolder" }}>
                        Building condition :
                      </span>{" "}
                      {propertyDetails?.building_condition === "1"
                        ? "Bad"
                        : propertyDetails?.building_condition === "2"
                          ? "Good"
                          : "Excellent"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: "0.1rem",
                      display:
                        propertyDetails?.major_road === "1" ? "block" : "none",
                    }}
                  >
                    <Typography>
                      <span style={{ fontWeight: "bolder" }}>
                        Major road access :
                      </span>{" "}
                      {propertyDetails?.major_road === "1" ? "Yes" : "No"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: "0.1rem",
                      display:
                        propertyDetails?.renovation === "1" ? "block" : "none",
                    }}
                  >
                    <Typography>
                      <span style={{ fontWeight: "bolder" }}>
                        Recently renovated :
                      </span>{" "}
                      {propertyDetails?.renovation === "1" ? "Yes" : "No"}
                    </Typography>
                  </Box>

                  <Box sx={{ mt: "0.1rem", display: "flex", gap: "0.5rem" }}>
                    <span style={{ fontWeight: "bolder" }}>
                      Near by transportation :
                    </span>
                    {trans?.join(", ")}
                  </Box>
                  <Box sx={{ mt: "0.1rem" }}>
                    <Typography>
                      <span style={{ fontWeight: "bolder" }}>
                        Description :
                      </span>{" "}
                      {propertyDetails?.description}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: "1rem",
                      width: { xs: "100%", md: "60%" },
                      boxSizing: "border-box",
                      display: propertyDetails?.role === 1 ? "flex" : "none",
                      gap: "1rem",
                      boxShadow: "1px 1px 1px 2px #afafaf",
                      borderRadius: "12px",
                      padding: "15px",
                    }}
                  >
                    <Box sx={{ height: "120px", width: "120px" }}>
                      {" "}
                      <img
                        src={
                          propertyDetails?.user?.profile === null
                            ? defaultImg
                            : `${baseUrl}/uploads/user/profile/${propertyDetails?.user?.profile}`
                        }
                        style={{ aspectRatio: "1/1", borderRadius: "12px" }}
                        width={"100%"}
                        height={"100%"}
                        alt=""
                        srcSet=""
                      />
                    </Box>
                    <Box
                      sx={{
                        alignItems: "start",
                        display: propertyDetails?.role === 1 ? "flex" : "none",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {/* {propertyDetails?.role === 1 && "Agent"} */}
                        {/* {propertyDetails?.role === 2 && "Agency"} */}
                        {/* {propertyDetails?.role === 4 && "Admin"} */}
                        {/* {propertyDetails?.role === 5 && "Admin"} */}
                        Contact Details
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bolder" }}> Name :</span>{" "}
                        {`${propertyDetails?.user.f_name} ${propertyDetails?.user.l_name}`}{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        <span style={{ fontWeight: "bolder" }}>E-mail : </span>
                        {`${propertyDetails?.user.email}`}{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        <span style={{ fontWeight: "bolder" }}>Number : </span>
                        {`${propertyDetails?.user.phone_no}`}{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        <span style={{ fontWeight: "bolder" }}>Agency : </span>
                        {agencyList && agencyList?.map((x) => x)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display:
                      CurrentUserInfo?.email === propertyDetails?.user?.email
                        ? "none"
                        : "flex",
                    justifyContent: {
                      xs: "center",
                      lg: "end",
                    },
                    paddingBottom: "1rem",
                    width: { xs: "100%", lg: "40%" },
                  }}
                >
                  <Box sx={{ p: { xs: "1rem 0" } }}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "12px",
                        mr: "1rem",
                        padding: "12px 15px",
                      }}
                      disabled={
                        propertyDetails?.is_i_requested?.request_status === 1
                          ? false
                          : true
                      }
                      onClick={() => HandleChatNav(propertyDetails)}
                    >
                      <ChatTextSVG color={theme.palette.light.main} />
                    </Button>
                    <Button
                      sx={{
                        background: theme.palette.gold.main,
                        color: "#FFF",
                        borderRadius: "12px",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      disabled={
                        reqLoading ||
                        CurrentUserInfo?.email ===
                        propertyDetails?.user?.email ||
                        propertyDetails?.is_i_requested?.status === 1 ||
                        propertyDetails?.is_i_requested?.status === 0
                      }
                      onClick={() => {
                        handlePropertyrequest();
                      }}
                    >
                      {propertyDetails?.is_i_requested?.status === 1 ||
                        propertyDetails?.is_i_requested?.status === 0
                        ? "Requested"
                        : "Request"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>
        </>
      )}
    </Container>
  );
}
