import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropertyCard from "../PropertyCard";
import apiHelper from "../../../common/API/ApiHelper";
import { NoData } from "../../../common/Svg";
import Loader from "../../../common/Loader";
import { useInView } from "react-intersection-observer";

let dataLength = 0;

export default function SellPropertyScreen() {
  const { ref, inView } = useInView();
  const [fetchProperty, SetFetchedProperty] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [loading, setLoading] = useState(true);


  const getPropertyList = async (count = 0) => {
    try
    {
      // setLoading(true);
      const result = await apiHelper.get_SELL_PropertyList(count);
      if (result?.data?.r.length > 0 && result.status === 200)
      {
        setMoreData(true);
        if (count !== 0)
        {
          SetFetchedProperty((prev) =>
            prev.length ? [...prev, ...result?.data?.r] : result?.data?.r
          );
        } else
        {
          SetFetchedProperty(result.data.r);
        }
        setLoading(false);
      } else
      {
        setLoading(false);
        setMoreData(false);
        return;
      }
    } catch (error)
    {
      error?.response?.data?.message
      ? console.log(error?.response?.data?.message)
      : console.log(error.message);

    }
  };;

  useEffect(() => {
    getPropertyList();
    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   AOS.init({
  //     duration: 500,
  //     easing: "ease-out-cubic",
  //   });
  // }, []);

  useEffect(() => {
    if (inView && fetchProperty.length && moreData)
    {
      //eslint-disable-next-line
      dataLength = fetchProperty.length;
      getPropertyList(dataLength);
    }
    //eslint-disable-next-line
  }, [inView, fetchProperty]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);
  return (
    <>
      {loading && fetchProperty.length === 0 ? (
        <Box
          sx={{
            minHeight: `calc( 100vh - 172px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : fetchProperty.length === 0 && !loading ? (
        <Box
          sx={{
            minHeight: `calc( 100vh - 172px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoData />
        </Box>
      ) : (
        // <Box sx={{ height: `calc( 100vh - 154px )`, overflow: "auto" }}>
        <Box sx={{ minHeight: `calc( 100vh - 154px )`, pb: "1rem" }}>
          <Grid container spacing={"1.5rem"}>
            {fetchProperty &&
              fetchProperty.map((x, i) => {
                return (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <PropertyCard x={x} />
                  </Grid>
                );
              })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              color:"grey",
              mt: "1rem",
            }}
            ref={ref}
          >
           {loading ? <CircularProgress color="error" /> : !moreData && "No more data."}
          </Box>
        </Box>
      )}
    </>
  );
}
