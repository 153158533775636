const Path = {
  HOME: "/",
  ABOUT: "/about",
  CONTACT: "/contact",
  HELP: "/help",
  SIGN_IN: "/register",
  LOGIN: "/login",
  GMAP: "/gmap",
  USER_PROFILE: "/profile",
  CHAT: "/chat",
  CHATBYID: "/chat/chats",
  PROPERTY: "/property",
  NOTIFICATION: "/notification",
  ADD_PROPERTY: "/property/add",
  PROPERTY_DETAILS: "/property/details",
  SELL_PROPERTY: "/property/sell",
  BUY_PROPERTY: "/property/buy",
  RENT_PROPERTY: "/property/rent",
  SELL_PROPERTY_HISTORY: "/property/history/sell",
  RENT_PROPERTY_HISTORY: "/property/history/rent",
  PROPERTY_HISTORY: "/property/history",
  PROPERTY_EVALUATION: "/property/evaluation",
  PROPERTY_EVALUATION_COMMERCIAL: "/property/evaluation/commercial",
  PROPERTY_EVALUATION_INDUSTRIAL: "/property/evaluation/industrial",
  PROPERTY_EVALUATION_AGRICULTURAL: "/property/evaluation/agricultural",
  PROPERTY_EVALUATION_COMMERCIAL__CALC: "/property/evaluation/commercial/calc",
  PROPERTY_EVALUATION_INDUSTRIAL__CALC: "/property/evaluation/industrial/calc",
  PROPERTY_EVALUATION_AGRICULTURAL__CALC: "/property/evaluation/agricultural/calc",
  PROPERTY_EVALUATION_RESIDENCE_CALC: "/property/evaluation/resident/calc",
  SEARCH_PROPERTY: "/property/search",
  REQUESTED_PROPERTY: "/user/requested/property",
  REQUESTE_TO_USERS_PROPERTY: "/user/get/property/request",
  AGENCY_NOT_FOUND: "/agency",
  APPROVAL: "/approval/pending",
  REJECT: "/approval/reject",
  DELETE: "/deleted",

};

export default Path;
