import * as React from "react";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Path from "../../../common/Path";



export default function CustomizedSwitches(props) {
  const { setThemeMode, themeMode, isScroled, location } = props;
  const handleThemeToggle = () => {
    const newThemeMode = themeMode === "dark" ? "light" : "dark";
    setThemeMode(newThemeMode);
    localStorage.setItem("themeMode", newThemeMode);
  };
  return themeMode === "dark" ? (
    <>
      <LightModeOutlinedIcon
        onClick={handleThemeToggle}
        sx={{ color: "#EEE", cursor: "pointer" }}
      />
      
    </>
  ) : (
    <DarkModeOutlinedIcon
      onClick={handleThemeToggle}
      sx={{
        color: isScroled ? "#1f2438" : location !== Path.HOME ? "#1f2438" : "#EEE",
        cursor: "pointer",
      }}
    />
  );
}
