import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";

export default function CommercialCalculation() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  return (
    <div>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Box sx={{ width: "300px" }}>
            <Typography
              sx={{
                bgcolor: theme.palette.gold.main,
                padding: "9px 22px",
                borderRadius: "12px",
              }}
            >
              <span
                style={{
                  display: "block",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {t("eval.calc.title")}
              </span>
              <span
                style={{
                  display: "block",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {t("eval.calc.detail")} : 483,000
              </span>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.pSize")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>200 SQ.M</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.location")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Melbourne, Australia</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.rInc")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>$20000</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.hor")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>3</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.pSpace")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>200 SQ.M</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.bc")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Good</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.zrp")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.eco")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Lorem</Box>
          </Box>
          <Divider />

          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.com.compi")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>High</Box>
          </Box>
          <Divider />
        </Box>
      </Box>
    </div>
  );
}
