import { useTheme } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AppleStore, GoogleStore } from "../../../common/Svg";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";
export default function AppSection() {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: `${theme.palette.bg.main}`,
        position: "relative",
      }}
    >
      <Container
        maxWidth={"xxl"}
        sx={{
          display: "flex",
          padding: { xs: "2rem 0", md: "3rem 0" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* main wrapper */}
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "60%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            zIndex: "1",
          }}
        >
          <Grid
            container
            spacing={4}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item xs={11} sm={6.1} md={6} xl={7} lg={6}>
              <ScrollAnimation
                animateIn="animate__fadeInBottomLeft"
                duration={1.5}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          color: theme.palette.primary.main,
                          fontSize: {
                            sm: "1rem",
                            md: "1.4rem",
                            lg: "2rem",
                            xl: "2.5rem",
                          },
                          mb: "1rem",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                      >
                        {t("landingPage.app.title")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "17px",
                          lineHeight: "22px",
                          color: theme.palette.paragraph.main,
                        }}
                      >
                        {t("landingPage.app.content")}
                      </Typography>
                    </Box>
                    {/* Mobile screen logo */}
                    <Box
                      sx={{
                        display: { sm: "block", md: "flex" },
                        mt: "1em",
                        position: "relative",
                        direction: language === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      <Box
                        sx={{
                          mr: "1em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <GoogleStore />
                        </span>
                      </Box>
                      <Box
                        sx={{
                          mr: "1em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <AppleStore />
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </ScrollAnimation>
            </Grid>
            <Grid item xs={11} sm={6.1} md={6} xl={3.5} lg={3}>
              <Box
                sx={{
                  display: "flex",
                  zIndex: "1",
                }}
              >
                <Box>
                  <ScrollAnimation
                    animateIn="animate__fadeInBottomRight"
                    duration={1.5}
                  >
                    {/* <MobileSVG /> */}
                    <img
                      src="/assets/image/home/device.webp"
                      alt=""
                      srcSet=""
                      width={"100%"}
                    />
                  </ScrollAnimation>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: { xs: "block", md: "none" },
              flexDirection: "column",
              alignSelf: "center",
              width: "70%",
              textAlign: "center",
              mb: "1rem",
            }}
          >
            <ScrollAnimation animateIn="animate__flipInX">
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: theme.palette.primary.main,
                  fontSize: "22px",
                }}
              >
                {t("landingPage.app.title")}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "end",
                  pt: "0.5rem",
                }}
              >
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <img
                    src="/assets/image/home/gplaystore.png"
                    width={120}
                    // height={40}
                    alt=""
                    srcSet=""
                  />
                </Box>
                <Box sx={{ mr: ".3em", display: "flex", cursor: "pointer" }}>
                  <img
                    src="/assets/image/home/appStore.png"
                    width={120}
                    // height={40}
                    alt=""
                    srcSet=""
                  />
                </Box>
              </Box>
            </ScrollAnimation>
          </Box>
        </Box>
      </Container>
      {/* {  BG  Image } */}
      <Box
        sx={{
          width: "100%",
          background:
            theme.palette.mode === "dark"
              ? 'url("/assets/image/home/cityD.png") no-repeat '
              : 'url("/assets/image/home/cityL.png") no-repeat ',
          // backgroundPosition: "90%",
          backgroundSize: "cover",
          height: "80%",
          position: "absolute",
          bottom: "0",
          zIndex: "0",
        }}
      ></Box>
    </Box>
  );
}
