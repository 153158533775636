import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import apiHelper from "../../common/API/ApiHelper";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext } from "../../Context/LanguageContext";

export default function ForgotPasswordDialogue() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [email, setemail] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (email === "") {
        return toast.error(
          language === "ar "
            ? "يرجى إدخال البريد الإلكتروني."
            : "Please enter email."
        );
      } else if (!/^[\w\\.-]+@[a-zA-Z\d\\.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        return toast.error(
          language === "ar "
            ? "يرجى إدخال بريد إلكتروني صالح."
            : "Please enter valid email."
        );
      } else {
        const res = await apiHelper.Forgot_password(email);
        if (res && res.data && res?.data?.s === 1) {
          toast.success(
            language === "en"
              ? "A password reset link has been successfully sent to your email."
              : "تم إرسال رابط إعادة تعيين كلمة المرور بنجاح إلى بريدك الإلكتروني."
          );
          handleClose();
        }
      }
      setemail("");
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <span variant="outlined" onClick={handleClickOpen}>
        {t("signin.login.forgot.mainTitle")}
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(5px)",
          color: theme.palette.dark.main,
        }}
        PaperProps={{
          sx: { borderRadius: 5 },
        }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.secondary.main,
            padding: "1rem",
            color: theme.palette.primary.main,
          }}
          component="form"
        >
          <DialogTitle>{t("signin.login.forgot.title")}</DialogTitle>
          <DialogContent sx={{ padding: "1rem 1.5rem 0.5rem 1.5rem " }}>
            <DialogContentText>
              {t("signin.login.forgot.content")}
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="email"
              placeholder={t("signin.login.forgot.email")}
              onChange={(e) => setemail(e.target.value)}
              type="email"
              fullWidth
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                "& .MuiOutlinedInput-input": {
                  padding: "14px",
                },
                ".MuiOutlinedInput-input": {
                  textAlign: language === "ar" ? "right" : "left",
                },
                width: "100%",
                borderRadius: "12px",
                input: { color: `#1f2438 !important` },
                bgcolor: theme.palette.input.main,
                paddingTop: "0.5rem",
              }}
            />
          </DialogContent>
          <DialogActions sx={{ pr: "1.5rem", gap: "1rem" }}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              sx={{
                color: "#FFF",
                width: "120px",
                fontSize: "1.1rem",
                textTransform: "capitalize",
                bgcolor: theme.palette.gold.main,
                "&:hover": {
                  backgroundColor: theme.palette.gold.main,
                },
                borderRadius: "12px",
              }}
              onClick={handleClose}
              type="reset"
            >
              {t("signin.login.forgot.cancle")}
            </Button>
            <Button
              variant="contained"
              size="large"
              fullWidth
              sx={{
                color: "#FFF",
                width: "120px",
                fontSize: "1.1rem",
                textTransform: "capitalize",
                bgcolor: theme.palette.gold.main,
                "&:hover": {
                  backgroundColor: theme.palette.gold.main,
                },
                "& .MuiFormControl-root ": {
                  paddingTop: "0",
                },
                borderRadius: "12px",
              }}
              // type="submit"
              onClick={handleSubmit}
            >
              {t("signin.login.forgot.button")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
