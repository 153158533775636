import React from "react";

export default function ChatBedge({ label, NewChatCount }) {
  return (
    <span
      style={{
        padding: "3px",
        display: label === "Chat" && NewChatCount ? "block" : "none",
        backgroundColor: "#8c1818",
        borderRadius: "50%",
        position: "absolute",
        bottom: "-5px",
        left: "45%",
        order: 1,
        zIndex: 11,
      }}
    ></span>
  );
}
