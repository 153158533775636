import { Footer_AR_json } from "../../component/Footer/FooterTrans";
import { Navabar_AR_json } from "../../component/Navbar/NavbarTrans";
import { About_AR_json } from "../../screen/About/AboutTrans";
import { AddProperty_AR_json } from "../../screen/AddProperty/AddPropertyTrans";
import { Chat_AR_json } from "../../screen/Chat/ChatTrans";
import { Contact_AR_json } from "../../screen/Contact/ContactTrans";
import { Gmap_AR_Trans } from "../../screen/GoogleMap/GMapTrans";
import { Help_AR_json } from "../../screen/Help/HelpTrans";
import { Home_AR_json } from "../../screen/Home/HomeTrans";
import { Notification_AR_json } from "../../screen/Notification/NotificationTrans";
import { Property_AR_json } from "../../screen/Property/PropertyTrans";
import { Evaluation_AR_json } from "../../screen/PropertyEvaluation/EvaluationTran";
import { Sign_AR_json } from "../../screen/SignIn/SignTrans";
import { Registration_AR_json } from "../../screen/SignIn/user/RegiTrans";
import { Validation_AR_json } from "../ValidationTrans";

export const Arabic = {
  translation: {
    landingPage: Home_AR_json,
    about: About_AR_json,
    contact: Contact_AR_json,
    help: Help_AR_json,
    footer: Footer_AR_json,
    navbar: Navabar_AR_json,
    property: Property_AR_json,
    signin: Sign_AR_json,
    addProperty: AddProperty_AR_json,
    validation: Validation_AR_json,
    eval: Evaluation_AR_json,
    regi:Registration_AR_json,
    chat:Chat_AR_json,
    notf:Notification_AR_json,
    map:Gmap_AR_Trans,

  }
};
