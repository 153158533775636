import React, { useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const Map = ({ lat = 0, lng = 0, onMapClick, setLatLng, setSelectedPlace }) => {
  const getLog = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position.coords.accuracy < 50) {
          setLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          setSelectedPlace({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        } else {
          setLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          setSelectedPlace({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
      },
      (error) => {
        console.error("Error getting geolocation:", error.message);
      },
      { enableHighAccuracy: true, timeout: 15000 }
    );
  };

  useEffect(() => {
    setLatLng && getLog(); //eslint-disable-next-line
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        m: "auto",
      }}
      center={{ lat: lat, lng: lng }}
      zoom={16}
      onClick={onMapClick}
    >
      {/* <Circle
      visible
        center={{ lat: lat, lng: lng }}
        radius={150}
        onCenterChanged={{ lat: lat, lng: lng }}
      /> */}
      <Marker
        position={{ lat: lat ?? 0, lng: lng ?? 0 }}
        onPositionChanged={{ lat: lat, lng: lng }}
      />
    </GoogleMap>
  );
};

export default Map;
