export const Sign_EN_json = {
    login: {
        title: "Lets log You in",
        // guestTitle: "Required!",
        content: " Welcome back, you’ve been missed!",
        // guestContent: "You need to login/register first to use this feature.",
        email: "Enter email",
        pass: "Enter password",
        button: "Login",
        or: "OR",
        account: "Don’t have an account ?",
        regi: "Sign Up",
        forgot: {
            mainTitle: "Forgot password ?",
            title: "Forgot password",
            content: "Lost your password? Please enter your email address. You will receive a link to create a new password via email.",
            email: "Email",
            button: "Submit",
            cancle: "Cancel"
        }
    }

};
export const Sign_AR_json = {
    login: {
        title: "دعنا نسجل دخولك",
        // guestTitle: "مطلوب!",
        content: "مرحبًا بعودتك، لقد افتقدناك!",
        // guestContent: "تحتاج إلى تسجيل الدخول/التسجيل أولاً لاستخدام هذه الميزة.",
        email: "أدخل البريد الإلكتروني",
        pass: "أدخل كلمة المرور",
        button: "تسجيل الدخول",
        or: "أو",
        account: "ليس لديك حساب؟",
        regi: "سجل الآن",
        forgot: {
            mainTitle: "هل نسيت كلمة المرور؟",
            title: "نسيت كلمة المرور",
            content: "فقدت كلمة المرور؟ يرجى إدخال عنوان بريدك الإلكتروني. ستتلقى رابطًا لإنشاء كلمة مرور جديدة عبر البريد الإلكتروني.",
            email: "البريد الإلكتروني",
            button: "إرسال",
            cancle: "إلغاء"
        }
    }
}


// export const Sign_AR_json = {

//     login: {
//         title: "لنقم بتسجيل دخولك",//n
//         content: "مرحباً بعودتك!",//n
//         email: "أدخل بريدك الالكتروني لإرسال تفاصيل إعادة تعيين حسابك",//n
//         pass: "كلمة السر",//n
//         button: "تسجيل الدخول",//n
//         or: "أو",//n
//         account: "ليس لديك حساب؟",//n
//         regi: "التسجيل",//n
//         forgot: {
//             mainTitle: "هل نسيت كلمة السر؟",//n
//             title: "هل نسيت كلمة السر",
//             content: "فقدت كلمة المرور الخاصة بك؟ الرجاء إدخال عنوان البريد الإلكتروني الخاص بك. سوف تتلقى رابطًا لإنشاء كلمة مرور جديدة عبر البريد الإلكتروني.",
//             email:  "البريد الالكتروني*",//n
//             button: "يُقدِّم",
//             cancle: "يلغي"
//         }
//     }
// };
