import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import upload from "../../common/assets/image/upload.webp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "85%", md: "60%", lg: "60%" },
  boxShadow: 24,
  borderRadius: "12px",
  p: 4,
  minHeight: `60%`,
  maxHeight: `calc(100vh - 100px)`,
  overflow: "auto",
  boxSizing: "border-box",
};

export default function MediaUploadModal({
  data,
  InputMode,
  handleFileChange,
  removeImage,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      handleFileChange({ target: { files: acceptedFiles } }, `${InputMode}`);
    },
    [handleFileChange, InputMode]
  );

  const { getRootProps, getInputProps, } = useDropzone({
    onDrop,
    accept: `${InputMode}/*`,
  });

  return (
    <div>
      <Button
        component="span"
        sx={{
          textTransform: "capitalize",
          bgcolor: "#1f2438",
          color: "white ",
          borderRadius: "7px",
          padding: "0.01em 1em",
          "&:hover": {
            backgroundColor: "#1f2438",
          },
        }}
        onClick={handleOpen}
      >
        {t("addProperty.photo.button")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backdropFilter: "blur(4px)",
          },
        }}
      >
        <Box
          sx={{
            ...style,
            bgcolor: theme.palette.navbarBG.main,
            color: theme.palette.dark.main,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: "1rem",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{ color: "#d32f2fc2", fontSize: "0.9rem" }}
              variant="h6"
            >
              {t("addProperty.photo.msg")}
            </Typography>

            <Button
              variant="contained"
              size="large"
              sx={{
                padding: "0.3em 1em",
                textTransform: "capitalize",
                bgcolor: theme.palette.gold.main,
                borderRadius: "12px",
                fontSize: "1.1rem",
                color: "white",
              }}
              onClick={handleClose}
            >
              {t("addProperty.photo.close")}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                {...getRootProps()}
                style={{
                  border: "1px dashed #80808054",
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                {" "}
                <Box
                  sx={{
                    width: "50%",
                    height: "100%",
                    display: { xs: "none", sm: "flex" },
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      justifyContent: "center",
                      height: "100%",
                      width: "50%",
                      position: "relative",
                    }}
                  >
                    <img
                      src={upload}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      alt=""
                    />
                  </Box>
                </Box>
                <input {...getInputProps({ accept: "image/*" })} />
              
                <AddOutlinedIcon sx={{ fontSize: "3rem", fill: "#80808054",display: {xs:"none",sm:"block"}, }} />
                <Box sx={{ width: "50%",  }}>
                  <Box
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      textAlign: "center",
                    }}
                  >
                    Drag or Select file
                  </Box>
                  <Box sx={{ opacity: "0.7", textAlign: "center" }}>
                    {" "}
                    Drop files here or click here...
                  </Box>
                </Box>
              </div>
            </Grid>
            {data &&
              Array.from(data).map((img, index) => (
                <Grid item key={index} xs={6} sm={4} md={3} lg={2}>
                  <div style={{ position: "relative" }}>
                    {InputMode === "image" ? (
                      <img
                        src={URL.createObjectURL(img)}
                        alt=""
                        height={150}
                        width={"100%"}
                        style={{ borderRadius: "12px" }}
                      />
                    ) : (
                      ""
                    )}
                    <span
                      style={{
                        position: "absolute",
                        right: "0.5rem",
                        top: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() => removeImage(index)}
                    >
                      <ClearIcon
                        sx={{
                          fontSize: "1rem",
                          color: "red",
                          fontWeight: "600",
                        }}
                      />
                    </span>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
