import { useTheme } from "@mui/material/styles";
import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";

export default function ResidentCalculation({ data }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  return (
    <div id="">
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Box sx={{ width: "300px" }}>
            <Typography
              sx={{
                bgcolor: theme.palette.gold.main,
                padding: "9px 22px",
                borderRadius: "12px",
              }}
            >
              <span
                style={{
                  display: "block",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {/* Total value of the properety */}
                {t("eval.calc.title")}
              </span>
              <span
                style={{
                  display: "block",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {t("eval.calc.detail")} : 483,000
              </span>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.opt")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>House</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.pSize")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>200 SQ.M</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.bed")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>3</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.wash")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>3</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.location")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Melbourne, Australia</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.age")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>3 Years</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.reno")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Yes</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.road")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Yes</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.bmc")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Yes</Box>
          </Box>
          <Divider />
          <Box
            sx={{
              color: theme.palette.paragraph.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "0.8rem",
            }}
          >
            <Box>{t("eval.calc.resi.surr")} :</Box>
            <Box sx={{ fontWeigth: "600" }}>Yes</Box>
          </Box>
          <Divider />
        </Box>
      </Box>
    </div>
  );
}
