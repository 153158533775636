export const TextTheme = {
  //main Heading or title
  mainHeading: {
    default: "1rem",
    xl: "3rem",
    lg: "2rem",
    md: "1.7rem",
    sm: "1.5rem",
    xs: "1.4rem",
  },

  // Sub Heading :
  subHeading: {
    default: "1rem",
    xl: "2rem",
    lg: "1.9rem",
    md: "1.8rem",
    sm: "1.7rem",
    xs: "1.5rem",
  },

  // button
  button: {
    default: "1.1rem",
    // xl: "1.1rem",
    // lg: "1.1rem",
    // md: "1rem",
    // sm: "0.8rem",
    // xs: "0.7rem",
  },

  //menu-Items
  menu: {
    default: "1rem",
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1rem",
    sm: "0.9rem",
    xs: "0.8rem",
  },

  text: {
    default: "1rem",
    xl: "1.2rem",
    xs: "1rem",
  },

  smallText: {
    xl: "0.01em",
    lg: "0.01em",
    md: "0.01em",
    sm: "0.01em",
    xs: "0.01em",
  },

  proHead:{
    xl: "2rem",
    lg: "1.7rem",
    md: "1.7rem",
    sm: "1.5rem",
    xs: "1.3rem",
  }


};
