import React from "react";
import { Box, useTheme } from "@mui/system";
import { baseUrl } from "../../common/Constant";
import { useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import ScrollAnimation from "react-animate-on-scroll";

export default function PropCard({ x }) {
  const theme = useTheme();
  const navigate = useNavigate();
  function formatUSD(value) {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toFixed(1);
    }
  }
  return (
    <ScrollAnimation animateIn="animate__fadeIn" duration={1.5}>
      <Box
        sx={{
          borderRadius: "12px",
          width: "100%",
          height: "280px",
          color: "#ffffff",
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
          padding: "1rem",
          boxSizing: "border-box",
          cursor: "pointer",
          fontSize: "14px",
          "&:hover": {
            outline: `2px solid ${theme.palette.gold.main}`,
          },
          background: x?.image[0]?.image
            ? `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 30.47%, #000000 100%), url(${baseUrl}/uploads/property/image/${x?.image[0]?.image}) center top / cover no-repeat`
            : `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 30.47%, #000000 100%), url(/assets/image/property/detail/d.png) center top / cover no-repeat`,
        }}
        onClick={() => navigate(`${Path.PROPERTY_DETAILS}/${x.id}`)}
      >
        <Box width={"75%"}>
          {x.location.length > 60
            ? `${x.location.slice(0, 60)}...`
            : x.location}
        </Box>

        <Box width={"100px"} sx={{ textAlign: "end" }}>
          KWD {formatUSD(x.price)}
        </Box>
      </Box>
    </ScrollAnimation>
  );
}
