import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import formValidator from "../../../common/Validator";
import { useTheme } from "@mui/material/styles";
import InputComponent from "../../../component/Input/InputComponentTextField";
import InputComponentOutline from "../../../component/Input/InputComponentOutline";
import { useLocation, useNavigate } from "react-router-dom";
import apiHelper from "../../../common/API/ApiHelper";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { LanguageContext } from "../../../Context/LanguageContext";
import { useTranslation } from "react-i18next";
import ErrorMsgComponent from "../../../component/Input/ErrorMsgComponent";
import Path from "../../../common/Path";

export default function AgencyFormComponent({ googleLoginData }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [validationError, setValidationError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPasword, setShowConfirmPasword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPasword = () =>
    setShowConfirmPasword((show) => !show);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isGoogleLogin, setIsGoogleLogin] = useState();
  const [phoneValidationError, setphoneValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initial = {
    role: 2,
    companyName: "",
    email: "",
    phoneNumber: "",
    companyWebsite: "",
    companyAddress: "",
    electronicNumber: "",
    confirmPasword: "",
    description: "",
    commercialLicense: {},
    agencyLicense: {},
    logo: {},
  };

  const [formDetails, setFormDetails] = useState(initial);

  const handleFileChange = (event, property) => {
    const selectedFile = event.target.files[0];
    setFormDetails({ ...formDetails, [property]: selectedFile });
  };

  const handleFormSubmittedDetails = async () => {
    try {
      setIsSubmitted(true);
      const validationResult = formValidator(
        formDetails,
        "agency",
        isGoogleLogin,
        t
      );
      if (validationResult.length > 0) {
        setValidationError(validationResult);
        return;
      } else if (formDetails.phoneNumber === "") {
        setphoneValidationError(
          language === "en"
            ? "Required field number is empty"
            : "رقم الحقل المطلوب فارغ"
        );
        return;
      } else {
        setIsLoading(true);
        setphoneValidationError("");
        setValidationError([]);
        const result = await apiHelper.RegisterAgency(
          formDetails,
          isGoogleLogin
        );
        if (result?.data?.r) {
          const token = result?.data?.r?.token;
          const userInfo = result?.data?.r;
          const status = result?.data?.r?.status;
          localStorage.setItem("token", token);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          result.data?.s === 1
            ? toast.success(
                language === "en" ? "Registered Successfully" : "مسجل بنجاح"
              )
            : toast.info(result.data?.m);
          setFormDetails(initial);
          setIsLoading(false);
          status === 2 ? navigate(Path.APPROVAL) : navigate(Path.PROPERTY);
        } else {
          setIsLoading(false);
          toast.warn(result?.data?.m);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const handlePhoneChange = (phone, cc) => {
    if (phone) {
      setFormDetails({ ...formDetails, phoneNumber: phone, country_code: cc });
    } else {
      setphoneValidationError(
        language === "en" ? "Please enter valid number" : "رقم غير صالح"
      );
      return;
    }
  };

  const handleFormDetailsChanges = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "agency",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const nav = location.search.split("?google=")[1];
    if (nav === "1") {
      setIsGoogleLogin(true);
      setFormDetails({
        ...formDetails,
        email: googleLoginData?.email,
        firstName: googleLoginData?.f_name,
        lastName: googleLoginData?.l_name,
      });
    } //eslint-disable-next-line
  }, [googleLoginData]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  return (
    <Box
      sx={{
        borderRadius: "12px",
        height: `calc(100vh - 280px )`,
        overflowY: "scroll",
        padding: { xs: "0", sm: "1.5rem", md: "1.5em 4em" },
        boxShadow: { xs: "", sm: " 4px 4px 13px 0px rgba(0, 0, 0, 0.15)" },
        bgcolor: { xs: "transparent", sm: theme.palette.bg.main },
        color: theme.palette.dark.main,
        width: { xs: "95%", md: "85%" },
        display: {
          xs: "block",
          md: "flex",
        },
        flexDirection: {
          xs: "row",
          md: "column",
        },
      }}
    >
      <Box>
        <h4 style={{ marginBottom: "0px", marginTop: "0px" }}>
          {" "}
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "600",
              fontSize: "1.5em",
            }}
          >
            {t("regi.agency.tagline")}
          </Typography>
        </h4>
        <Typography
          sx={{ color: theme.palette.paragraph.main, fontSize: "0.83em" }}
        >
          {t("regi.tagline")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            md: "flex",
          },
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agency.cName")}
            </Typography>
            <InputComponent
              type="text"
              name="companyName"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "companyName" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "companyName")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              id="companyName"
              value={formDetails.companyName}
              placeholder={t("regi.agency.cNamePlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.email")}
            </Typography>
            <InputComponent
              type="email"
              id="email"
              name="email"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "email" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "email")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              value={formDetails.email}
              className="form-control"
              placeholder={t("regi.emailPlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.phone")}
            </Typography>

            <PhoneInput
              country={"kw"}
              value={formDetails.phoneNumber}
              onChange={(number, cc) => {
                handlePhoneChange(number, cc.dialCode);
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
                autoComplete: "off",
              }}
              fullWidth={true}
              inputStyle={{
                width: "100%",
                height: "50px",
                borderRadius: "12px",
                border: "none",
                textAlign: language === "ar" ? "right" : "left",
                padding: "0 4rem",
              }}
              style={{
                color: "#1f2438",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
              containerClass="react-tel-input"
            />
            {
              <div
                style={{
                  direction: language === "ar" ? "rtl" : "ltr",
                  textAlign: language === "ar" ? "right" : "left",
                  color: "#BE3144",
                  fontSize: "0.8rem",
                  pl: "0.5rem",
                }}
              >
                {phoneValidationError}
              </div>
            }
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agency.website")}
            </Typography>
            <InputComponent
              value={formDetails.companyWebsite}
              id="companyWebsite"
              type="text"
              name="companyWebsite"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "companyWebsite" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "companyWebsite")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              className="form-control"
              placeholder={t("regi.agency.websitePlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agency.add")}
            </Typography>
            <InputComponent
              type="text"
              name="companyAddress"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "companyAddress" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "companyAddress")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              value={formDetails.companyAddress}
              id="companyAddress"
              className="form-control"
              placeholder={t("regi.agency.addPlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agency.eNum")}
            </Typography>
            <InputComponent
              type="text"
              name="electronicNumber"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "electronicNumber" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "electronicNumber")
                  ?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              value={formDetails.electronicNumber}
              id="electronicNumber"
              className="form-control"
              placeholder={t("regi.agency.eNumPlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agency.cLi")}
            </Typography>
            <InputComponentOutline
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              placeholder={t("regi.agency.cLiPlace")}
              value={formDetails?.commercialLicense?.name}
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "commercialLicense" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "commercialLicense")
                  ?.message
              }
              id="commercialLicense"
              readOnly
              endAdornment={
                <InputAdornment position="end">
                  <input
                    type="file"
                    id="CommercialLicense"
                    hidden
                    onChange={(e) => handleFileChange(e, "commercialLicense")}
                  />
                  <label htmlFor="CommercialLicense">
                    <Button
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        bgcolor: "#1f2438",
                        color: "white",
                        borderRadius: "7px",
                        padding: "0em 1em",
                        "&:hover": {
                          backgroundColor: "#1f2438",
                        },
                      }}
                    >
                      {t("regi.add")}
                    </Button>
                  </label>
                </InputAdornment>
              }
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agency.aLi")}
            </Typography>
            <InputComponentOutline
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              placeholder={t("regi.agency.aLiPlace")}
              id="agencyLicense"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "agencyLicense" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "agencyLicense")?.message
              }
              value={formDetails?.agencyLicense?.name}
              readOnly
              endAdornment={
                <InputAdornment position="end">
                  <input
                    type="file"
                    id="agencyLicense1"
                    hidden
                    onChange={(e) => handleFileChange(e, "agencyLicense")}
                  />
                  <label htmlFor="agencyLicense1">
                    <Button
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        bgcolor: "#1f2438",
                        color: "white",
                        borderRadius: "7px",
                        padding: "0em 1em",
                        "&:hover": {
                          backgroundColor: "#1f2438",
                        },
                      }}
                    >
                      {t("regi.add")}
                    </Button>
                  </label>
                </InputAdornment>
              }
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.agency.aLogo")}
            </Typography>
            <InputComponentOutline
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              placeholder={t("regi.agency.aLogoPlace")}
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "logo" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "logo")?.message
              }
              value={formDetails?.logo?.name}
              id="logo"
              readOnly
              endAdornment={
                <InputAdornment position="end">
                  <input
                    type="file"
                    id="fileInputlogo"
                    hidden
                    onChange={(e) => handleFileChange(e, "logo")}
                  />
                  <label htmlFor="fileInputlogo">
                    <Button
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        bgcolor: "#1f2438",
                        color: "white ",
                        borderRadius: "7px",
                        padding: "0em 1em",
                        "&:hover": {
                          backgroundColor: "#1f2438",
                        },
                      }}
                    >
                      {t("regi.add")}
                    </Button>
                  </label>
                </InputAdornment>
              }
            />
          </Box>

          <Box sx={{ mb: "0.8rem", display: isGoogleLogin ? "none" : "block" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.pass")}
            </Typography>
            <InputComponentOutline
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              id="password"
              name="password"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "password" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "password")?.message
              }
              type={showPassword ? "text" : "password"}
              value={formDetails.password}
              placeholder={t("regi.passPlace")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: "#1f2438" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>

          <Box sx={{ mb: "0.8rem", display: isGoogleLogin ? "none" : "block" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.cPass")}
            </Typography>
            <Box sx={{ display: "flex", position: "relative" }}>
              <InputComponentOutline
                sx={{
                  bgcolor: "white",
                  width: "100%",
                  borderRadius: "12px",
                }}
                id="confirmPasword"
                name="confirmPasword"
                onChange={handleFormDetailsChanges}
                isError={validationError?.find((x) => {
                  return x.key === "confirmPasword" ? true : false;
                })}
                helperText={
                  validationError.find((x) => x.key === "confirmPasword")
                    ?.message
                }
                value={formDetails.confirmPasword}
                type={showConfirmPasword ? "text" : "password"}
                placeholder={t("regi.cPassPlace")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: "#1f2438" }}
                      aria-label="toggle confirmPasword visibility"
                      onClick={handleClickShowConfirmPasword}
                      onMouseDown={handleClickShowConfirmPasword}
                      edge="end"
                    >
                      {showConfirmPasword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.des")}
            </Typography>

            <textarea
              placeholder={t("regi.desPlace")}
              value={formDetails.description}
              name="description"
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                borderRadius: "12px",
                padding: "17px 14px",
                border: "1px solid #ced4da",
                outline: "none",
                width: "100%",
                maxWidth: "100%",
                boxSizing: "border-box",
                direction: language === "ar" ? "rtl" : "ltr",
                textAlign: language === "ar" ? "right" : "left",
              }}
              onChange={handleFormDetailsChanges}
              id="description"
              rows={1} // Setting rows to 1 for a single line text area
              cols={formDetails.description.length} // Dynamically adjusting cols based on content length
            ></textarea>
            <ErrorMsgComponent
              id={"description"}
              validationError={validationError}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: "1em" }}>
        <Button
          variant="contained"
          size="large"
          sx={{
            padding: "0.3em 5em",
            textTransform: "capitalize",
            bgcolor: theme.palette.gold.main,
            borderRadius: "12px",
            fontSize: "1.1rem",
            color: "white",
          }}
          onClick={handleFormSubmittedDetails}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              {" "}
              <CircularProgress
                disableShrink
                sx={{ color: "#1f2438", margin: "0.2rem" }}
                size={20}
              />
            </>
          ) : (
            `${t("regi.button")}`
          )}
        </Button>
      </Box>
    </Box>
  );
}
