import { useTheme } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../Context/LanguageContext";
import LocationSearchField from "../../component/GoogleMap/LocationSearchField";
import Map from "../../component/GoogleMap/Map";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function GMapComponent() {
  const theme = useTheme();
  const { t } = useTranslation();

  const { language, mapModal, handleClose_MapModal, setGLatLng } =
    useContext(LanguageContext);

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [address, setAddress] = useState("");
  const [latlng, setLatLng] = useState({
    lat: "",
    lng: "",
  });

  const [selectedPlace, setSelectedPlace] = useState({
    lat: "",
    lng: "",
  });

  const GetAddressFromLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDQA_QHDdWzhTzvutj_tV6IVGoyIu1PXBM`
      );
      const data = await response.json();
      if (data?.status === "OK") {
        const add = data?.results[0]?.formatted_address;
        setAddress(add);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleConfirmLocation = () => {
    if (address !== "") {
      setGLatLng(latlng);
      handleClose_MapModal();
    } else {
      toast.error("please enter location.");
    }
  };

  const handleAddress = (address) => {
    setAddress(address);
  };

  const updateLatLng = (latLngs) => {
    setLatLng({ ...latlng, lat: latLngs.lat, lng: latLngs.lng });
  };

  const onMapClick = (e) => {
    setLatLng({ ...latlng, lat: e.latLng.lat(), lng: e.latLng.lng() });
    setSelectedPlace({
      ...selectedPlace,
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      setScriptLoaded(true);
    }
  }, [scriptLoaded]);

  useEffect(() => {
    selectedPlace.lat !== "" &&
      GetAddressFromLng(selectedPlace.lat, selectedPlace.lng);
  }, [selectedPlace]);

  return (
    <>
      {scriptLoaded && (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            bgcolor: theme.palette.navbarBG.main,
            boxSizing: "border-box",
            display: mapModal ? "block" : "none",
            zIndex: "99",
            mt: "95px",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
              justifyContent: { xs: "start", sm: "center" },
              // flexDirection: "column",
              boxSizing: "border-box",
              borderTop: "1px solid #1f243850",
              // border: "solid red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent:{ xs: "start", sm: "center" },
                alignItems: "center",
                boxSizing: "border-box",
                width: "100%",
                padding: "0 0.5rem",
              }}
            >
              <LocationSearchField
                address={address && address}
                handleAddress={handleAddress}
                updateLatLng={updateLatLng}
                width={"340px"}
                textColor={"#1f2438"}
                color={theme.palette.input.main}
              />
            </Box>
          </Box>

          <Button
            variant="outlined"
            sx={{
              position: "absolute",
              right: { xs: "0.5rem", md: "2rem" },
              top: { xs: "0.4rem", md: "2rem" },
              padding: "0.6rem ",
              textTransform: "capitalize",
              bgcolor: theme.palette.gold.main,
              color: "#fff",
              fontSize: "1rem",
              borderRadius: "9px",
              display: { xs: "block", md: "none" },
              "&:hover": {
                bgcolor: theme.palette.gold.main,
              },
            }}
            onClick={() => handleClose_MapModal()}
          >
            {t("map.close")}
          </Button>

          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              height: {
                xs: `calc(100vh - 180px )`,
                md: `calc(100vh - 120px )`,
              },
              borderRadius: "12px",
              justifyContent: "center",
            }}
          >
            {/* laptop screen  */}
            <Box
              sx={{
                width: "350px",
                boxShadow: "1px 1px 3px 0px #1f243850",
                borderRadius: "12px",
                padding: "1rem",
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocationSearchField
                  address={address && address}
                  handleAddress={handleAddress}
                  updateLatLng={updateLatLng}
                  width={"333px"}
                  textColor={"#1f2438"}
                  color={theme.palette.input.main}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <input
                  id="place"
                  name="place"
                  type="text"
                  readOnly
                  placeholder={`${t("map.selectedLoc")}`}
                  value={address && address}
                  className="PlaceInput"
                  style={{
                    background: theme.palette.input.main,
                    width: "300px",
                    padding: "14px",
                    borderRadius: "12px",
                    border: "none",
                    textAlign: language === "ar" ? "right" : "left",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "23px",
                    outline: "none",
                    paddingRight: "5rem",
                    boxSizing: "border-box",
                  }}
                />
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    position: "absolute",
                    right: "0.5rem",
                    padding: "0.3rem ",
                    textTransform: "capitalize",
                    bgcolor: theme.palette.gold.main,
                    color: "#fff",
                    fontSize: "0.7rem",
                    borderRadius: "7px",
                    "&:hover": {
                      bgcolor: theme.palette.gold.main,
                    },
                  }}
                  onClick={handleConfirmLocation}
                >
                  {t("map.confirm")}
                </Button>
              </Box>
            </Box>

            {/* map  */}
            <Box
              sx={{
                width: { xs: "100%", md: `calc( 100% - 400px )` },
                boxShadow: { xs: "0", md: "1px 1px 3px 0px #1f243850" },
                borderRadius: "12px",
                padding: { xs: "0", md: "1rem" },
                display: "flex",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Map
                onMapClick={onMapClick}
                lat={latlng.lat ?? 0}
                lng={latlng.lng ?? 0}
                setLatLng={setLatLng}
                setSelectedPlace={setSelectedPlace}
              />
              <Button
                variant="outlined"
                sx={{
                  position: "absolute",
                  right: "2rem",
                  top: "2rem",
                  padding: "0.3rem ",
                  textTransform: "capitalize",
                  bgcolor: theme.palette.gold.main,
                  color: "#fff",
                  fontSize: "1rem",
                  borderRadius: "9px",
                  display: { xs: "none", md: "block" },
                  "&:hover": {
                    bgcolor: theme.palette.gold.main,
                  },
                }}
                onClick={() => handleClose_MapModal()}
              >
                {t("map.close")}
              </Button>
            </Box>
          </Box>

          {/* mobile screen modal close btnn */}

          <Box
            sx={{
              position: "absolute",
              bottom: "2.5rem",
              left: "1rem",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                gap: "1rem",
                background: "#00000045",
                boxSizing: "border-box",
                borderRadius: "12px",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  padding: "0.3rem 2rem",
                  textTransform: "capitalize",
                  bgcolor: theme.palette.gold.main,
                  color: "#fff",
                  fontSize: "18px",
                  borderRadius: "7px",
                }}
                onClick={handleConfirmLocation}
              >
                {t("map.confirm")}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  padding: "0.3rem 2rem",
                  textTransform: "capitalize",
                  bgcolor: theme.palette.gold.main,
                  color: "#fff",
                  fontSize: "18px",
                  borderRadius: "7px",
                  zIndex: "999",
                }}
                onClick={() => handleClose_MapModal()}
              >
                {t("map.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
