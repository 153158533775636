import { Box, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import formValidator from "../../../common/Validator";
import { useTheme } from "@mui/material/styles";
import InputComponent from "../../../component/Input/InputComponentTextField";
import InputComponentOutline from "../../../component/Input/InputComponentOutline";
import apiHelper from "../../../common/API/ApiHelper";
import { useLocation, useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import { getFirebaseToken } from "../../../common/Firebase";

export default function CustomerFormComponent({
  googleLoginData,
  SetIsLoginGuest,
  setuserInfo,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [validationError, setValidationError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPasword, setShowconfirmPasword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const theme = useTheme();
  const location = useLocation();
  const [isGoogleLogin, setIsGoogleLogin] = useState();
  const [phoneValidationError, setphoneValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPasswordConfPass = () =>
    setShowconfirmPasword((show) => !show);
  const navigate = useNavigate();

  window.scrollTo({ behavior: "instant", top: "0" });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function generateDeviceId(username, deviceInfo) {
    const combinedData = `${username}-${deviceInfo}`;

    const dataBuffer = new TextEncoder().encode(combinedData);

    try {
      const hashBuffer = await window.crypto.subtle.digest(
        "SHA-256",
        dataBuffer
      );

      // Convert the hash buffer to a Base64 string
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(hashBuffer))
      );

      return base64String;
    } catch (error) {
      console.error("Error generating device ID:", error);
      throw error; // Rethrow the error
    }
  }

  const initial = {
    firstName: "",
    lastName: "",
    email: "",
    confirmPasword: "",
    phoneNumber: "",
  };

  const [formDetails, setFormDetails] = useState(initial);

  const HandleFormSubmittedDetails = async () => {
    try {
      setIsSubmitted(true);
      const validationResult = formValidator(
        formDetails,
        "customer",
        isGoogleLogin,
        t
      );
      if (validationResult.length > 0) {
        setValidationError(validationResult);
        return;
      } else if (formDetails.phoneNumber === "") {
        setphoneValidationError(
          language === "en"
            ? "Required field number is empty"
            : "رقم الحقل المطلوب فارغ"
        );
        return;
      } else {
        setIsLoading(true);
        setphoneValidationError("");
        setValidationError([]);
        const result = await apiHelper.RegisterCustomer({
          role: 3,
          login_type: isGoogleLogin ? 1 : 2,
          f_name: formDetails.firstName,
          l_name: formDetails.lastName,
          email: formDetails.email,
          phone_no: formDetails.phoneNumber,
          password: isGoogleLogin ? null : formDetails.password,
        });
        if (result?.data?.r && result.data?.s === 1) {
          const token = result?.data?.r?.token;
          const userInfo = result?.data?.r;
          localStorage.setItem("token", token);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          const deviceId = await generateDeviceId(
            result?.data?.r?.id,
            result?.data?.r?.created_at,
          );
          localStorage.setItem("deviceID", deviceId);
          await getFirebaseToken("add");

          toast.success(
            language === "en" ? "Registered Successfully" : "مسجل بنجاح"
          );

          setuserInfo(result?.data?.r);
          if (result?.data?.r?.token) {
            navigate(Path.PROPERTY);
            SetIsLoginGuest(false);

            setFormDetails(initial);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.warn(result?.data?.m);
        }
      }
    } catch (error) {
      setIsLoading(false);
      error?.response?.data?.message
        ? console.log(error?.response?.data?.message)
        : console.log(error.message);
    }
  };

  const handlePhoneChange = (phone, cc) => {
    if (phone) {
      setFormDetails({ ...formDetails, phoneNumber: phone, country_code: cc });
    } else {
      setphoneValidationError(
        language === "en" ? "Please enter valid number" : "رقم غير صالح"
      );
      return;
    }
  };

  const handleFormDetailsChanges = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "customer",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  useEffect(() => {
    const nav = location.search.split("?google=")[1];
    if (nav === "1") {
      setIsGoogleLogin(true);
      setFormDetails({
        ...formDetails,
        email: googleLoginData?.email,
        firstName: googleLoginData?.f_name,
        lastName: googleLoginData?.l_name,
      });
    } //eslint-disable-next-line
  }, [googleLoginData]);

  return (
    <Box
      sx={{
        borderRadius: "12px",
        padding: { xs: "0", sm: "1.5rem", md: "1.5em 4em" },
        boxShadow: { xs: "", sm: " 4px 4px 13px 0px rgba(0, 0, 0, 0.15)" },
        bgcolor: { xs: "transparent", sm: theme.palette.bg.main },
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        height: `calc(100vh - 280px )`,
        width: { xs: "95%", md: "85%" },
        color: theme.palette.dark.main,
      }}
      className={"HideScrollBar"}
    >
      <Box>
        <h4 style={{ marginBottom: "0px", marginTop: "0px" }}>
          {" "}
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "600",
              fontSize: "1.5em",
            }}
          >
            {t("regi.customer.tagline")}
          </Typography>
        </h4>
        <Typography
          sx={{ color: theme.palette.paragraph.main, fontSize: "0.83em" }}
        >
          {t("regi.tagline")}
        </Typography>
      </Box>
      <Box
        sx={{
          color: theme.palette.dark.main,
          display: {
            xs: "block",
            md: "flex",
          },
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.fName")}
            </Typography>
            <InputComponent
              type="text"
              name="firstName"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "firstName" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "firstName")?.message
              }
              sx={{
                bgcolor: "white",
                width: "100%",
                borderRadius: "12px",
                color: theme.palette.bg.main,
              }}
              id="firstName"
              value={formDetails.firstName}
              placeholder={t("regi.fNamePlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.lName")}
            </Typography>
            <InputComponent
              value={formDetails.lastName}
              id="lastName"
              type="text"
              name="lastName"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "lastName" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "lastName")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              className="form-control"
              placeholder={t("regi.lNamePlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>

          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.email")}
            </Typography>
            <InputComponent
              type="email"
              id="email"
              name="email"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "email" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "email")?.message
              }
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              value={formDetails.email}
              className="form-control"
              placeholder={t("regi.emailPlace")}
              style={{ color: "#1f2438" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          <Box sx={{ mb: "0.8rem" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.phone")}
            </Typography>

            <PhoneInput
              country={"kw"}
              value={formDetails.phoneNumber}
              onChange={(number, cc) => {
                handlePhoneChange(number, cc.dialCode);
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
                autoComplete: "off",
              }}
              fullWidth={true}
              inputStyle={{
                width: "100%",
                height: "50px",
                borderRadius: "12px",
                border: "none",
                textAlign: language === "ar" ? "right" : "left",
                padding: "0 4rem",
              }}
              style={{
                color: "#1f2438",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
              containerClass="react-tel-input"
            />
            {
              <div
                style={{
                  direction: language === "ar" ? "rtl" : "ltr",
                  textAlign: language === "ar" ? "right" : "left",
                  color: "#BE3144",
                  fontSize: "0.8rem",
                  pl: "0.5rem",
                }}
              >
                {phoneValidationError}
              </div>
            }
          </Box>
          <Box sx={{ mb: "0.8rem", display: isGoogleLogin ? "none" : "block" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.pass")}
            </Typography>
            <InputComponentOutline
              sx={{ bgcolor: "white", width: "100%", borderRadius: "12px" }}
              id="password"
              name="password"
              onChange={handleFormDetailsChanges}
              isError={validationError?.find((x) => {
                return x.key === "password" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "password")?.message
              }
              type={showPassword ? "text" : "password"}
              value={formDetails.password}
              placeholder={t("regi.passPlace")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: "#1f2438" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>

          <Box sx={{ mb: "0.8rem", display: isGoogleLogin ? "none" : "block" }}>
            <Typography
              sx={{
                fontSize: "0.83em",
                m: "3px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              {t("regi.cPass")}
            </Typography>
            <Box sx={{ display: "flex", position: "relative" }}>
              <InputComponentOutline
                sx={{
                  bgcolor: "white",
                  width: "100%",
                  borderRadius: "14px",
                }}
                id="confirmPasword"
                name="confirmPasword"
                onChange={handleFormDetailsChanges}
                isError={validationError?.find((x) => {
                  return x.key === "confirmPasword" ? true : false;
                })}
                helperText={
                  validationError.find((x) => x.key === "confirmPasword")
                    ?.message
                }
                value={formDetails.confirmPasword}
                type={showconfirmPasword ? "text" : "password"}
                placeholder={t("regi.cPassPlace")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirmPasword visibility"
                      onClick={handleClickShowPasswordConfPass}
                      onMouseDown={handleClickShowPasswordConfPass}
                      edge="end"
                      sx={{ color: "#1f2438" }}
                    >
                      {showconfirmPasword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: "1em" }}>
        <Button
          variant="contained"
          size="large"
          sx={{
            padding: "0.3em 5em",
            textTransform: "capitalize",
            bgcolor: theme.palette.gold.main,
            borderRadius: "12px",
            fontSize: "1.1rem",
            color: "white",
          }}
          onClick={HandleFormSubmittedDetails}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              {" "}
              <CircularProgress
                disableShrink
                sx={{ color: "#1f2438", margin: "0.2rem" }}
                size={20}
              />
            </>
          ) : (
            `${t("regi.button")}`
          )}
        </Button>
      </Box>
    </Box>
  );
}
