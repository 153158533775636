import * as React from "react";
import { Box } from "@mui/material";
import { LanguageContext } from "../Context/LanguageContext";
import Lottie from "lottie-react";
import loder from "../common/LF/loader.json";

export default function Loader() {
  const { language } = React.useContext(LanguageContext);
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        direction: language === "ar" ? "rtl" : "ltr",
      }}
    >
      <Lottie
        animationData={loder}
        style={{ height: "155x", width: "100px", color: 'red' }}
        loop={true}


      />
      <span
        style={{
          color: "grey",
        }}
      >
        {language === "ar" ? "تحميل..." : "Loading..."}
      </span>
    </Box>
  );
}
