import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import formValidator from "../../common/Validator";
import InputComponent from "../../component/Input/InputComponentTextField";
import {
  ContactScreenSVG_Dark,
  ContactScreenSVG_Light,
} from "../../common/Svg";

import apiHelper from "../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";

export default function ContactScreen({ verifyUserLogin }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [isLoading, setisLoading] = useState(false);
  const [contactFormDetails, setContactFormDetails] = useState({
    type: 1,
    name: "",
    email: "",
    description: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);

  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...contactFormDetails, [name]: value };
    setContactFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "contact",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  const SubmitContactFormDetails = async () => {
    try {
      let userInfo;
      try {
        userInfo = JSON.parse(localStorage.getItem("userInfo"));
      } catch (error) {
        userInfo = null;
      }
      if (!userInfo || (userInfo.role && userInfo.role === 4)) {
        verifyUserLogin();
        return;
      } else {
        setIsSubmitted(true);
        const validationResult = formValidator(
          contactFormDetails,
          "contact",
          "",
          t
        );
        if (validationResult.length > 0) {
          setValidationError(validationResult);
        } else {
          setisLoading(true);
          setValidationError([]);
          const result = await apiHelper.ContactHelpRequst(contactFormDetails);
          if (result.data?.s === 1) {
            toast.success(result.data?.m);
            setContactFormDetails({});
          } else {
            toast.error(result?.data?.m);
          }
        }
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.navbarBG.main,
        minHeight: `calc(100vh - 368px)`,
        pt: "85px",
        overflow: "hidden",
      }}
    >
      <Container maxWidth={"xl"}>
        <Box>
          <Box>
            <h2
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
                direction: language === "ar" ? "rtl" : "ltr",
                color: theme.palette.dark.main,
              }}
            >
              {t("contact.title")}
            </h2>
          </Box>

          <Box
            sx={{
              padding: { xs: "0.5rem 1rem", md: "0" },
              textAlign: { xs: "justify", md: "center" },
              direction: language === "ar" ? "rtl" : "ltr",
              color: theme.palette.paragraph.main,
              // border:"1px solid red",
              width: { md: "90%", lg: "75%" },
              margin: "auto",
            }}
          >
            <Typography>{t("contact.content")}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1.68rem 0",
              gap: { xs: "1rem", md: "4rem", lg: "5rem", xl: "7rem" },
              flexDirection: { xs: "column", sm: "row" },
              margin: "auto",
            }}
          >
            <ScrollAnimation animateIn="fadeIn">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "330px",
                      sm: "350px",
                      md: "360px",
                      lg: "380px",
                      xl: "400px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "9px",
                      mb: "1rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <InputComponent
                      type="email"
                      id="email"
                      name="email"
                      sx={{
                        bgcolor: theme.palette.input.main,
                        width: "100%",
                        borderRadius: "12px",
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                      }}
                      onChange={handleFormDetails}
                      isError={validationError?.find((x) => {
                        return x.key === "email" ? true : false;
                      })}
                      helperText={
                        validationError.find((x) => x.key === "email")?.message
                      }
                      value={contactFormDetails.email}
                      className="form-control"
                      placeholder={t("contact.email")}
                      style={{ color: "#1f2438" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      fontSize: "9px",
                      mb: "1rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <InputComponent
                      value={contactFormDetails.name}
                      name="name"
                      onChange={handleFormDetails}
                      isError={validationError?.find((x) => {
                        return x.key === "name" ? true : false;
                      })}
                      helperText={
                        validationError.find((x) => x.key === "name")?.message
                      }
                      sx={{
                        bgcolor: theme.palette.input.main,
                        width: "100%",
                        borderRadius: "12px",
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                        // input: {
                        //   "&::placeholder": {
                        //     textAlign: language === "ar" ? "right" : "left",
                        //   },
                        // },
                      }}
                      placeholder={t("contact.name")}
                      type="text"
                      id="name"
                      className="form-control"
                      style={{ color: "#1f2438" }}
                    />
                  </Box>

                  <Box sx={{ mb: "1rem" }}>
                    <InputComponent
                      value={contactFormDetails.description}
                      name="description"
                      onChange={handleFormDetails}
                      isError={validationError?.find((x) => {
                        return x.key === "description" ? true : false;
                      })}
                      helperText={
                        validationError.find((x) => x.key === "description")
                          ?.message
                      }
                      sx={{
                        bgcolor: theme.palette.input.main,
                        width: "100%",
                        borderRadius: "12px",
                        // input: { color: `#1f2438 !important` },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                        },
                        " .MuiOutlinedInput-input": {
                          textAlign: language === "ar" ? "right" : "left",
                        },
                      }}
                      multiline
                      placeholder={t("contact.des")}
                      rows={5}
                      style={{
                        color: "#1f2438",
                      }}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        borderRadius: "12px",
                        padding: "0.3em 5em",
                        textTransform: "capitalize",
                        width: "100%",
                        fontSize: "1.1rem",
                        bgcolor: theme.palette.gold.main,
                        color: "#FFF",
                      }}
                      disabled={!contactFormDetails.email || isLoading}
                      onClick={SubmitContactFormDetails}
                    >
                      {isLoading ? (
                        <>
                          <CircularProgress
                            disableShrink
                            sx={{ color: "#1f2438", margin: "0.2rem" }}
                            size={20}
                          />
                        </>
                      ) : (
                        `${t("contact.button")}`
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <Box
                sx={{
                  height: { md: "250px", lg: "333px" },
                  padding: { xs: "0", sm: "0.76rem" },
                  position: "relative",
                }}
              >
                {theme.palette.mode === "light" ? (
                  <Box
                    sx={{
                      display: { xs: "none", md: "block" },
                      aspectRatio: "3/2",
                      height: "100%",
                      // border:"1px solid red"
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    <ContactScreenSVG_Light />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      display: { xs: "none", md: "block" },
                      aspectRatio: "3/2",
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    <ContactScreenSVG_Dark />
                  </Box>
                )}
              </Box>
            </ScrollAnimation>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
