export const About_EN_json = {
    title: "About Us",
    content: {
        welcomeHeading: "Welcome to Aldera - Where Your Dream Home Awaits!",
        welcomeContent: "Discover the perfect blend of comfort, luxury, and style with Aldera Real Estate. We understand that finding the right home is more than just a transaction; it's about finding a place where you can create lasting memories, build your future, and truly thrive. Whether you're searching for a cozy urban apartment, a sprawling suburban estate, or a serene countryside retreat, Aldera is here to guide you every step of the way.",
        aldera: "Why Choose Aldera ?",

        alderaP1T: "Unparalleled Selection",
        alderaP1: "Explore a diverse portfolio of properties tailored to your unique preferences and lifestyle. From chic city lofts to charming family homes, waterfront properties to mountain retreats, we have something for everyone.",

        alderaP2T: "Expert Guidance",
        alderaP2: "Our team of experienced real estate professionals is dedicated to helping you find the ideal home. With in-depth market knowledge and personalized assistance, we ensure a seamless and stress-free experience. From understanding your needs to negotiating the best deals, we're with you every step of the way.",

        alderaP3T: "Exceptional Service",
        alderaP3: "Client satisfaction is our top priority. We believe in going above and beyond to exceed your expectations. From your initial inquiry to the closing of your dream home, we provide unparalleled customer service and support. Your happiness is our success.",

        alderaP4T: "Innovative Technology",
        alderaP4: "Leverage cutting-edge technology and advanced search tools to streamline your property search. Our user-friendly website and mobile app make it easy to explore listings, schedule viewings, and stay informed about the latest market trends. With Aldera, you have the power to find your dream home at your fingertips.",

        alderaP5T: "Community Connections",
        alderaP5: "Beyond just finding you a home, we strive to connect you with vibrant communities where you can thrive. Discover local amenities, schools, and recreational opportunities to enhance your quality of life. Whether you're moving across town or across the country, we'll help you find a neighborhood that feels like home.",

        journeyTitle: "Start Your Journey Today!",
        journeyP1: "Take the first step towards finding your dream home with Aldera Real Estate. Our mission is to make your real estate journey as smooth and rewarding as possible. Browse our listings, connect with our team, and embark on an exciting new chapter in your life. Whether you're buying, selling, or renting, Aldera is your trusted partner in real estate.",
        journeyP2: "Experience the difference with Aldera - Where Home Begins. Your dream home is just a click away."


    }
}



// export const About_AR_json = {
//     title: "معلومات عنا",//n
//     content: {
//         welcomeHeading: "مرحبًا بك في الجانب - حيث ينتظرك منزل أحلامك!",
//         welcomeContent: "اكتشف المزيج المثالي من الراحة والرفاهية والأناقة مع شركة ألديرا العقارية .نحن ندرك أن العثور على المنزل المناسب هو أكثر من مجرد صفقة؛ يتعلق الأمر بإيجاد مكان يمكنك من خلاله إنشاء ذكريات دائمة وبناء مستقبلك والازدهار حقًا. سواء كنت تبحث عن شقة حضرية مريحة، أو عقار مترامي الأطراف في الضواحي، أو ملاذ ريفي هادئ، فإن إلى الجانب هنا لإرشادك في كل خطوة على الطريق.",
//         aldera: "لماذا تختار ألديرا؟",

//         alderaP1T: "اختيار لا مثيل له",
//         alderaP1: "استكشف مجموعة متنوعة من العقارات المصممة خصيصًا لتناسب تفضيلاتك وأسلوب حياتك الفريد. بدءًا من الغرف العلوية الأنيقة في المدينة وحتى المنازل العائلية الساحرة، ومن العقارات المطلة على الواجهة البحرية إلى المنتجعات الجبلية، لدينا ما يناسب الجميع.",

//         alderaP2T: "توجيهات الخبراء",
//         alderaP2: "فريقنا من المتخصصين في مجال العقارات ذوي الخبرة مكرس لمساعدتك في العثور على المنزل المثالي. بفضل المعرفة المتعمقة بالسوق والمساعدة الشخصية، نضمن تجربة سلسة وخالية من التوتر. بدءًا من فهم احتياجاتك وحتى التفاوض على أفضل الصفقات، نحن معك في كل خطوة على الطريق.",

//         alderaP3T: "خدمة استثنائية",
//         alderaP3: "رضا العملاء هو أولويتنا القصوى. نحن نؤمن بأهمية الذهاب إلى أبعد الحدود لتجاوز توقعاتك. بدءًا من استفسارك الأولي وحتى إغلاق منزل أحلامك، نقدم خدمة عملاء ودعم لا مثيل لهما. سعادتك هي نجاحنا.",

//         alderaP4T: "التكنولوجيا المبتكرة",
//         alderaP4: "استفد من التكنولوجيا المتطورة وأدوات البحث المتقدمة لتبسيط عملية البحث عن الممتلكات الخاصة بك. يسهل موقعنا الإلكتروني وتطبيقنا للهاتف المحمول سهل الاستخدام استكشاف القوائم وجدولة العروض والبقاء على اطلاع بأحدث اتجاهات السوق. مع على الجانب الآخر، لديك القدرة للعثور على منزل أحلامك في متناول يدك.",

//         alderaP5T: "الاتصالات المجتمعية",
//         alderaP5: "بعيدًا عن مجرد العثور على منزل لك، فإننا نسعى جاهدين لربطك بمجتمعات نابضة بالحياة حيث يمكنك أن تزدهر. اكتشف المرافق المحلية والمدارس والفرص الترفيهية لتحسين نوعية حياتك. سواء كنت تنتقل عبر المدينة أو عبر البلاد، فإننا سوف نساعدك في العثور على الحي الذي يبدو وكأنه منزلك.",
//         journeyTitle: "ابدأ رحلتك اليوم!",

//         journeyP1: "اتخذ الخطوة الأولى نحو العثور على منزل أحلامك مع شركة ألديرا العقارية مهمتنا هي أن نجعل رحلتك العقارية سلسة ومجزية قدر الإمكان. تصفح قوائمنا، وتواصل مع فريقنا، وابدأ فصلًا جديدًا ومثيرًا في حياتك. سواء كنت تشتري أو تبيع أو تستأجر، فإن إلى الجانب هي شريكك الموثوق به في مجال العقارات.",
//         journeyP2: "جرب الفرق مع الجانب - حيث يبدأ المنزل. منزل أحلامك فقط بنقرة زر واحدة."




//     }
// }

export const About_AR_json = {
    title: "معلومات عنا",
    content: {
        welcomeHeading: "مرحبًا بك في ألديرا - حيث ينتظرك منزل أحلامك!",
        welcomeContent: "اكتشف المزيج المثالي من الراحة والرفاهية والأناقة مع شركة ألديرا العقارية. نحن ندرك أن العثور على المنزل المناسب هو أكثر من مجرد صفقة؛ إنه يتعلق بإيجاد مكان يمكنك فيه إنشاء ذكريات دائمة، وبناء مستقبلك، والازدهار حقًا. سواء كنت تبحث عن شقة حضرية مريحة، أو عقار مترامي الأطراف في الضواحي، أو ملاذ ريفي هادئ، فإن ألديرا هنا لإرشادك في كل خطوة على الطريق.",
        aldera: "لماذا تختار ألديرا؟",
        alderaP1T: "اختيار لا مثيل له",
        alderaP1: "استكشف مجموعة متنوعة من العقارات المصممة خصيصًا لتناسب تفضيلاتك وأسلوب حياتك الفريد. بدءًا من الغرف العلوية الأنيقة في المدينة إلى المنازل العائلية الساحرة، ومن العقارات المطلة على الواجهة البحرية إلى المنتجعات الجبلية، لدينا ما يناسب الجميع.",
        alderaP2T: "توجيهات الخبراء",
        alderaP2: "فريقنا من المتخصصين في مجال العقارات ذوي الخبرة مكرس لمساعدتك في العثور على المنزل المثالي. بفضل المعرفة المتعمقة بالسوق والمساعدة الشخصية، نضمن تجربة سلسة وخالية من التوتر. بدءًا من فهم احتياجاتك إلى التفاوض على أفضل الصفقات، نحن معك في كل خطوة على الطريق.",
        alderaP3T: "خدمة استثنائية",
        alderaP3: "رضا العملاء هو أولويتنا القصوى. نحن نؤمن بأهمية الذهاب إلى أبعد الحدود لتجاوز توقعاتك. بدءًا من استفسارك الأولي إلى إغلاق منزل أحلامك، نقدم خدمة عملاء ودعمًا لا مثيل لهما. سعادتك هي نجاحنا.",
        alderaP4T: "التكنولوجيا المبتكرة",
        alderaP4: "استفد من التكنولوجيا المتطورة وأدوات البحث المتقدمة لتبسيط عملية البحث عن الممتلكات الخاصة بك. يسهل موقعنا الإلكتروني وتطبيقنا للهاتف المحمول سهل الاستخدام استكشاف القوائم، وجدولة العروض، والبقاء على اطلاع بأحدث اتجاهات السوق. مع ألديرا، لديك القدرة على العثور على منزل أحلامك في متناول يدك.",
        alderaP5T: "الاتصالات المجتمعية",
        alderaP5: "بعيدًا عن مجرد العثور على منزل لك، نسعى جاهدين لربطك بمجتمعات نابضة بالحياة حيث يمكنك أن تزدهر. اكتشف المرافق المحلية، والمدارس، والفرص الترفيهية لتحسين نوعية حياتك. سواء كنت تنتقل عبر المدينة أو عبر البلاد، سنساعدك في العثور على الحي الذي يبدو وكأنه منزلك.",
        journeyTitle: "ابدأ رحلتك اليوم!",
        journeyP1: "اتخذ الخطوة الأولى نحو العثور على منزل أحلامك مع شركة ألديرا العقارية. مهمتنا هي أن نجعل رحلتك العقارية سلسة ومجزية قدر الإمكان. تصفح قوائمنا، وتواصل مع فريقنا، وابدأ فصلًا جديدًا ومثيرًا في حياتك. سواء كنت تشتري أو تبيع أو تستأجر، فإن ألديرا هي شريكك الموثوق به في مجال العقارات.",
        journeyP2: "جرب الفرق مع ألديرا - حيث يبدأ المنزل. منزل أحلامك على بُعد نقرة واحدة."
    }
}
