import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Path from "../../common/Path";
import { InstaSVG, TwitterSVG } from "../../common/Svg";
import { toast } from "react-toastify";
import InputComponentOutline from "../Input/InputComponentOutline";
import apiHelper from "../../common/API/ApiHelper";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext } from "../../Context/LanguageContext";

export default function Footer(props) {
  const [email, setEmail] = useState();
  const { themeMode } = props;
  const theme = useTheme();
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  let userInfo;
  try
  {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error)
  {
    userInfo = null;
  }

  const isGuestUser = userInfo?.role === 4 ? true : false;

  const handleSubscribe = async () => {
    try
    {
      const validate = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        email
      );
      if (!validate)
      {
        toast.error(
          language === "en"
            ? "Please enter valid email"
            : "الرجاء إدخال بريد إلكتروني صحيح"
        );
      } else
      {
        const res = await apiHelper.SubscribeUser({ email: email });
        if (res?.data?.s === 1)
        {
          toast.success(
            language === "en" ? "Successfully Subscribed" : "تم الاشتراك بنجاح"
          );
          setEmail("");
        }
      }
    } catch (error)
    {
      console.error(error);
    }
  };
  return (
    <Box
      sx={{
        fontSize: { xs: "0.85rem", sm: "1rem" },
        padding: { xs: "1rem", sm: "2.25rem" },
        display:
          pathname === Path.HOME ||
            pathname === Path.ABOUT ||
            pathname === Path.CONTACT ||
            pathname === Path.HELP
            ? "block"
            : "none",
        bgcolor:
          pathname === Path.HOME
            ? theme.palette.navbarBG.main
            : theme.palette.newBg_footer,
      }}
    >
      <Container
        maxWidth={"xl"}
        sx={{
          // "& .MuiGrid-item": { padding: "1rem" },
          justifyContent: "center",
          color: themeMode === "light" ? "#1f2438" : "#eee",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5.8} md={2.5} xl={3}>
            <Box
              sx={{
                // border: "1px solid red",
                width: "100%",

                display: "flex",
                flexDirection: "column",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                },
                direction: language === "ar" ? "rtl" : "ltr",
                gap: "0.25rem",
              }}
              className="text"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "center" },
                }}
              >
                {themeMode === "light" ? (
                  <img
                    src="/assets/image/home/logo.svg"
                    // width={{ xs: "50%", sm: "80%", md: "100%" }}
                    width={"77px"}
                    alt=""
                    srcSet=""
                  />
                ) : (
                  <img
                    src="/assets/image/home/dlogo.svg"
                    // width={{ xs: "50%", sm: "80%", md: "100%" }}
                    width={"77px"}
                    alt=""
                    srcSet=""
                  />
                )}
              </Box>

              <Typography
                sx={{
                  // mt: "9px",
                  mb: "4px",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                  },
                  fontSize: { xs: "0.85rem", sm: "1rem" },
                }}
                className="footerList"
              >
                <a
                  className="footerList"
                  href="mailto: Johnsingh@gmail.com"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                  }}
                >
                  johnsingh@gmail.com
                </a>
              </Typography>
              <Typography
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                  },
                  fontSize: { xs: "0.85rem" },
                }}
              >
                +1(201) 895-3801
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  pt: "0.5rem ",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12%",
                    cursor: "pointer",
                  }}
                >
                  <InstaSVG color={theme.palette.dark.main} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12%",
                    cursor: "pointer",
                  }}
                >
                  <TwitterSVG
                    color={theme.palette.dark.main}
                    dcolor={theme.palette.light.main}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={5.8} md={2.5} xl={2}>
            <Box
              sx={{
                width: "100%",
                // border: "1px solid red",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                textAlign: { xs: "center", sm: "start" },
                mt: { xs: "1rem", md: "0" },
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              <Box
                sx={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  alignItems:
                    language === "en" ? { xs: "center", md: "start" } : "right",
                  flexDirection: "column",
                  direction: language === "ar" ? "rtl" : "ltr",
                }}
              >
                <Box
                  sx={{
                    width: "150px",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  {t("footer.service.title")}
                </Box>
                <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                    // "&:hover": { color: "red !important" },
                  }}
                >
                  <Link
                    style={{
                      color: theme.palette.dark.main,
                    }}
                    className="footerList"
                    to={Path.PROPERTY_EVALUATION}
                  >
                    {t("footer.service.eva")}
                  </Link>
                </Box>
                <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                  }}
                >
                  <Link
                    style={{ color: theme.palette.dark.main }}
                    to={`${Path.PROPERTY}?value=1`}
                    className="footerList"
                  >
                    {t("footer.service.rent")}
                  </Link>
                </Box>
                <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                  }}
                >
                  <Link
                    style={{ color: theme.palette.dark.main }}
                    to={`${Path.PROPERTY}?value=2`}
                    className="footerList"
                  >
                    {t("footer.service.buy")}
                  </Link>
                </Box>
                <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                  }}
                >
                  <Link
                    style={{ color: theme.palette.dark.main }}
                    to={Path.ADD_PROPERTY}
                    className="footerList"
                  >
                    {t("footer.service.sell")}
                  </Link>
                </Box>
                {/* <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                  }}
                >
                  Buy Property
                </Box> */}
                <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                    display: !userInfo || isGuestUser ? "block" : "none",
                  }}
                >
                  <Link
                    to={Path.SIGN_IN}
                    style={{ color: theme.palette.dark.main }}
                    className="footerList"
                  >
                    {t("footer.service.agent")}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5} xl={2}>
            <Box
              sx={{
                width: "100%",
                // border: "1px solid red",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                textAlign: { xs: "center", md: "start" },
                mt: { xs: "1.5rem" },
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              <Box
                sx={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  alignItems:
                    language === "en" ? { xs: "center", md: "start" } : "right",
                  flexDirection: "column",
                  direction: language === "ar" ? "rtl" : "ltr",
                }}
              >
                <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                    // "&:hover": { color: "red !important" },
                  }}
                >
                  <a
                    href={
                      language === "ar"
                        ? "https://api.aldera.app/terms-condition/ar/index.html"
                        : "https://api.aldera.app/terms-condition/en/index.html"
                    }
                    style={{
                      color: theme.palette.dark.main,
                    }}
                    target="_blank"
                    rel="noreferrer"
                    className="footerList"
                  // to={Path.PROPERTY_EVALUATION}
                  >
                    {t("footer.tc")}
                  </a>
                </Box>
                <Box
                  sx={{
                    width: "150px",
                    margin: { xs: "5px 0px", md: "11px 0px 0 0" },
                  }}
                >
                  <a
                    href={
                      language === "ar"
                        ? "https://api.aldera.app/privacy-policy/ar/index.html"
                        : "https://api.aldera.app/privacy-policy/en/index.html"
                    }
                    style={{
                      color: theme.palette.dark.main,
                    }}
                    target="_blank"
                    rel="noreferrer"
                    className="footerList"
                    to={Path.PROPERTY_EVALUATION}
                  >
                    {t("footer.pp")}
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4.5} xl={4}>
            <Box
              sx={{
                marginTop: {
                  xs: "1rem",
                  md: "0",
                },
                // border: "1px solid red",
                width: "100%",
                color: "#EEE",
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                bgcolor: theme.palette.newsBox.main,
                borderRadius: "16px",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              <Box sx={{ padding: "1.5rem 2rem" }}>
                <Box
                  sx={{
                    borderRadius: "12px",
                    // padding: "0rem 3rem",
                    //
                    // color: themeMode === "light" ? "#fff" : "#1f2438",
                    // boxShadow: `0px 0px 2px 0px  ${
                    //   themeMode === "dark" ? "#eee" : "#1f2438"
                    // }`,
                    // maxWidth: "380px",
                    // minWidth: "300px",
                    // border:"1px solid #1f243873",
                    // bgcolor: themeMode === "light" ? "#DBDBDB" : "#181c2f",
                  }}
                >
                  <Typography
                    sx={{
                      mb: "0.5em",
                      direction: language === "ar" ? "rtl" : "ltr",
                      textAlign: language === "ar" ? "right" : "left",
                      fontSize: "1.1em",
                      fontWeight: "bold",
                    }}
                  >
                    {t("footer.sub.title")}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "0.95rem",

                      direction: language === "ar" ? "rtl" : "ltr",
                      textAlign: language === "ar" ? "right" : "left",
                    }}
                  >
                    {t("footer.sub.content")}
                  </Typography>

                  <Box sx={{ display: "flex", gap: "0.4rem", mt: "0.5rem" }}>
                    <FormControl
                      sx={{
                        width: "80%",
                        borderRadius: "12px",
                      }}
                      variant="outlined"
                    >
                      <InputComponentOutline
                        id="outlined-adornment-password"
                        placeholder={t("footer.sub.email")}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        type={"email"}
                        sx={{
                          px: "10px",
                          borderRadius: "8px",
                          color: "#1f2438",
                          direction: language === "ar" ? "rtl" : "ltr",
                          textAlign: language === "ar" ? "right" : "left",
                          "&:hover": {
                            "& fieldset": { border: "none" },
                          },
                          "& .MuiOutlinedInput-input": {
                            textAlign: language === "ar" ? "right" : "left",
                            padding: "14px ",
                          },
                          " .MuiOutlinedInput-input": {
                            textAlign: language === "ar" ? "right" : "left",
                          },
                          bgcolor: "#EEE",
                          border:
                            theme.palette.mode === "light" &&
                              location.pathname === "/"
                              ? "1px solid #181c2f5c"
                              : "",
                          width: "100%",
                        }}
                        value={email}
                      />
                    </FormControl>
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "14px",

                        p: "0rem 1rem",
                        height: "3.22rem",
                        textTransform: "none",
                        bgcolor: "#fff",
                        fontWeight: "600",
                        color: "#000",
                        cursor: "pointer",
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: "#EEE",
                        },
                        ":disabled": {
                          bgcolor: "#fff",
                          color: "#000",
                        },
                      }}
                      size="small"
                      disabled={!email}
                      onClick={handleSubscribe}
                    >
                      {t("footer.sub.button")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: { xs: "block", sm: "none" }, mb: "1rem" }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "0.4rem",
                direction: language === "ar" ? "rtl" : "ltr",
              }}
            >
              <FormControl
                sx={{
                  width: "80%",
                  borderRadius: "12px",
                }}
                variant="outlined"
              >
                <InputComponentOutline
                  id="outlined-adornment-password"
                  placeholder={t("footer.sub.email")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type={"email"}
                  sx={{
                    borderRadius: "12px",
                    color: "#1f2438",
                    pl: "1rem",
                    flexDirection: "column",
                    direction: language === "ar" ? "rtl" : "ltr",
                    "&:hover": {
                      "& fieldset": { border: "none" },
                    },
                    "& .MuiOutlinedInput-input": {
                      textAlign: language === "ar" ? "right" : "left",
                      padding: "14px ",
                    },
                    " .MuiOutlinedInput-input": {
                      textAlign: language === "ar" ? "right" : "left",
                    },
                    bgcolor: "#EEE",
                    border:
                      theme.palette.mode === "light" &&
                        location.pathname === "/"
                        ? "1px solid #181c2f5c"
                        : "",
                    width: "100%",
                  }}
                  value={email}
                />
              </FormControl>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: theme.palette.mode === "light" ? "#1f2438" : "#EEE",
                  borderRadius: "12px",
                  color: theme.palette.mode === "dark" ? "#1f2438" : "#EEE",
                  "&:hover": {
                    // backgroundColor: "#EEE",
                  },
                  ":disabled": {
                    bgcolor:
                      theme.palette.mode === "light" ? "#000000a1" : "#b2b2b2",
                    color: "white",
                  },
                }}
                disabled={!email}
                onClick={handleSubscribe}
              >
                {t("footer.sub.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
