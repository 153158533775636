// English
export const Help_EN_json = {
    title: "Hello, We are here to help",
    content: "Explore Our Help Resources: Your Trusted Partner for Guidance and Support in Navigating Property Transactions and Finding Your Ideal Home or Investment Opportunity.",
    name: "Enter your name",
    email: "Enter your email",
    des: "Description",
    button: "Submit",
};

// Arabic
export const Help_AR_json = {
    title: "مرحبا، نحن هنا للمساعدة",
    content: "استكشف موارد المساعدة لدينا: شريكك الموثق به للحصول على التوجيه والدعم في التعامل مع المعاملات العقارية والعثور على منزلك المثالي أو فرصة الاستثمار.",
    name: "أدخل أسمك",
    email: "أدخل بريدك الإلكتروني",
    des: "وصف العقار*",
    button: "يُقدِّم",
};
