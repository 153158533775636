import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

export default function UserDeleteDialogue(props) {
  const { handleToggleDrawer, handleDeleteUserAccount } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Box
        onClick={() => {
          setOpen(true);
          handleToggleDrawer && handleToggleDrawer();
        }}
      >
        {t("navbar.menu.userProfile.delete.mainTitle")}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 5, // Adjusted border radius
            // direction: language === "ar" ? "rtl" : "ltr",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            backdropFilter: "blur(3px)",
          },
        }}
      >
        <Box sx={{ bgcolor: "#FFF", borderRadius: "12px" }}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "colomn",
              }}
            >
              <img
                style={{ aspectRatio: "1.1", borderRadius: "50%" }}
                src="/assets/image/delete.svg"
                width={"80px"}
                height={"85px"}
                alt=""
                srcSet=""
              />
            </Box>
            <Typography
              sx={{
                pr: "1rem",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "22px",
                color: "#1f2438",
              }}
            >
              {t("navbar.menu.userProfile.delete.title")}
            </Typography>
            <Typography
              sx={{ textAlign: "center", pr: "5px", color: "#1f2438" }}
            >
              {t("navbar.menu.userProfile.delete.msg")}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Box
              sx={{
                minWidth: {
                  xs: "100%",
                  sm: " 280px",
                },
                display: {
                  xs: "block",
                  md: "flex",
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <Box
                  sx={{
                    mb: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    variant="outlined"
                    sx={{
                      width: "160px",
                      bgcolor: "#EEE",
                      color: "#1f2438",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#EEE",
                      },
                      borderRadius: "12px",
                    }}
                  >
                    {t("navbar.menu.userProfile.delete.cancle")}
                  </Button>
                  <Button
                    onClick={() => {
                      handleDeleteUserAccount();
                      setOpen(false);
                    }}
                    variant="outlined"
                    sx={{
                      width: "160px",
                      bgcolor: "#A65353",
                      color: "#FFF",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#A65353",
                      },
                      borderRadius: "12px",
                    }}
                  >
                    {t("navbar.menu.userProfile.delete.delete")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
}
