
const nameRegex = /^[a-zA-Z]{2,30}$/;
// const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
//eslint-disable-next-line
const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
const passwordRegex = /^(?=\S{6,}$).*/;
const phoneNumberRegex = /^\+?\d{10,13}$/;
//eslint-disable-next-line
const addressRegex = /^[a-zA-Z0-9\s\.,#-]*$/;
const passwordMessage = `6 characters required, no spaces.
`;
const cityRegex = /^(?! )[a-zA-Z]+(?:[\s'-][a-zA-Z]+)*$/



const formValidator = (data, type, googleLogin, t) => {


  const error = [];

  const validateField = (
    fieldName,
    regex,
    requireMSG,
    errorMessage,
    validationFunction,
    validateOnChange = true
  ) => {
    if (!data[fieldName] && !validateOnChange)
    {
      return;
    }

    if (!data["parking"])
    {
      if (!data[fieldName] || data[fieldName] === "0")
      {
        error.push({
          key: fieldName,
          // message: `Required field ${fieldName.toLowerCase()} is empty`,
          message: requireMSG,
        });
      } else if (
        !(regex instanceof RegExp) ||
        !regex.test(data[fieldName]) ||
        (validationFunction && !validationFunction(data))
      )
      {
        error.push({ key: fieldName, message: errorMessage });
      }
    } else
    {
      if (!data[fieldName])
      {
        error.push({
          key: fieldName,
          // message: `Required field ${fieldName.toLowerCase()} is empty`,
          message: requireMSG,
        });
      } else if (
        !(regex instanceof RegExp) ||
        !regex.test(data[fieldName]) ||
        (validationFunction && !validationFunction(data))
      )
      {
        error.push({ key: fieldName, message: errorMessage });
      }
    }
  };

  const ImageValidator = (data, type, RequiredMSG, t) => {
    const imageFile = data[type]?.name;
    if (!imageFile)
    {
      error.push({
        key: type,
        // message: `Please select an ${type.toLowerCase()}`,
        message: RequiredMSG,
      });
    } else if (!(/\.(jpg|jpeg|png|gif)$/).test(imageFile))
    {
      error.push({
        key: type,
        message: `${t("validation.fileFormate", { type: type.toLowerCase() })}`
      });
    }
  };



  const CombineValidator = (data, type, RequiredMSG, t) => {
    const File = data[type];
    if (File.length === 0)
    {
      error.push({
        key: type,
        message: RequiredMSG,
      });
    }
    else if (File.length < 5)
    {
      error.push({
        key: type,
        message: `${t("validation.fiveRequired")}`,
      });
    }
  };

  if (type === "agriEvalution")
  {
    validateField("floorArea", /./, `${t("validation.floorArea")}`);
    validateField(
      "soilQualityType",
      /./,
      `${t("validation.soliQuality")}`
    );
    validateField(
      "cropYieldHistory",
      /./,
      `${t("validation.ydHistory")}`
    );
    validateField(
      "govtRegulations",
      /./,
      `${t("validation.govtRegu")}`
    );
    validateField("proximity", /./, `${t("validation.proximity")}`);
    validateField("demand", /./, `${t("validation.demand")}`);
    validateField("potential", /./, `${t("validation.potential")}`);
  }

  if (type === "residentEvalution")
  {
    validateField("property", /./, `${t("validation.property")}`);
    validateField("propertySize", /./, `${t("validation.propertySize")}`);
    validateField("noOfBedroom", /./, `${t("validation.bed")}`);
    validateField(
      "noOfWashroom",
      /./,
      `${t("validation.washroom")}`
    );
    validateField(
      "ageOfProperty",
      /./,
      `${t("validation.propertyAge")}`
    );
    validateField(
      "propertyValue",
      /./,
      `${t("validation.propertyValue")}`
    );
    validateField("location", /./, `${t("validation.location")}`);
    validateField(
      "materialsAndQuality",
      /./,
      `${t("validation.mANDq")}`
    );
  }

  if (type === "industrialEvalution")
  {
    validateField("area", /./, `${t("validation.area")}`);
    validateField("location", /./, `${t("validation.location")}`);
    validateField(
      "CeilingHeight",
      /./,
      `${t("validation.ceilingHeight")}`
    );
    validateField(
      "loadingCapacity",
      /./,
      `${t("validation.loading")}`
    );
    validateField(
      "regulationsAndPermition",
      /./,
      `${t("validation.RnP")}`
    );
    validateField(
      "rent_leaseRates",
      /./,
      `${t("validation.lease")}`
    );
  }

  if (type === "commercialEvalution")
  {
    validateField("area", /./, `${t("validation.area")}`);
    validateField("RentalIncome", /./, `${t("validation.rentalIncome")}`);
    validateField("parkingSpace", /./, `${t("validation.pSpace")}`);
    validateField(
      "occupancyRates",
      /./,
      `${t("validation.oRate")}`
    );
    validateField("location", /./, `${t("validation.location")}`);
    validateField(
      "economyFactors",
      /./,
      `${t("validation.economy")}`
    );
    validateField(
      "regulationsAndPermition",
      /./,
      `${t("validation.RnP")}`
    );
  }

  if (type === "addProperty")
  {
    validateField("property", /./, `${t("validation.property")}`);
    validateField("province", cityRegex, `${t("validation.provience")}`, `${t("validation.provienceRegex")}`);
    validateField("city", cityRegex, `${t("validation.city")}`, `${t("validation.provienceRegex")}`);
    validateField("location", /./, `${t("validation.location")}`);
    CombineValidator(data, "image", `${t("validation.image")}`, t);
    validateField("Price", /^\d*[0-9]\d*$/, `${t("validation.price")}`, `${t("validation.count")}`);
    validateField("description", /./, `${t("validation.descri")}`);
    validateField("bedroom", /^\d*[0-9]\d*$/, `${t("validation.bed")}`, `${t("validation.count")}`);
    validateField("bathroom", /^\d*[0-9]\d*$/, `${t("validation.bath")}`, `${t("validation.count")}`);
    validateField("parking", /^\d+$/, `${t("validation.parking")}`, `${t("validation.count")}`);
    // validateField("tv", /^\d*[0-9]\d*$/, `${t("validation.tv")}`, `${t("validation.count")}`);
  }

  if (type === "updateUser")
  {
    validateField(
      "firstName",
      nameRegex,
      `${t("validation.firstName")}`,
      `${t("validation.invalidFN")}`,
    );
    validateField(
      "lastName",
      nameRegex,
      `${t("validation.lastName")}`,
      `${t("validation.invalidLN")}`,
    );
    validateField(
      "email",
      emailRegex,
      `${t("validation.email")}`,
      `${t("validation.invalidEmail")}`,
    );
    validateField(
      "phoneNumber",
      phoneNumberRegex,
      `${t("validation.number")}`,
      `${t("validation.invalidNumber")}`,
    );
    // validateField(
    //   "address",
    //   addressRegex,
    //   `${t("validation.address")}`,

    // );
  }

  if (type === "agent")
  {
    validateField(
      "firstName",
      nameRegex,
      `${t("validation.firstName")}`,
      `${t("validation.invalidFN")}`,
    );
    validateField(
      "lastName",
      nameRegex,
      `${t("validation.lastName")}`,
      `${t("validation.invalidLN")}`,
    );
    validateField(
      "email",
      emailRegex,
      `${t("validation.email")}`,
      `${t("validation.invalidEmail")}`,
    );
    if (!googleLogin)
    {
      validateField(
        "password",
        passwordRegex,
        `${t("validation.password")}`,
        passwordMessage
      );
      validateField(
        "confirmPasword",
        passwordRegex,
        `${t("validation.password")}`,
        `${t("validation.passNotMatch")}`,
        (data) => data.confirmPasword === data.password
      );
    }
    // validateField(
    //   "phoneNumber",
    //   phoneNumberRegex,
    //   "Required field number is empty",
    //   "accept 10 - 13 digits only",
    // );
    validateField("civilId", /./, `${t("validation.civilID")}`);
    validateField("description", /./, `${t("validation.descri")}`,
    );
    validateField("agency_id", /./, `${t("validation.agencyID")}`);
    ImageValidator(data, "image", `${t("validation.image")}`, t);
  }

  if (type === "agency")
  {
    validateField(
      "companyName",
      /./,
      `${t("validation.companyName")}`,
      // `${t("validation.invalidComName")}`,
    );
    validateField(
      "email",
      emailRegex,
      `${t("validation.email")}`,
      `${t("validation.invalidEmail")}`,
    );
    validateField(
      "phoneNumber",
      phoneNumberRegex,
      `${t("validation.number")}`,
      `${t("validation.invalidNumber")}`,
    );
    validateField(
      "companyWebsite",
      /./,
      `${t("validation.website")}`,
    );
    validateField(
      "companyAddress",
      /./,
      `${t("validation.address")}`,
    );
    validateField(
      "electronicNumber",
      /./,
      `${t("validation.electronicNumber")}`,
    );
    if (!googleLogin)
    {
      validateField(
        "password",
        passwordRegex,
        `${t("validation.password")}`,
        passwordMessage
      );
      validateField(
        "confirmPasword",
        passwordRegex,
        `${t("validation.password")}`,
        `${t("validation.passNotMatch")}`,
        (data) => data.confirmPasword === data.password
      );
    }

    validateField("description", /./,
      `${t("validation.descri")}`,
    );

    ImageValidator(
      data,
      "commercialLicense",
      `${t("validation.commerLC")}`, t
    );
    ImageValidator(
      data,
      "agencyLicense",
      `${t("validation.agencyLC")}`,
    );
    ImageValidator(data, "logo",
      `${t("validation.logo")}`, t
    );
  }

  if (type === "customer")
  {
    validateField(
      "firstName",
      nameRegex,
      `${t("validation.firstName")}`,
      `${t("validation.invalidFN")}`,
    );
    validateField(
      "lastName",
      nameRegex,
      `${t("validation.lastName")}`,
      `${t("validation.invalidLN")}`,
    );
    validateField(
      "email",
      emailRegex,
      `${t("validation.email")}`,
      `${t("validation.invalidEmail")}`,
    );
    if (!googleLogin)
    {
      validateField(
        "password",
        passwordRegex,
        `${t("validation.password")}`,
        passwordMessage
      );
      validateField(
        "confirmPasword",
        passwordRegex,
        `${t("validation.password")}`,
        `${t("validation.passNotMatch")}`,
        (data) => data.confirmPasword === data.password
      );
    }
    // validateField(
    //   "phoneNumber",
    //   phoneNumberRegex,
    //   `${t("validation.number")}`,
    //   `${t("validation.invalidNumber")}`,
    // );
    // validateField("description", /./ );
  }

  if (type === "contact")
  {
    validateField(
      "name",
      nameRegex,
      `${t("validation.name")}`,
      `${t("validation.inValidName")}`,
    );
    validateField(
      "email",
      emailRegex,
      `${t("validation.email")}`,
      `${t("validation.invalidEmail")}`,
    );
    validateField("description",
      /./,
      `${t("validation.descri")}`,
    );
  }

  if (type === "login")
  {
    validateField(
      "email",
      emailRegex,
      `${t("validation.email")}`,
      `${t("validation.invalidEmail")}`,
    );
    validateField(
      "password",
      passwordRegex,
      `${t("validation.password")}`,
      passwordMessage
    );
  }

  return error;
};

export default formValidator;
