import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import InputComponent from "../../../component/Input/InputComponentTextField";
import formValidator from "../../../common/Validator";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";

export default function IndustrialEvaluation() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [formDetails, setFormDetails] = useState({
    area: "",
    location: "",
    CeilingHeight: "",
    loadingCapacity: "",
    regulationsAndPermition: "",
    rent_leaseRates: "",
    condition: "",
    sectorTrends: "",
    transportation: [],
  });

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const HandleFormSubmittedDetails = () => {
    try {
      setIsSubmitted(true);
      const validationResult = formValidator(
        formDetails,
        "industrialEvalution",
        "",
        t
      );
      if (validationResult.length > 0) {
        return setValidationError(validationResult);
      }
      if (!formDetails.condition)
        return toast.error(
          language === "en"
            ? "select Building Condition fields"
            : "حدد حقول حالة المبنى"
        );

      if (!formDetails.sectorTrends)
        return toast.error(
          language === "en"
            ? "select industrial sector Trends fields"
            : "حدد مجالات اتجاهات القطاع الصناعي"
        );
      if (formDetails.transportation.length === 0)
        return toast.error(
          language === "en"
            ? "select transportation Details"
            : "اختر تفاصيل النقل"
        );
      navigate(Path.PROPERTY_EVALUATION_INDUSTRIAL__CALC);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "residentEvalution",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  return (
    <ScrollAnimation animateIn="fadeIn">
      <div id="resident" style={{ padding: "1rem" }}>
        <Typography
          sx={{
            fontWeight: "600",
            color: theme.palette.primary.main,
          }}
          variant="text"
        >
          {t("eval.msg")}
        </Typography>
        <Box sx={{ pt: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "0", sm: "1rem" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="area"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "area" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "area")?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="area"
                  value={formDetails.area}
                  placeholder={t("eval.indu.area")}
                  // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="CeilingHeight"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "CeilingHeight" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "CeilingHeight")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="CeilingHeight"
                  value={formDetails.CeilingHeight}
                  placeholder={t("eval.indu.cHeight")}
                  // style={{ color: "red" }}
                />
              </Box>
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="location"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "location" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "location")?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="location"
                  value={formDetails.location}
                  placeholder={t("eval.indu.location")}
                  // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="loadingCapacity"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "loadingCapacity" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "loadingCapacity")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="loadingCapacity"
                  value={formDetails.loadingCapacity}
                  placeholder={t("eval.indu.capa")}
                  // style={{ color: "red" }}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="text"
              name="regulationsAndPermition"
              onChange={handleFormDetails}
              isError={validationError?.find((x) => {
                return x.key === "regulationsAndPermition" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "regulationsAndPermition")
                  ?.message
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: "100%",
                borderRadius: "12px",
              }}
              id="regulationsAndPermition"
              value={formDetails.regulationsAndPermition}
              placeholder={t("eval.indu.rp")}
            />
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="text"
              name="rent_leaseRates"
              onChange={handleFormDetails}
              isError={validationError?.find((x) => {
                return x.key === "rent_leaseRates" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "rent_leaseRates")
                  ?.message
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: "100%",
                borderRadius: "12px",
              }}
              id="rent_leaseRates"
              value={formDetails.rent_leaseRates}
              placeholder={t("eval.indu.value")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: {
                xs: "center",
                sm: "space-between",
              },
            }}
          >
            <Box>
              <Box> {t("eval.indu.condi.title")}</Box>
              <Box
                sx={{
                  mt: "0.5rem",
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "1rem",
                }}
              >
                <Box>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="condition"
                      value={"good"}
                      onChange={handleFormDetails}
                    />
                    <span className="checkmark"></span>
                    {t("eval.indu.condi.good")}
                  </label>
                </Box>
                <Box sx={{ display: "inline-block" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="condition"
                      value={"bad"}
                      onChange={handleFormDetails}
                    />
                    <span className="checkmark"></span>
                    {t("eval.indu.condi.bad")}
                  </label>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: { xs: "1rem", sm: "0" } }}>
              <Box>{t("eval.indu.ind.title")}</Box>
              <Box
                sx={{
                  mt: "0.5rem",
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "1rem",
                }}
              >
                <label className="custom-radio">
                  <input
                    type="radio"
                    value={"high"}
                    name="sectorTrends"
                    onChange={handleFormDetails}
                  />
                  <span className="checkmark"></span>
                  {t("eval.indu.ind.high")}
                </label>

                <Box sx={{ display: "inline-block", ml: "1rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="sectorTrends"
                      value={"low"}
                      onChange={handleFormDetails}
                    />
                    <span className="checkmark"></span>
                    {t("eval.indu.ind.low")}
                  </label>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              justifyContent: {
                xs: "space-around",
                sm: "space-between",
              },
              mt: "1rem",
              gap: "1rem",
            }}
          >
            <Box>
              <Box> {t("eval.indu.tran.title")}</Box>
              <Box
                sx={{
                  // mt: "0.5rem",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ display: "inline-block", m: "0.5rem 0" }}>
                    <label className="custom-radio">
                      <input
                        type="checkbox"
                        value={"highway"}
                        inputMode=""
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setFormDetails((prevFormDetails) => ({
                            ...prevFormDetails,
                            transportation: isChecked
                              ? [
                                  ...prevFormDetails.transportation,
                                  e.target.value,
                                ]
                              : prevFormDetails.transportation.filter(
                                  (x) => x !== e.target.value
                                ),
                          }));
                        }}
                      />
                      <span className="checkmark"></span>
                      {t("eval.indu.tran.high")}
                    </label>
                  </Box>
                  <Box
                    sx={{
                      display: "inline-block",
                      m: "0.5rem",
                    }}
                  >
                    <label className="custom-radio">
                      <input
                        type="checkbox"
                        value={"bus"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setFormDetails((prevFormDetails) => ({
                            ...prevFormDetails,
                            transportation: isChecked
                              ? [
                                  ...prevFormDetails.transportation,
                                  e.target.value,
                                ]
                              : prevFormDetails.transportation.filter(
                                  (x) => x !== e.target.value
                                ),
                          }));
                        }}
                      />
                      <span className="checkmark"></span>
                      {t("eval.indu.tran.bus")}
                    </label>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ display: "inline-block", m: "0.5rem" }}>
                    <label className="custom-radio">
                      <input
                        type="checkbox"
                        value={"airports"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setFormDetails((prevFormDetails) => ({
                            ...prevFormDetails,
                            transportation: isChecked
                              ? [
                                  ...prevFormDetails.transportation,
                                  e.target.value,
                                ]
                              : prevFormDetails.transportation.filter(
                                  (x) => x !== e.target.value
                                ),
                          }));
                        }}
                      />
                      <span className="checkmark"></span>
                      {t("eval.indu.tran.air")}
                    </label>
                  </Box>
                  <Box
                    sx={{
                      display: "inline-block",
                      m: "0.5rem",
                    }}
                  >
                    <label className="custom-radio">
                      <input
                        type="checkbox"
                        value={"ports"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setFormDetails((prevFormDetails) => ({
                            ...prevFormDetails,
                            transportation: isChecked
                              ? [
                                  ...prevFormDetails.transportation,
                                  e.target.value,
                                ]
                              : prevFormDetails.transportation.filter(
                                  (x) => x !== e.target.value
                                ),
                          }));
                        }}
                      />
                      <span className="checkmark"></span>
                      {t("eval.indu.tran.port")}
                    </label>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "95%",
              display: "flex",
              justifyContent: "center",
              mt: "1rem",
            }}
          >
            <Button
              onClick={HandleFormSubmittedDetails}
              variant="contained"
              sx={{
                borderRadius: "12px",
                padding: "0.3em 5em",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                bgcolor: theme.palette.gold.main,
                mt: "1rem",
                color: "#fff",
              }}
            >
              {t("eval.button")}
            </Button>
          </Box>
        </Box>
      </div>
    </ScrollAnimation>
  );
}

// <Box sx={{display:"flex",justifyContent:'space-around',width:"100%"}}>

// </Box>

// <Box>

// </Box>
