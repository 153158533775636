export const Contact_EN_json = {
    title: "Contact Us",
    content: `Contact
    us via phone, email, or visit our office for personalized
    assistance in finding your perfect property.`,
    name: "Enter your name",
    email: "Enter your email",
    des: "Description",
    button: "Submit",



}


// export const Contact_AR_json = {
//     title: "اتصل بنا",
//     content:`اتصل بنا عبر الهاتف أو البريد الإلكتروني أو قم بزيارة مكتبنا للحصول على معلومات شخصية
//     المساعدة في العثور على الممتلكات المثالية الخاصة بك.`,
//     name: "أدخل أسمك",
//     email: "أدخل بريدك الإلكتروني",
//     des: "وصف العقار",//n
//     button: "يُقدِّم",

// }

export const Contact_AR_json = {
    title: "اتصل بنا",
    content: `اتصل بنا عبر الهاتف أو البريد الإلكتروني أو قم بزيارة مكتبنا للحصول على مساعدة شخصية في العثور على العقار المثالي لك.`,
    name: "أدخل اسمك",
    email: "أدخل بريدك الإلكتروني",
    des: "الوصف",
    button: "إرسال",
};
