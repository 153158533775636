// English
export const Home_EN_json = {
    homeSection: {
        MainTitle: "Everything you need in the Kuwaiti real estate market",
        searchPropertyFilters: {


            type: "Property For",
            location: "Add Location",
            category: "Property Type",
            search: "Search Property",
            sell: "Buy",
            rent: "Rent",
            home: "Home",
            office: "Office",
            land: "Land",
        }
    },
    evaluation: {
        title: "Property Evaluation",
        content: `Property evaluation is a critical component in the realm of real
          estate, encompassing various methodologies and factors to
          determine the fair market value of a property. Whether it's for
          selling, renting, or investing, property evaluation serves as a
          fundamental tool for both buyers and sellers to make informed
          decisions. In property evaluation, numerous aspects come into
          play. Firstly, the location of the property holds paramount
          importance. Factors such as neighborhood desirability, proximity
          to amenities, schools, transportation, and market trends
          significantly influence the property's value. A property located
          in a prime location with access to essential services and
          facilities tends to command higher prices in the market.`,
        subContent: "Get AI Expert to evaluate it before you",
        buy: "Buy",
        or: "or",
        sell: "Sell",
        btnTitle: "Request a Valuation"
    },
    card: {
        sell: {
            title: "Sell property",
            heading: "Your Dream Home Awaits!",
            button: "See Your options"
        },
        rent: {
            title: "Rent property",
            heading: "Comfort and Convenience!",
            button: "Find Rentals"
        },
        buy: {
            title: "Buy property",
            heading: "Get Your Dream Home Today!",
            button: "Browse Homes"
        }
    },
    app: {
        title: "Aldera one app for everything your property needs.",
        content: "Your all-in-one property app. Buy, sell or rent with ease. Search listings, manage applications, and connect with agents/landlords - all in one place. Download Aldera and simplify your property journey!"
    },
    list: {
        rentTitle: "Latest properties on Rent",
        sellTitle: "Latest properties on Sell",
        more: "More",
    },
};

// Arabic
export const Home_AR_json = {
    homeSection: {
        MainTitle: "كل ما تحتاجه في السوق العقاري الكويتي",
        searchPropertyFilters: {
            type: "ملكية لـ",
            location: "إضافة الموقع",
            category: "نوع العقار",
            search: "بحث الملكية",
            sell: "يشتري",
            rent: "إيجار",
            home: "بيت",
            office: "مكتب",
            land: "أرض",
        },
    },
    evaluation: {
        title: "تقييم الممتلكات",
        content: `يعد تقييم الممتلكات عنصرًا حاسمًا في عالم العقارات، وتشمل مختلف المنهجيات والعوامل تحديد القيمة السوقية العادلة للعقار. سواء كان ذلك من أجل بيع أو تأجير أو استثمار، يعد تقييم الممتلكات أداة أساسية لكل من المشترين والبائعين لاتخاذ قرارات مستنيرة. في تقييم الممتلكات، تأتي جوانب عديدة للعب دورها. أولاً، موقع العقار له أهمية قصوى. عوامل مثل رغبة الحي والقرب من المرافق والمدارس ووسائل النقل واتجاهات السوق تؤثر بشكل كبير على قيمة العقار. العقار الموجود في موقع متميز مع إمكانية الوصول إلى الخدمات الأساسية والمرافق يميل إلى فرض أسعار أعلى في السوق.`,
        subContent: "احصل على خبير الذكاء الاصطناعي لتقييمه أمامك",
        sell: "يبيع",
        buy: "يشتري",
        or: "أو",
        btnTitle: "طلب التقييم",
    },
    card: {
        sell: {
            title: "بيع العقار",
            heading: "منزلك المثالي في انتظارك!",
            button: "استعرض خياراتك"
        },
        rent: {
            title: "استئجار العقار",
            heading: "الراحة والملاءمة!",
            button: "ابحث عن الإيجارات"
        },
        buy: {
            title: "شراء العقار",
            heading: "احصل على منزلك المثالي اليوم!",
            button: "تصفح المنازل"
        }
    },

    app: {
        title: "تطبيق ألديرا واحد لكل ما يحتاجه عقارك.",
        content: "تطبيقك العقاري الشامل. شراء، بيع، أو استئجار بكل سهولة. ابحث في القوائم، وقم بإدارة التطبيقات، وتواصل مع الوكلاء/الملاك - كل ذلك في مكان واحد. قم بتنزيل ألدرة  وقم بتبسيط رحلتك العقارية!"
    }, list: {
        rentTitle: "أحدث العقارات للإيجار",
        sellTitle: "أحدث العقارات للبيع",
        more: "المزيد",
    },

};
