import axios from "axios";
import AppURL from "./AppApis";
import { toast } from "react-toastify";

class ApiHelper {
  constructor() {
    this.baseUrl = "https://api.aldera.app/api";
    this.bU = "https://api.aldera.app";
    this.AppURL = AppURL();

    axios.interceptors.request.use(function (config) {
      let userInfo;
      try
      {
        userInfo = JSON.parse(localStorage.getItem("userInfo"));
      } catch (error)
      {
        userInfo = null;
      }
      config.headers.Authorization = userInfo
        ? `Bearer ${userInfo?.token}`
        : "";
      return config;
    });
  }

  // registe Api

  async RegisterCustomer(data) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.SIGN_UP}`,
        data
      );
      if (res?.data?.s === 1)
      {
        return res;
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error)
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
    }
  }

  async RegisterAgent(data, isGoogleLogin) {
    const formData = new FormData();
    formData.append("role", data?.role);
    formData.append("login_type", isGoogleLogin ? 1 : 2);
    formData.append("f_name", data?.firstName);
    formData.append("l_name", data?.lastName);
    formData.append("email", data?.email);
    formData.append("phone_no", data?.phoneNumber);
    formData.append("country_code", data?.country_code);
    formData.append("civil_id", data?.civilId);
    if (!isGoogleLogin)
    {
      formData.append("password", data?.password);
    }
    formData.append("agent_description", data?.description);
    formData.append("profile", data?.image);
    formData.append("agency_id", data?.agency_id);
    // --------------------------------------------------------------------------
    try
    {
      const res = await axios.post(`${this.baseUrl}${this.AppURL.SIGN_UP}`, formData);
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
    }
  }

  async RegisterAgency(data, isGoogleLogin) {
    const formData = new FormData();
    formData.append("role", data.role);
    formData.append("login_type", isGoogleLogin ? 1 : 2);
    formData.append("company_name", data.companyName);
    formData.append("email", data.email);
    formData.append("phone_no", data.phoneNumber);
    formData.append("country_code", data?.country_code);
    formData.append("website", data.companyWebsite);
    formData.append("company_address", data.companyAddress);
    formData.append("commercial_license", data.commercialLicense);
    formData.append("electronic_number", data.electronicNumber);
    formData.append("agency_license", data.agencyLicense);
    formData.append("agency_logo", data.logo);
    if (!isGoogleLogin)
    {
      formData.append("password", data?.password);
    }
    formData.append("agency_description", data.description);
    // -------------------------------------------------------------------------
    try
    {
      const res = await axios.post(`${this.baseUrl}${this.AppURL.SIGN_UP}`, formData);
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }

    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
    }

  }

  //commen Api

  async fetchAgencyList() {
    try
    {
      const res = await axios.get(`${this.baseUrl}${this.AppURL.AGENCY_LIST}`);
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }

    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
    }
  }

  async fetchPropertyCategoryList() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.PROPERTY_CATEGORY_LIST}`
      );

      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
    }
  }
  async fetchPropertyTypeList() {
    const token = localStorage.getItem("token");
    try
    {
      if (token)
      {
        const res = await axios.get(
          `${this.baseUrl}${this.AppURL.PROPERTY_TYPE_LIST}`
        );

        if (res?.data?.s === 1)
        {
          return res
        } else
        {
          toast.error(res?.data?.m)
        }
      } else
      {
        const guestRes = await this.guest();
        localStorage.setItem("userInfo", JSON.stringify(guestRes));
        localStorage.setItem("token", guestRes.token);
        return this.fetchPropertyTypeList();
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.fetchPropertyTypeList();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  //user api

  async login(data) {
    try
    {
      const res = await axios.post(`${this.baseUrl}${this.AppURL.LOGIN}`, data);
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
    }

  }

  async fetchUserDetailsById(id) {
    try
    {

      const res = await axios.post(`${this.baseUrl}${this.AppURL.USERDETAILS_BY_ID}`, { id: id });
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.fetchUserDetailsById(id);
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async DeleteUserAccount() {
    try
    {

      const res = await axios.post(`${this.baseUrl}${this.AppURL.DELETE_ACCOUNT}`);
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
    }
  }

  async UpdateUserProfile(data) {
    const formData = new FormData();
    formData.append("f_name", data.firstName);
    formData.append("l_name", data.lastName);
    formData.append("phone_no", data.phoneNumber);
    formData.append("address", data.address);
    formData.append("profile", data.profile);

    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.UPADATE_USER_PROFILE}`,
        formData
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.UpdateUserProfile();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async loginUserDetails() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.LOGIN_USER_DETAILS}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.loginUserDetails();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message)
      }
    }
  }

  async Get_User_Property_History_List() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.GET_USER_PROPERTY_LIST}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.Get_User_Property_History_List();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async Get_User_Property_Requested_History_List() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.GET_USER_REQUESTED_PROPERTY_LIST}`
      );
      if (res?.data?.s === 1)
      {
        return res;
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.Get_User_Property_Requested_History_List();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async Get_User_Property_History_RENT_List() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.GET_USER_PROPERTY_RENT_LIST}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.Get_User_Property_History_RENT_List();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }


  async Get_User_Property_History_sell_List() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.GET_USER_PROPERTY_SELL_LIST}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.Get_User_Property_History_sell_List();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async Request_To_User_Property() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.REQUEST_TO_USERS_PROPERTY}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.Request_To_User_Property();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }


  async guest() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.LOGIN_AS_GUEST}`
      );

      if (res?.data?.s === 1)
      {
        return res.data.r
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  //Propert APi
  async getPropertyList(count) {
    const token = localStorage.getItem("token");

    try
    {
      if (token)
      {
        const res = await axios.get(
          `${this.baseUrl}${this.AppURL.GET_PROPERTY_LIST}?count=${count}`
        );
        if (res?.data?.s === 1)
        {
          return res
        } else
        {
          toast.error(res?.data?.m)
        }
      } else
      {
        const guestRes = await this.guest();
        localStorage.setItem("userInfo", JSON.stringify(guestRes));
        localStorage.setItem("token", guestRes?.token);
        return this.getPropertyList(count);
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.getPropertyList(count);
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async getPropertyListByFilter(data) {
    const token = localStorage.getItem("token");
    try
    {
      if (token)
      {

        const res = await axios.post(
          `${this.baseUrl}${this.AppURL.SEARCH_PROPERTY_WITH_FILTER}`,
          data
        );
        if (res?.data?.s === 1)
        {
          return res
        } else
        {
          toast.error(res?.data?.m)
        }
      } else
      {

        const guestRes = await this.guest();
        localStorage.setItem("userInfo", JSON.stringify(guestRes));
        localStorage.setItem("token", guestRes?.token);
        return this.getPropertyListByFilter(data);
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.getPropertyListByFilter();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async get_RENT_PropertyList(count) {
    const token = localStorage.getItem("token");
    try
    {
      if (token)
      {
        const res = await axios.get(
          `${this.baseUrl}${this.AppURL.GET_PROPERTY_LIST_RENT}&count=${count}`
        );
        if (res?.data?.s === 1)
        {
          return res
        } else
        {
          toast.error(res?.data?.m)
        }
      } else
      {
        const guestRes = await this.guest();
        localStorage.setItem("userInfo", JSON.stringify(guestRes));
        localStorage.setItem("token", guestRes.token);
        return this.get_RENT_PropertyList(count);
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.get_RENT_PropertyList(count);
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async get_SELL_PropertyList(count) {
    const token = localStorage.getItem("token");
    try
    {
      if (token)
      {
        const res = await axios.get(
          `${this.baseUrl}${this.AppURL.GET_PROPERTY_LIST_SELL}&count=${count}`
        );
        if (res?.data?.s === 1)
        {
          return res
        } else
        {
          toast.error(res?.data?.m)
        }
      } else
      {
        const guestRes = await this.guest();
        localStorage.setItem("userInfo", JSON.stringify(guestRes));
        localStorage.setItem("token", guestRes.token);
        return this.get_SELL_PropertyList(count);
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.get_SELL_PropertyList(count);
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async getPropertyDetailsByID(id) {
    const token = localStorage.getItem("token");
    try
    {
      if (token)
      {

        const res = await axios.get(
          `${this.baseUrl}${this.AppURL.PROPERTY_DETAILS}?id=${id}`
        );
        if (res?.data?.s === 1)
        {
          return res
        } else
        {
          toast.error(res?.data?.m)
        }
      } else
      {
        const guestRes = await this.guest();
        localStorage.setItem("userInfo", JSON.stringify(guestRes));
        localStorage.setItem("token", guestRes?.token);
        return this.getPropertyDetailsByID(id);
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.getPropertyDetailsByID();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async AddProperty(data) {
    const formData = new FormData();
    formData.append("city", data.city);
    formData.append("description", data.description);
    formData.append("no_bed", data.bedroom);
    formData.append("no_parking", data.parking);
    formData.append("location", data.location);
    formData.append("no_tv", data.tv);
    formData.append("price", data.Price);
    formData.append("property_for", data.propertyFor);
    formData.append("province", data.province);
    formData.append("property_type", data.property);
    formData.append("no_bath", data.bathroom);
    formData.append("lat", data.lat);
    formData.append("lng", data.lng);
    formData.append("building_condition", data.condition);
    formData.append("renovation", data.renovation);
    formData.append("major_road", data?.majorRoad);
    formData.append("near_by_transport", data.transportation);

    // --------------------------------- Media Data append --------------------------------------------

    try
    {
      //add property details
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.PROPERTY_ADD}`,
        formData
      );
      // if property id getting from added proprty response
      if (res && res?.data?.s === 1 && res.data.r)
      {
        const prop_id = res?.data?.r;
        const mediaData = new FormData();
        mediaData.append("property_id", prop_id);

        const imageArray = data?.image ? Array.from(data.image) : [];
        for (let i = 0; i < imageArray.length; i++)
        {
          mediaData.append("image", imageArray[i]);
          mediaData.append("image_thumb", imageArray[i]);
        }

        const VideoArray = data?.video ? Array.from(data.video) : [];
        for (let i = 0; i < VideoArray.length; i++)
        {
          mediaData.append("video", VideoArray[i].video);
          mediaData.append("video_thumb", VideoArray[i].video_thumb);
        }

        const catArr = VideoArray.map((i) => i.categories_id);

        mediaData.append("categories_id", JSON.stringify(catArr));
        const result = await axios.post(
          `${this.baseUrl}${this.AppURL.ADD_PROPERTY_MEDIA}`,
          mediaData
        );
        if (result.data?.s === 1)
        {
          return result;
        }
      } else if (res?.data?.m)
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async SendPropertyRequest(id) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.SEND_PROPERTY_REQUEST}`,
        { property_id: id }
      );

      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.SendPropertyRequest();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  //Conatct-help form data api
  async Forgot_password(email) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.FORGOT_PASSWORD}`,
        { email: email }
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }


  async ContactHelpRequst(data) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.CONTACT_HELP_FORM}`,
        data
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.ContactHelpRequst(data);
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }

  async SubscribeUser(email) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.NEWSLETTER_SUBSCRIBE}`,
        email
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }
  async GetNotification() {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.NOTIFICATION}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        const result = await this.RefetchToken();
        result && this.GetNotification();
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }


  // PROPERTY SCREEN APi's


  async PropertyChatRequest(data) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.PROPERTY_CHAT_REQUEST}`,
        { ...data }
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  //------------------------------  CHAT API 

  async GetChatUserId(userId) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.GET_USER_ID}`,
        { user_id: userId }
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async GetChatList() {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.GET_CHAT_LIST}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async SendMsg(data) {
    try
    {
      const res = await axios.post(`${this.baseUrl}${this.AppURL.SEND_MSG}`, data);
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async Delete_Chat(CId) {
    try
    {
      const res = await axios.post(`${this.baseUrl}${this.AppURL.DELETE_CHAT}`, { chat_id: CId });
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }


  async Delete_MSG(data) {
    try
    {
      const res = await axios.post(`${this.baseUrl}${this.AppURL.DELETE_MSG}`, { ...data });
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async GetUserChatMsg(chatId, count) {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.GET_MSG}?chat_id=${chatId}&count=${count}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }


  async UnreadCountHandler(chatId, msgID) {
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.UNREAD_MSG_COUNT}`, { chat_id: chatId, chat_msg_id: msgID }
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async updatePropertyStatus(id) {
    try
    {
      const res = await axios.get(
        `${this.baseUrl}${this.AppURL.PROPERTY_STATUS}?id=${Number(id)}`
      );
      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async fetchPropertyByLocation(count, loc) {
    try
    {
      let url = `${this.baseUrl}${this.AppURL.GET_PROPERTY_LIST}?count=${count}&lat=${loc?.lat}&lng=${loc?.lng}`;

      const res = await axios.get(url);

      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }


  async fetchPropertyByString(count, loc) {
    try
    {
      let url = `${this.baseUrl}${this.AppURL.GET_PROPERTY_LIST}?count=${count}&search=${loc?.search}`;

      const res = await axios.get(url);

      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async fetchPropertyByFilter(count, data) {
    try
    {
      let url = `${this.baseUrl}${this.AppURL.GET_PROPERTY_LIST}?count=${count}&min_price=${data?.min}&max_price=${data?.max}`;

      const res = await axios.get(url);

      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async updateLanguageChange(lng) {
    try
    {
      let url = `${this.baseUrl}${this.AppURL.LOCALIZATION}`;

      const res = await axios.post(url, { language: lng });

      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }

  async SET_FCM_token(data) {
    try
    {
      let url = `${this.baseUrl}${this.AppURL.FCN_TOKEN}`;

      const res = await axios.post(url, { ...data });

      if (res?.data?.s === 1)
      {
        return res
      } else
      {
        toast.error(res?.data?.m)
      }
    } catch (error)
    {
      console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
    }
  }






  // refresh Token Api

  async RefetchToken() {
    let userInfo;
    try
    {
      userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } catch (error)
    {
      userInfo = null;
    }
    const RefreshToken = userInfo?.refreshToken;
    try
    {
      const res = await axios.post(
        `${this.baseUrl}${this.AppURL.REGENERAT_TOKEN}`,

        {
          headers: { Authorization: "Bearer " + RefreshToken },
        }
      );

      res?.data?.s === 1 &&
        localStorage.setItem("userInfo", res?.data?.r);
      if (res?.data?.r)
      {
        return true;
      } else
      {
        toast.info("Something Went wrong");
      }
    } catch (error)
    {
      if (error?.response?.status === 401)
      {
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        // toast.info("Welcome back ! Please Login Your self");
        return;
      } else
      {
        console.log(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
      }
    }
  }
}

const apiHelper = new ApiHelper();
export default apiHelper;