export const AddProperty_EN_json = {
    tagline: "Add Your Property: Showcase Your Listing to Potential Buyers and Renters",
    title: "Add New Property",
    subhead: "Empower Your Property: Seamlessly Add Your Listing and Elevate its Visibility.",
    id: "Auto Generated ID*",
    idPlace: "Generated ID",
    property: "Property*",
    propertyPlace: "Select Property",
    home: "Home",
    ofc: "Office",
    land: "Land",
    bed: "No. of bedroom",
    bedPlace: "no of bedroom",
    bath: "No. of bathroom",
    bathPlace: "no. of bathroom",
    parking: "No. of Parking",
    parkingPlace: "no of Parking",
    tv: "No. of TV",
    tvPlace: "no of tv",
    provience: "Province*",
    proviencePlace: "Add Province",

    city: "City*",
    cityPlace: "Enter city",
    for: {
        title: "Property for",
        sell: "Sell",
        rent: "Rent"
    },
    condition: {
        title: "Building Condition",
        bad: "Bad",
        good: "Good",
        exce: "Excellent",
    },
    location: "Location*",
    locationPlace: "Select location",
    photo: {
        title: "Photos",
        place: "{{count}} image Selected",
        button: "Browse",
        close: "Close",
        msg: " * Please select atleast 5 images.",
        dragActive: "Drop the files here..."
    },
    video: {
        title: "Videos",
        place: "{{count}} video selected",
        button: "Browse",
        close: "Close",
        msg: "* Please select category wise Video.",
    },
    price: "Price",
    pricePlace: "Enter price",
    descr: "Description of property",
    descrPlace: "Description ...",
    trans: {
        title: "Near by transportation",
        highway: "Highways",
        bus: "Bus",
        airports: "Airports",
        ports: "Ports",
    },
    reno: {
        title: "Recent Renovation",
        yes: "Yes",
        no: "No"
    },
    road: {
        title: "Access to major road",
        yes: "Yes",
        no: "No"
    },
    button: "Add Property"
};
// export const AddProperty_AR_json = {
//     tagline: "أضف عقارك: اعرض قائمتك للمشترين المحتملين و المستأجرين",
//     title: "إضافة عقار جديد",//n
//     subhead: "تمكين الممتلكات الخاصة بك: قم بإضافة قائمتك والارتقاء بها بسلاسة  إمكانية رؤيتها.",
//     id: "رمز التعريف التلقائي*",//n
//     property: "عقار",//n
//     propertyPlace: "حدد خاصية",
//     home: "الرئيسية",//n
//     ofc: "مكتب",
//     land: "أرض",
//     bed: "عدد غرف النوم",//n
//     bedPlace: "عدد غرف النوم",//n
//     bath: "عدد الحمامات",//n
//     bathPlace: "عدد الحمامات",//n
//     parking: "عدد مواقف السيارات",//n
//     parkingPlace: "عدد مواقف السيارات",//n
//     tv: "رقم التلفاز",
//     tvPlace: "لا من التلفزيون",
//     provience: "المحافظة*",//n
//     proviencePlace: "المحافظة*",//n
//     city: "المدينة*",//n
//     cityPlace: "أدخل المدينة",
//     for: {
//         title: "عقار لل",//n
//         sell: "يشتري",//n
//         rent: "إيجار"//n
//     },
//     condition: {
//         title: "حالة المبنى",//n
//         bad: "سيء",//n
//         good: "جيد",//n
//         exce: "ممتاز",//n
//     },
//     location: "الموقع*",//n
//     locationPlace: "اختر موقعا",
//     photo: {
//         msg: "* الرجاء اختيار 5 صور على الأقل.",
//         title: "اختيار الصورة",//n
//         place: "{{count}} صورة مختارة",
//         button: "تصفح",//n
//         close: "يغلق",
//         dragActive: "ضع الملفات هنا..."
//     },
//     video: {
//         title: "أشرطة فيديو",
//         msg: "* يرجى تحديد فئة الفيديو الحكيمة.",
//         place: "تم تحديد {{count}} فيديو",
//         button: "تصفح",//n
//         close: "يغلق",
//     },
//     price: "السعر*",//n
//     pricePlace: "أدخل السعر",
//     descr: "وصف العقار",//n
//     descrPlace: "وصف ...",
//     trans: {
//         title: "قرب المواصلات",//n
//         highway: "طريق سريع",//n
//         bus: "محطة باصات",//n
//         airports: "مطارات",//n
//         ports: "موانئ",//n
//     },
//     reno: {
//         title: "تجديدات حديثة:",//n
//         yes: "نعم",//n
//         no: "لا"//n
//     },
//     road: {
//         title: "مخرج/مدخل إلى طريق رئيسي:",//n
//         yes: "نعم",//n
//         no: "لا"//n
//     },
//     button: "إضافة عقار"//n
// };
export const AddProperty_AR_json = {
    tagline: "أضف عقارك: اعرض قائمتك للمشترين المحتملين والمستأجرين",
    title: "إضافة عقار جديد",
    subhead: "تمكين ممتلكاتك: قم بإضافة قائمتك ورفع مستوى رؤيتها بسلاسة.",
    id: "رمز التعريف التلقائي*",
    idPlace: "رمز التعريف",
    property: "عقار*",
    propertyPlace: "حدد نوع العقار",
    home: "منزل",
    ofc: "مكتب",
    land: "أرض",
    bed: "عدد غرف النوم",
    bedPlace: "عدد غرف النوم",
    bath: "عدد الحمامات",
    bathPlace: "عدد الحمامات",
    parking: "عدد مواقف السيارات",
    parkingPlace: "عدد مواقف السيارات",
    tv: "عدد أجهزة التلفاز",
    tvPlace: "عدد أجهزة التلفاز",
    provience: "المحافظة*",
    proviencePlace: "أدخل المحافظة",
    city: "المدينة*",
    cityPlace: "أدخل المدينة",
    for: {
        title: "العقار من أجل",
        sell: "بيع",
        rent: "إيجار"
    },
    condition: {
        title: "حالة المبنى",
        bad: "سيء",
        good: "جيد",
        exce: "ممتاز",
    },
    location: "الموقع*",
    locationPlace: "اختر موقعًا",
    photo: {
        title: "الصور",
        place: "{{count}} صورة مختارة",
        button: "تصفح",
        close: "إغلاق",
        msg: "* الرجاء اختيار 5 صور على الأقل.",
        dragActive: "أسقط الملفات هنا..."
    },
    video: {
        title: "الفيديوهات",
        place: "{{count}} فيديو مختار",
        button: "تصفح",
        close: "إغلاق",
        msg: "* يرجى اختيار الفيديو حسب الفئة."
    },
    price: "السعر",
    pricePlace: "أدخل السعر",
    descr: "وصف العقار",
    descrPlace: "أدخل الوصف ...",
    trans: {
        title: "قرب وسائل النقل",
        highway: "طرق سريعة",
        bus: "محطات الحافلات",
        airports: "مطارات",
        ports: "موانئ"
    },
    reno: {
        title: "تجديدات حديثة",
        yes: "نعم",
        no: "لا"
    },
    road: {
        title: "الوصول إلى الطرق الرئيسية",
        yes: "نعم",
        no: "لا"
    },
    button: "إضافة عقار"
};
