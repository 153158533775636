import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NoData, SearchSVG } from "../../common/Svg";
import { useTranslation } from "react-i18next";
import apiHelper from "../../common/API/ApiHelper";
import PropertyCard from "./PropertyCard";
import { useInView } from "react-intersection-observer";
import { LanguageContext } from "../../Context/LanguageContext";
import debounce from "lodash.debounce";
import SearchPropertyByLocation from "./Prop_Location_modal";
import FilterMenuComponent from "./FilterModal";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import AllPropertyScreen from "./All/AllPropertyScreen";
import RentPropertyScreen from "./Rent/RentPropertyScreen";
import SellPropertyScreen from "./Sell/SellPropertyScreen";

const StyledTab = styled(Tab)(({ theme, themeMode, selected }) => ({
  borderRadius: "12px",
  textTransform: "capitalize",
  margin: "0.2rem 1rem 0.2rem 0.1rem",
  borderBottom: "none",
  height: "100%",
  minWidth: "60px",
  padding: "0px 8px",
  boxSizing: "border-box",
  minHeight: "44px",
  borderL: "solid red",
  // width: "60px",
  backgroundColor:
    themeMode === "light"
      ? selected
        ? theme.palette.primary.main
        : theme.palette.secondary.main
      : selected
      ? theme.palette.gold.main
      : theme.palette.secondary.main,
  color:
    themeMode === "light"
      ? selected
        ? `${theme.palette.secondary.main} !important`
        : theme.palette.primary.main
      : selected
      ? theme.palette.dark.main
      : theme.palette.primary.main,
  boxShadow: `3px 3px 3px 0px ${
    theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.15)" : "#DBDBDB25"
  }`,
  "&:hover": {
    backgroundColor:
      themeMode === "light"
        ? selected
          ? theme.palette.primary.main
          : theme.palette.secondary.main
        : selected
        ? theme.palette.gold.main
        : theme.palette.secondary.main,
    color:
      themeMode === "light"
        ? selected
          ? theme.palette.secondary.main
          : theme.palette.primary.main
        : selected
        ? theme.palette.dark.main
        : theme.palette.primary.main,
    border: "none",
    outline: !selected && `1px solid ${theme.palette.dark.main}`,
  },
}));

let dataLength = 0;
export default function PropertyScreenCopy(props) {
  const { themeMode } = props;
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [priceRange, setPriceRange] = useState({
    min: 0,
    max: 0,
  });
  const [pathname, setPathname] = useState();
  const [findByString, setfindByString] = useState("");
  const [data, setdata] = useState([]);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  const [loading, setloading] = useState(false);
  const [searchView, setSearchView] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const { ref, inView } = useInView();
  const [address, setAddress] = useState();
  const [latlng, setLatLng] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    const id = Number(location?.search?.split("?value=")[1]);
    id && setValue(id);
  }, [location]);

  const handleAddress = (address) => {
    setAddress(address);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateLatLng = (latLngs) => {
    setLatLng({ ...latlng, lat: latLngs?.lat, lng: latLngs?.lng });
  };

  // handle get property with price filter
  const handleFilter = async (count = 0) => {
    try {
      priceRange.min > 0 && priceRange.max > 0 && setSearchView(true);
      if (priceRange.min <= 0 || priceRange.max <= 0) {
        return toast.error(
          language === "ar"
            ? "الرجاء إدخال سعر صالح."
            : "Please enter valid price."
        );
      } else {
        setloading(true);
        const res = await apiHelper.fetchPropertyByFilter(count, priceRange);
        if (res?.data?.r.length > 0 && res.status === 200) {
          setMoreData(true);
          if (count !== 0) {
            setdata((prev) => [...prev, ...res?.data?.r]);
          } else {
            setdata(res.data.r);
          }
        } else {
          setMoreData(false);
          if (count === 0) {
            setdata([]);
          }
        }
        setloading(false);
        if (priceRange.min === 0 && priceRange.max === 0) {
          setSearchView(false);
        }
        setloading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePropertySearchByLocation = async (latlng, count = 0) => {
    try {
      setloading(true);
      const propertyType = pathname?.split("/")[2];
      const locdata = {
        proprty_for: propertyType === "sell" ? 1 : 2,
        lat: latlng?.lat?.toFixed(2),
        lng: latlng?.lng?.toFixed(2),
      };

      locdata?.lat !== "" && setSearchView(true);
      const res = await apiHelper.fetchPropertyByLocation(count, locdata);
      if (res?.data?.r.length > 0 && res.status === 200) {
        setMoreData(true);
        if (count !== 0) {
          setdata((prev) => [...prev, ...res?.data?.r]);
        } else {
          setdata(res.data.r);
        }
      } else {
        setMoreData(false);
        if (count === 0) {
          setdata([]);
        }
      }
      setloading(false);
      if (latlng.lat.toString().length === 0) {
        setSearchView(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePropertySearchByString = async (text, count = 0) => {
    try {
      setloading(true);
      const propertyType = pathname?.split("/")[2];
      const locdata = {
        proprty_for: propertyType === "sell" ? 1 : 2,
        search: text,
      };
      locdata?.search !== "" && setSearchView(true);
      const res = await apiHelper.fetchPropertyByString(count, locdata);
      if (res?.data?.r.length > 0 && res.status === 200) {
        setMoreData(true);
        if (count !== 0) {
          setdata((prev) => [...prev, ...res?.data?.r]);
        } else {
          setdata(res.data.r);
        }
      } else {
        setMoreData(false);
        if (count === 0) {
          setdata([]);
        }
      }
      if (text.length === 0) {
        setSearchView(false);
      }
      setloading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedHandleSearchByString = debounce(
    handlePropertySearchByString,
    500
  );

  useEffect(() => {
    findByString?.length && debouncedHandleSearchByString(findByString);
    return () => {
      debouncedHandleSearchByString.cancel();
    }; //eslint-disable-next-line
  }, [findByString]);

  useState(() => {
    findByString?.length && setSearchView(findByString?.length > 0);
  }, [findByString]);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    if (data.length > 28 && inView && moreData) {
      //eslint-disable-next-line
      dataLength = data?.length;
      findByString.length > 0
        ? handlePropertySearchByString(dataLength)
        : handlePropertySearchByLocation(dataLength);
    } //eslint-disable-next-line
  }, [inView]);

  useEffect(() => {
    latlng.lat !== 0 && handlePropertySearchByLocation(latlng); //eslint-disable-next-line
  }, [latlng]);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.secondary.main,
        pt: { xs: "5.8rem", md: "5.8rem" },
        position: "relative",
      }}
    >
      <Container maxWidth={"xxl"}>
        <Box
          sx={{
            width: {
              xs: "100%",
            },
            display: "flex",
            justifyContent: { xs: "start", md: "space-between" },
            flexDirection: { xs: "column", md: "row" },
            position: "sticky",
            top: { xs: "5.8rem", md: "5.8rem" },
            p: "0.2rem",
            zIndex: 1,
            bgcolor: theme.palette.secondary.main,
            mb: "0.5rem",
            pb: "0.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0.3rem 0",
              height: "100%",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor={theme.palette.secondary.main}
              // textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                // mb: 2,
                // border: "solid red",
                width: "100%",
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledTab
                label={t("property.type.all")}
                selected={value === 0}
                themeMode={themeMode}
              />
              <StyledTab
                label={t("property.type.rent")}
                selected={value === 1}
                themeMode={themeMode}
              />
              <StyledTab
                label={t("property.type.sell")}
                selected={value === 2}
                themeMode={themeMode}
              />
            </Tabs>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              mt: { xs: "0.5rem", sm: 0 },
              flexDirection: { xs: "row", sm: "row" },
              justifyContent: { xs: "start", md: "center" },
              alignItems: "center",
              ml: { md: "auto" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FilterMenuComponent
                setPriceRange={setPriceRange}
                priceRange={priceRange}
                handleFilter={handleFilter}
              />
            </Box>
            <Box>
              <SearchPropertyByLocation
                address={address}
                handleAddress={handleAddress}
                updateLatLng={updateLatLng}
                textColor={theme.palette.light.main}
                color={"white"}
                setSearchView={setSearchView}
              />
            </Box>
            <Box>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchSVG color={theme.palette.dark.main} />
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
                type="text"
                id="findByLocation"
                name="findByLocation"
                sx={{
                  width: { xs: "100%", sm: "220px" },
                  bgcolor: theme.palette.newBg_footer.main,
                  boxShadow: `3px 3px 3px 0px ${
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.15)"
                      : "#DBDBDB25"
                  }`,
                  border: `1px solid ${
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.15)"
                      : "#DBDBDB25"
                  }`,
                  borderRadius: "12px",
                  fontSize: "20px",
                  fontWeight: "500",
                  " .MuiOutlinedInput-input": {
                    textAlign: language === "ar" ? "right" : "left",
                  },

                  "& .MuiOutlinedInput-input": {
                    padding: "8px ",
                  },
                  "& fieldset": { border: "none" },
                }}
                value={findByString}
                onChange={(e) => setfindByString(e.target.value)}
                placeholder={t("property.type.find")}
              />
            </Box>
            <Button
              sx={{
                cursor: "pointer",
                borderRadius: "12px",
                textTransform: "none",
                boxShadow: `3px 3px 3px 0px ${
                  theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.15)"
                    : "#DBDBDB25"
                }`,
                display:
                  !searchView && priceRange.min < 1 && priceRange.max < 1
                    ? "none"
                    : "block",
              }}
              onClick={() => {
                setPriceRange({ ...priceRange, max: 0, min: 0 });
                setLatLng({ ...latlng, lat: 0, lng: 0 });
                setfindByString("");
                setAddress("");
                setSearchView(false);
              }}
            >
              {language === "ar" ? "واضح" : "Clear"}
            </Button>
          </Box>
        </Box>

        <Box>
          {!searchView ? (
            <Box>
              {value === 0 && <AllPropertyScreen />}
              {value === 1 && <RentPropertyScreen />}
              {value === 2 && <SellPropertyScreen />}
            </Box>
          ) : data.length > 0 ? (
            <Box
              sx={{
                minHeight: `calc(100vh - 172px)`,
                pb: "1rem",
                display: "block",
              }}
            >
              <Grid container spacing={"1.5rem"}>
                {data?.map((x, i) => (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <PropertyCard x={x} />
                  </Grid>
                ))}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "grey",
                  mt: "1rem",
                }}
                ref={ref}
              >
                {loading ? (
                  <CircularProgress color="error" />
                ) : (
                  !moreData && "No more data."
                )}
              </Box>
            </Box>
          ) : loading ? (
            <Box
              sx={{
                minHeight: `calc( 100vh - 172px)`,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <Box
              sx={{
                minHeight: `calc(100vh - 172px)`,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NoData />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
