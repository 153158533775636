import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import InputComponent from "../../../component/Input/InputComponentTextField";
import formValidator from "../../../common/Validator";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";

export default function CommercialEvaluation() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [formDetails, setFormDetails] = useState({
    area: "",
    RentalIncome: "",
    parkingSpace: "",
    occupancyRates: "",
    location: "",
    economyFactors: "",
    regulationsAndPermition: "",
    buildingCondition: "",
    competition: "",
  });

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const HandleFormSubmittedDetails = () => {
    try {
      setIsSubmitted(true);
      const validationResult = formValidator(
        formDetails,
        "commercialEvalution",
        "",
        t
      );
      if (validationResult.length > 0) {
        return setValidationError(validationResult);
      }
      if (!formDetails.buildingCondition)
        return toast.error(
          language === "en"
            ? "select Building Condition fields"
            : "حدد حقول حالة المبنى"
        );

      if (!formDetails.competition)
        return toast.error(
          language === "en" ? "select Compitition fields" : "حدد حقول المنافسة"
        );
      navigate(Path.PROPERTY_EVALUATION_COMMERCIAL__CALC);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "residentEvalution",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  return (
    <ScrollAnimation animateIn="fadeIn">
      <div id="commercial" style={{ padding: "1rem" }}>
        <Typography
          sx={{
            fontWeight: "600",
            color: theme.palette.primary.main,
          }}
          variant="text"
        >
          {t("eval.msg")}
        </Typography>
        <Box sx={{ pt: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "0", sm: "1rem" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="area"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "area" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "area")?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="area"
                  value={formDetails.area}
                  placeholder={t("eval.comm.area")}
                  // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="RentalIncome"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "RentalIncome" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "RentalIncome")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="RentalIncome"
                  value={formDetails.RentalIncome}
                  placeholder={t("eval.comm.rentIncom")}
                  // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="parkingSpace"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "parkingSpace" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "parkingSpace")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="parkingSpace"
                  value={formDetails.parkingSpace}
                  placeholder={t("eval.comm.pSpace")}
                  // style={{ color: "red" }}
                />
              </Box>
            </Box>

            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="location"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "location" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "location")?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="location"
                  value={formDetails.location}
                  placeholder={t("eval.comm.location")}
                  // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="occupancyRates"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "occupancyRates" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "occupancyRates")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="occupancyRates"
                  value={formDetails.occupancyRates}
                  placeholder={t("eval.comm.oRate")}
                  // style={{ color: "red" }}
                />
              </Box>

              <Box
                sx={{
                  mb: "0.7em",
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <InputComponent
                  type="text"
                  name="economyFactors"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "economyFactors" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "economyFactors")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="economyFactors"
                  value={formDetails.economyFactors}
                  placeholder={t("eval.comm.economy")}
                  // style={{ color: "red" }}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="text"
              name="regulationsAndPermition"
              onChange={(e) => {
                handleFormDetails(e);
                if (isSubmitted) {
                  const validationResult = formValidator(
                    formDetails,
                    "commercialEvalution",
                    "",
                    t
                  );
                  setValidationError(validationResult);
                }
              }}
              isError={validationError?.find((x) => {
                return x.key === "regulationsAndPermition" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "regulationsAndPermition")
                  ?.message
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: {
                  xs: "100%",
                },
                borderRadius: "12px",
              }}
              id="regulationsAndPermition"
              value={formDetails.regulationsAndPermition}
              placeholder={t("eval.comm.rp")}
            />
          </Box>

          <Box
            sx={{
              width: {
                xs: "100%",
              },
              display: "flex",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
              justifyContent: {
                xs: "center",
                md: "space-between",
              },
              gap: "1rem",
            }}
          >
            <Box>
              <Box>{t("eval.comm.condition.title")}</Box>
              <Box sx={{ mt: "0.5rem", display: "flex", gap: "1rem" }}>
                <Box sx={{ display: "inline-block", mt: "0.5rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="buildingCondition"
                      onChange={handleFormDetails}
                      value={"bad"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.comm.condition.bad")}
                  </label>
                </Box>

                <Box sx={{ display: "inline-block", mt: "0.5rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="buildingCondition"
                      onChange={handleFormDetails}
                      value={"good"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.comm.condition.good")}
                  </label>
                </Box>

                <Box sx={{ display: "inline-block", mt: "0.5rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="buildingCondition"
                      onChange={handleFormDetails}
                      value={"excellent"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.comm.condition.exc")}
                  </label>
                </Box>
              </Box>
            </Box>

            <Box sx={{ mt: { xs: "1rem", md: "0" } }}>
              <Box>{t("eval.comm.comp.title")}</Box>
              <Box sx={{ mt: "0.5rem", display: "flex", gap: "1rem" }}>
                <Box sx={{ display: "inline-block", mt: "0.5rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="competition"
                      onChange={handleFormDetails}
                      value={"high"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.comm.comp.high")}
                  </label>
                </Box>

                <Box
                  sx={{ display: "inline-block", mt: "0.5rem", ml: "0.5rem" }}
                >
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="competition"
                      onChange={handleFormDetails}
                      value={"low"}
                    />
                    <span className="checkmark"></span>
                    {t("eval.comm.comp.low")}
                  </label>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "95%",
              display: "flex",
              justifyContent: "center",
              mt: "1rem",
            }}
          >
            <Button
              onClick={HandleFormSubmittedDetails}
              variant="contained"
              sx={{
                borderRadius: "12px",
                padding: "0.3em 5em",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                bgcolor: theme.palette.gold.main,
                mt: "1rem",
                color: "#fff",
              }}
            >
              {t("eval.button")}
            </Button>
          </Box>
        </Box>
      </div>
    </ScrollAnimation>
  );
}
