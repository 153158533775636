// English
export const Property_EN_json = {
    card: {
        bed: "Bed",
        bath: "Bath",
        parking: "Parking",
        button: "Details",
        sell: "Buy",
        rent: "Rent",
        sold: "Sold",
        rented: "Rented",
    },
    type: {
        all: "All",
        sell: "Buy",
        rent: "Rent",
        lcBtn: "Location",
        find: "Find out",
    }
};

// Arabic
export const Property_AR_json = {
    card: {
        bed: "سرير",
        bath: "حمام",
        parking: "موقف سيارات",
        button: "تفاصيل",
        sell: "يشتري", // updated from "يبيع"
        rent: "إيجار", // updated from "إيجار" with a line break
        sold: "مُباع",
        rented: "مستأجرة",
    },
    type: {
        all: "الجميع",
        sell: "يشتري", // updated from "يبيع"
        rent: "إيجار", // updated from "إيجار" with a line break
        lcBtn: "موقع",
        find: "اكتشف",
    }
};
