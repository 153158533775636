import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import CustomerLoginDialogue from "../CustomerLoginDialogue";
import { LanguageContext } from "../../../Context/LanguageContext";

function PendigScreen({ handleOpen }) {
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: `calc(100vh)`,
        bgcolor: theme.palette.navbarBG.main,
      }}
    >
      <Box sx={{ width: { xs: "80%", md: "40%" } }}>
        <img
          src="/assets/image/sign/verify.svg"
          height={"100%"}
          width={"100%"}
          alt="Application approved."
          srcSet=""
        />
        <Typography
          textAlign={"center"}
          color={"grey"}
          fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.4rem" }}
        >
          {language === "ar"
            ? "التحقق من الحساب معلق."
            : "Account verification pending."}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              padding: "0.5rem",
              borderRadius: "12px",
              background: "#1f2438",
              color: "#EEE",
              textAlign: "center",
              cursor: "pointer",
              mt: "1rem",
              boxSizing: "border-box",
              width: "200px",
            }}
            fontSize={"1.2rem"}
          >
            <CustomerLoginDialogue
              handleOpen={handleOpen}
              displayName={`Login`}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default PendigScreen;
