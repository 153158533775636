import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { Box, TextField, useTheme } from "@mui/material";
import { LanguageContext } from "../../Context/LanguageContext";
// import { toast } from "react-toastify";
// import Slider from "@mui/material/Slider";

export default function FilterMenuComponent({
  priceRange,
  setPriceRange,
  handleFilter,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const { language } = React.useContext(LanguageContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          borderRadius: "12px",
          boxShadow: `3px 3px 3px 0px ${
            theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.15)" : "#DBDBDB25"
          }`,
          fontSize: "1.1ram",
          "&.MuiButtonBase-root": {
            padding: "10px",
          },
        }}
      >
        <FilterListRoundedIcon />
        {/* <img
          src="./assets/image/property/filter.png"
          style={{ height: "20px", width: "20px" }}
          alt=""
          srcSet=""
        /> */}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "12px",
            mt: "0.5rem",
          },
          "& .MuiMenu-list": {
            p: "0px",
          },
        }}
      >
        <Box
          sx={{
            p: "0.5rem 1rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            minWidth: "220px",
            gap: "0.5rem",
            background: theme.palette.secondary.main,
          }}
        >
          <Box>
            <p style={{ margin: "0.5rem 0 0 0 " }}>
              {language === "ar" ? "أدخل النطاق السعري" : "Enter price range"}
            </p>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <TextField
                // label="min-Price"
                placeholder={language === "ar" ? "دقيقة " : "min"}
                type="number"
                id="min"
                name="min"
                autoFocus={true}
                sx={{
                  // "& fieldset": { borderRadius: "12px" },
                  "& fieldset": {
                    border: "none",
                    borderBottom: "1px solid grey",
                  },

                  width: "100px",
                  "& .MuiOutlinedInput-input": {
                    padding: "10px ",
                  },
                }}
                // onChange={(e) => {
                //   if (e.target.value > -1) {
                //     setPriceRange({ ...priceRange, min: e.target.value });
                //   } else {
                //     setTimeout(() => {
                //       return toast.error("Please enter valid price.");
                //     }, 500);
                //   }
                // }}
                onChange={(e) => {
                  setPriceRange({ ...priceRange, min: e.target.value });
                }}
              />
              -
              <TextField
                type="number"
                id="max"
                placeholder={language === "ar" ? "الأعلى" : "max"}
                name="max"
                sx={{
                  width: "100px",
                  "& fieldset": {
                    border: "none",
                    borderBottom: "1px solid grey",
                  },

                  "& .MuiOutlinedInput-input": {
                    padding: "10px ",
                  },
                }}
                // onChange={(e) => {
                //   if (e.target.value > -1) {
                //     setPriceRange({ ...priceRange, max: e.target.value });
                //   } else {
                //     setTimeout(() => {
                //       return toast.error("Please enter valid price.");
                //     }, 500);
                //   }
                // }}
                onChange={(e) => {
                  setPriceRange({ ...priceRange, max: e.target.value });
                }}
              />
            </Box>
          </Box>
          {/* <Box sx={{ width: "100%", p: "1rem" }}>
            <PriceRangeSlider />
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
              p: "0.5rem 0",
            }}
          >
            <Button
              sx={{
                bgcolor: theme.palette.gold.main,
                color: "#EEE",
                textTransform: "none",
                borderRadius: "12px",
                padding: "0.1rem 2rem",
                fontSize: "16px",

                "&:hover": {
                  bgcolor: theme.palette.gold.main,
                },
              }}
              variant="outlined"
              onClick={() => {
                handleFilter();
                handleClose();
              }}
            >
              {language === "ar" ? "منقي" : "Filter"}
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
}

// function valuetext(value) {
//   return `${value}°C`;
// }
// function PriceRangeSlider() {
//   const [value, setValue] = React.useState([20, 37]);
//   console.log(value);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Box sx={{ width: "100%" }}>
//       <label htmlFor="">Price</label>
//       <Slider
//         getAriaLabel={() => "Temperature range"}
//         value={value}
//         onChange={handleChange}
//         valueLabelDisplay="auto"
//         getAriaValueText={valuetext}
//         min={0}
//         max={1000000000}
//         step={1000}
//       />
//     </Box>
//   );
// }
