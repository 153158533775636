import { useTheme } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import SingInForm from "./SignInFormUI";
import Path from "../../common/Path";
import { useNavigate } from "react-router-dom";

export default function SignInScreen({
  googleLoginData,
  SetIsLoginGuest,
  isLogInGuest,
  setuserInfo,
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    let data;
    try {
      data = JSON.parse(localStorage.getItem("userInfo"));
    } catch (error) {
      data = null;
    }
    if (data && data?.role !== 4) {
      navigate(Path.HOME);
    }
  }, []);
  return (
    <Box
      sx={{
        bgcolor: theme.palette.secondary.main,
        // minHeight: `calc(100vh - 85px)`,
      }}
    >
      <Container maxWidth={"xxl"}>
        <Box
          sx={{
            justifyContent: "center",
            display: {
              xs: "flex",
            },
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
              mt: "6em",
              // gap: "3em",
              width: "100%",
              height: `calc(100vh - 96px)`,
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                // alignItems: "end",
                justifyContent: "center",
                width: "22%",
                height: `calc(100vh - 250px )`,
                alignSelf: "end",
                pb: "1rem",
              }}
            >
              <img src="/assets/image/sign/man.svg" alt="" srcSet="" />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  md: "start",
                },
                width: {
                  xs: "100%",
                  sm: "90%",
                  md: "78%",
                },
              }}
            >
              <SingInForm
                setuserInfo={setuserInfo}
                SetIsLoginGuest={SetIsLoginGuest}
                isLogInGuest={isLogInGuest}
                googleLoginData={googleLoginData}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
