import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import { useState } from "react";
import AgentFormComponent from "./user/AgentFormComponent";
import AgencyFormComponent from "./user/AgencyFormComponent";
import CustomerFormComponent from "./user/CustomerFormComponent";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SingInForm({
  setuserInfo,
 
  googleLoginData,
  SetIsLoginGuest,
  isLogInGuest,
}) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        // padding:`0px !important`
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
              // background:"red",
            },
          }}
          sx={{
            "& .MuiTabs-flexContainer": {
              justifyContent: { md: "start", sm: "center" },
              // border: "1px solid red",
            },
            // border: "1px solid red",
          }}
        >
          <Tab
            sx={{
              textTransform: "capitalize",
              boxShadow: "4px 4px 13px 0px rgba(0, 0, 0, 0.15)",
              fontSize: "1rem",
              bgcolor:
                value === 0 ? theme.palette.gold.main : theme.palette.bg.main,
              borderRadius: "12px",

              padding: "0.5em 4em",
              m: "0.9em",
            }}
            style={{
              color: value === 0 ? "#fff" : theme.palette.primary.main,
            }}
            label={t("regi.customer.title")}
            {...a11yProps(0)}
          />

          <Tab
            sx={{
              textTransform: "capitalize",
              boxShadow: "4px 4px 13px 0px rgba(0, 0, 0, 0.15);",
              bgcolor:
                value === 1 ? theme.palette.gold.main : theme.palette.bg.main,

              borderRadius: "12px",

              padding: "0.5em 4em",
              m: "0.9em",
            }}
            label={t("regi.agent.title")}
            style={{
              color: value === 1 ? "#fff" : theme.palette.primary.main,
            }}
            {...a11yProps(1)}
          />

          <Tab
            sx={{
              textTransform: "capitalize",
              boxShadow: "4px 4px 13px 0px rgba(0, 0, 0, 0.15);",
              bgcolor:
                value === 2 ? theme.palette.gold.main : theme.palette.bg.main,
              borderRadius: "12px",
              padding: "0.5em 4em",
              m: "0.9em",
            }}
            label={t("regi.agency.title")}
            style={{
              color: value === 2 ? "#fff" : theme.palette.primary.main,
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CustomerFormComponent
          SetIsLoginGuest={SetIsLoginGuest}
          isLogInGuest={isLogInGuest}
          googleLoginData={googleLoginData}
        
          setuserInfo={setuserInfo}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AgentFormComponent
          SetIsLoginGuest={SetIsLoginGuest}
          isLogInGuest={isLogInGuest}
          googleLoginData={googleLoginData}
         
          setuserInfo={setuserInfo}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <AgencyFormComponent
          SetIsLoginGuest={SetIsLoginGuest}
          isLogInGuest={isLogInGuest}
          googleLoginData={googleLoginData}
        
          setuserInfo={setuserInfo}
        />
      </CustomTabPanel>
    </Box>
  );
}
