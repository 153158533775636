import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import apiHelper from "../../../common/API/ApiHelper";
import Path from "../../../common/Path";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext } from "react";
import { LanguageContext } from "../../../Context/LanguageContext";

import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function DeleteChatModal({ color, setselectedChatId }) {
  const [open, setOpen] = useState(false);
  const { ChatFunction } = useContext(LanguageContext);
  const { t } = useTranslation();
  const location = useLocation();
  const query = location.search;
  const params = new URLSearchParams(query);
  const chatId = params.get("chatId");
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChatDelete = async () => {
    try {
      const res = await apiHelper.Delete_Chat(chatId);
      res?.data?.s === 1 && navigate(Path.CHAT);
      ChatFunction?.GetUserChatList();
      setselectedChatId("");
      toast.success(`${t("chat.delete.done")}`);
      handleClose();
    } catch (error) {}
  };

  return (
    <Fragment>
      <Box
        onClick={() => {
          setOpen(true);
          //   handleToggleDrawer && handleToggleDrawer();
        }}
      >
        <MoreVertIcon style={{ color: color, cursor: "pointer" }} />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 5,
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            backdropFilter: "blur(3px)",
          },
        }}
      >
        <Box sx={{ bgcolor: "#FFF", borderRadius: "12px" }}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "colomn",
              }}
            >
              <img
                style={{ aspectRatio: "1.1", borderRadius: "50%" }}
                src="/assets/image/delete.svg"
                width={"80px"}
                height={"85px"}
                alt=""
                srcSet=""
              />
            </Box>
            <Typography
              sx={{
                pr: "1rem",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "22px",
                color: "#1f2438",
              }}
            >
              {t("chat.delete.title")}
            </Typography>
            <Typography
              sx={{ textAlign: "center", pr: "5px", color: "#1f2438" }}
            >
              {t("chat.delete.content")}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Box
              sx={{
                minWidth: {
                  xs: "100%",
                  sm: " 280px",
                },
                display: {
                  xs: "block",
                  md: "flex",
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <Box
                  sx={{
                    mb: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    variant="outlined"
                    sx={{
                      width: "160px",
                      bgcolor: "#EEE",
                      color: "#1f2438",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#EEE",
                      },
                      borderRadius: "12px",
                    }}
                  >
                    {t("chat.delete.cancle")}
                  </Button>
                  <Button
                    onClick={handleChatDelete}
                    variant="outlined"
                    sx={{
                      width: "160px",
                      bgcolor: "#A65353",
                      color: "#FFF",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#A65353",
                      },
                      borderRadius: "12px",
                    }}
                  >
                    {t("chat.delete.delete")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
}
