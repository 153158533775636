import { useTheme } from "@mui/material/styles";
import { Box, Button, Container, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import ScrollAnimation from "react-animate-on-scroll";

import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";

export default function EvalutionSection() {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Box>
      <Container maxWidth={"xxl"} sx={{ bgcolor: theme.palette.bg.main }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: { xs: "1rem", sm: "3rem", md: "4rem" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "95%",
                md: "45%",
              },
              paddingRight: "3rem",
              // textAlign:,
              textAlign:
                language === "en" ? { xs: "justify", sm: "left" } : "right",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <ScrollAnimation animateIn="animate__pulse">
              <Box>
                <Typography
                  variant="subHeading"
                  sx={{
                    fontWeight: "600",
                    display: "block",
                    mb: "19px",
                    color: theme.palette.dark.main,
                  }}
                >
                  {t("landingPage.evaluation.title")}
                </Typography>
                <Typography
                  // variant="text"
                  fontSize={"17px"}
                  sx={{
                    color: theme.palette.paragraph.main,
                    lineHeight: "22px",
                  }}
                >
                  {t("landingPage.evaluation.content")}{" "}
                </Typography>
                <Box sx={{ pt: "1rem" }}>
                  <Typography
                    // variant="text"
                    fontSize={"17px"}
                    sx={{ color: theme.palette.paragraph.main }}
                  >
                    {t("landingPage.evaluation.subContent")}
                    <span
                      style={{
                        color: theme.palette.dark.main,
                        fontWeight: "600",
                        marginLeft: "0.4rem",
                      }}
                    >
                      {t("landingPage.evaluation.buy")}
                    </span>{" "}
                    {t("landingPage.evaluation.or")}
                    <span
                      style={{
                        color: theme.palette.dark.main,
                        fontWeight: "600",
                        marginLeft: "0.4rem",
                      }}
                    >
                      {t("landingPage.evaluation.sell")}.
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: { xs: "2rem", sm: "3rem" },
                  // border: "1px solid red",
                  display: "flex",
                  justifyContent: language === "en" ? "left" : "right",
                }}
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                    mb: "0.9rem",
                    borderRadius: "12px",
                  }}
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    navigate(Path.PROPERTY_EVALUATION);
                  }}
                >
                  {t("landingPage.evaluation.btnTitle")}
                </Button>
              </Box>
            </ScrollAnimation>
          </Box>
          <ScrollAnimation animateIn="animate__pulse">
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "400px",
                },
                display: { xs: "none", md: "flex" },
                justifyContent: "start",
                mt: {
                  xs: "2em",
                  md: "0px",
                },
                backdropFilter: `blur("6px")`,
              }}
            >
              <img
                src="/assets/image/home/property.png"
                width={"100%"}
                alt=""
                srcSet=""
              />
            </Box>
          </ScrollAnimation>
        </Box>
      </Container>
    </Box>
  );
}
