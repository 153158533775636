import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import InputComponent from "../../Input/InputComponentTextField";
import formValidator from "../../../common/Validator";
import apiHelper from "../../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { baseUrl } from "../../../common/Constant";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import defaultImg from "../../../common/assets/image/User.svg"

export default function UserProfileUpdateDialogue(props) {
  const { handleToggleDrawer, setuserInfo, setuserProfileInfo, userInfo } =
    props;
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [localProfile, setlocalProfile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  const init = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    // address: "",
    profile: "",
  };
  const [formDetails, setFormDetails] = useState(init);

  const handleClickOpen = (e) => {
    setFormDetails(init);
    setFormDetails({
      ...formDetails,
      firstName: userInfo.f_name,
      lastName: userInfo.l_name,
      email: userInfo.email,
      phoneNumber: userInfo.phone_no,
      profile: userInfo?.profile,
    });
    setOpen(true);
  };


  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };

    setFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "updateUser",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setValidationError([]);
    setFormDetails(init);
    setlocalProfile("");
  };

  const updateUserDetails = async () => {
    try {
      setIsSubmitted(true);
      const validationResult = formValidator(formDetails, "updateUser", "", t);
      if (validationResult.length > 0) {
        return setValidationError(validationResult);
      }
      setIsLoading(true);

      const result = await apiHelper.UpdateUserProfile(formDetails);

      if (result && result?.data?.s === 1) {
        toast.success(
          language === "en"
            ? "Account updated successfully"
            : "تم تحديث الحساب بنجاح"
        );
        localStorage.setItem("userInfo", JSON.stringify(result?.data?.r));
        localStorage.setItem("token", result?.data?.r.token);
        setuserInfo(result?.data?.r);
        setuserProfileInfo(result?.data?.r);
        setFormDetails(init);
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      error?.message
        ? console.log(error?.message)
        : console.log(error?.response?.data?.message);
    }
  };

  return (
    <Fragment>
      <Box
        onClick={() => {
          handleClickOpen();
          handleToggleDrawer && handleToggleDrawer();
        }}
      >
        {t("navbar.menu.userProfile.edit.title")}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 5,
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            backdropFilter: "blur(3px)",
          },
          direction: language === "ar" ? "rtl" : "ltr",
        }}
        className={"HideScrollBar"}
      >
        <Box
          sx={{
            bgcolor: theme.palette.secondary.main,
            borderRadius: "15px",
            padding: "1rem",
            fontWeight: "400",
          }}
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "colomn",
                padding: "1rem",
              }}
            >
              <label htmlFor="profile">
                {isImageError ? (
                  <img
                    src={
                      localProfile
                        ? URL.createObjectURL(localProfile)
                        : defaultImg
                    }
                    alt="profileImage"
                    height={"100px"}
                    width={"100px"}
                    onError={() => setIsImageError(true)}
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "0.5px solid #8080804d",
                      padding: "3px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <img
                    src={
                      localProfile
                        ? URL.createObjectURL(localProfile)
                        : formDetails?.profile !== null
                        ? `${baseUrl}/uploads/user/profile/${formDetails?.profile}`
                        : defaultImg
                    }
                    alt="profileImage"
                    height={"100px"}
                    width={"100px"}
                    onError={() => setIsImageError(true)}
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "0.5px solid #8080804d",
                      padding: "3px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </label>
              <input
                type="file"
                name="profile"
                hidden
                accept={"image/*"}
                id="profile"
                onChange={(e) => {
                  if (e.target.files[0].size < 500099) {
                    setFormDetails({
                      ...formDetails,
                      profile: e.target.files[0],
                    });
                    setlocalProfile(e.target.files[0]);
                  } else {
                    return toast.error("Image size should be small in size.");
                  }
                }}
              />
            </Box>
          </DialogTitle>

          <DialogContent>
            <Box
              sx={{
                minWidth: {
                  xs: "100%",
                  sm: " 330px",
                },
                display: {
                  xs: "block",
                  md: "flex",
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                  },
                }}
              >
                <Box sx={{ mb: "1rem", width: "100%" }}>
                  <InputComponent
                    type="text"
                    name="firstName"
                    onChange={handleFormDetails}
                    isError={validationError?.find((x) => {
                      return x.key === "firstName" ? true : false;
                    })}
                    helperText={
                      validationError.find((x) => x.key === "firstName")
                        ?.message
                    }
                    id="firstName"
                    value={formDetails.firstName}
                    placeholder={t("navbar.menu.userProfile.edit.fname")}
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    style={{ color: "#1f2438" }}
                  />
                </Box>

                <Box sx={{ mb: "1rem" }}>
                  <InputComponent
                    value={formDetails.lastName}
                    id="lastName"
                    type="text"
                    name="lastName"
                    onChange={handleFormDetails}
                    isError={validationError?.find((x) => {
                      return x.key === "lastName" ? true : false;
                    })}
                    helperText={
                      validationError.find((x) => x.key === "lastName")?.message
                    }
                    className="form-control"
                    placeholder={t("navbar.menu.userProfile.edit.lname")}
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    style={{ color: "#1f2438" }}
                  />
                </Box>

                <Box sx={{ mb: "1rem" }}>
                  <InputComponent
                    type="email"
                    id="email"
                    name="email"
                    readOnly={true}
                    value={formDetails.email}
                    className="form-control"
                    placeholder={t("navbar.menu.userProfile.edit.email")}
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    style={{ color: "#1f2438" }}
                  />
                </Box>

                <Box sx={{ mb: "1rem" }}>
                  <InputComponent
                    type="number"
                    name="phoneNumber"
                    onChange={handleFormDetails}
                    isError={validationError?.find((x) => {
                      return x.key === "phoneNumber" ? true : false;
                    })}
                    helperText={
                      validationError.find((x) => x.key === "phoneNumber")
                        ?.message
                    }
                    value={formDetails.phoneNumber}
                    id="phoneNumber"
                    className="form-control"
                    placeholder={t("navbar.menu.userProfile.edit.phone")}
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    style={{ color: "#1f2438" }}
                  />
                </Box>

                {/* <Box sx={{ mb: "1rem" }}>
                  <InputComponent
                    type="text"
                    name="address"
                    onChange={handleFormDetails}
                    isError={validationError?.find((x) => {
                      return x.key === "address" ? true : false;
                    })}
                    helperText={
                      validationError.find((x) => x.key === "address")?.message
                    }
                    value={formDetails.address}
                    id="address"
                    className="form-control"
                    placeholder={t("navbar.menu.userProfile.edit.add")}
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    style={{ color: "#1f2438" }}
                  />
                </Box> */}
                <Box
                  sx={{ mt: "1rem", display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      padding: "0.3em 5em",
                      textTransform: "none",
                      bgcolor: theme.palette.gold.main,
                      borderRadius: "12px",
                      fontSize: "1.1rem",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#1f2438",
                      },
                    }}
                    onClick={updateUserDetails}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        {" "}
                        <CircularProgress
                          disableShrink
                          sx={{ color: "#1f2438", margin: "0.2rem" }}
                          size={20}
                        />
                      </>
                    ) : (
                      `${t("navbar.menu.userProfile.edit.button")}`
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
}
