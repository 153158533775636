import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import apiHelper from "../../common/API/ApiHelper";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useTranslation } from "react-i18next";

export default function VideoUploadModal({
  data,
  handleVideoChange,
  removeImage,
}) {
  let userInfo;
  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error) {
    userInfo = null;
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [category, setCategory] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "85%", md: "60%", lg: "50%" },
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    minHeight: `50%`,
    maxHeight: `calc(100vh - 100px)`,
    overflow: "auto",
    boxSizing: "border-box",
    display: loading ? "flex" : "block",
    justifyContent: "center",
    alignItems: "center",
  };

  const fetchCategory = async () => {
    try {
      setLoading(true);
      const res = await apiHelper.fetchPropertyCategoryList();
      if (res?.data?.s === 1 && res?.data?.r?.length > 0) {
        setCategory(res?.data?.r);
      }
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  React.useEffect(() => {
    userInfo && fetchCategory();
  }, []);

  return (
    <div>
      <Button
        component="span"
        sx={{
          textTransform: "capitalize",
          bgcolor: "#1f2438",
          color: "white ",
          borderRadius: "7px",
          padding: "0.01em 1em",
          "&:hover": {
            backgroundColor: "#1f2438",
          },
        }}
        onClick={handleOpen}
      >
        {t("addProperty.video.button")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backdropFilter: "blur(4px)",
          },
        }}
      >
        <Box
          sx={{
            ...style,
            bgcolor: theme.palette.navbarBG.main,
            color: theme.palette.dark.main,
          }}
        >
          {loading ? (
            <Box
              sx={{
                height: "100%",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <CircularProgress disableShrink />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pb: "1rem",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  sx={{ color: "#d32f2fc2", fontSize: "0.9rem" }}
                  variant="h6"
                >
                  {t("addProperty.video.msg")}
                </Typography>

                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "0.3em 1em",
                    textTransform: "capitalize",
                    bgcolor: theme.palette.gold.main,
                    borderRadius: "12px",
                    fontSize: "1.1rem",
                    color: "white",
                  }}
                  onClick={handleClose}
                >
                  {t("addProperty.video.close")}
                </Button>
              </Box>
              <Grid container spacing={2}>
                {category &&
                  category.map((cat) => (
                    <Grid key={cat.id} item xs={6} sm={4} md={3.4} xl={2.5}>
                      <input
                        hidden
                        type="file"
                        id={`fileInput-${cat.id}`}
                        accept={`video/*`}
                        onChange={(e) => {
                          handleVideoChange(e, `video`, cat.id);
                        }}
                      />
                      <label htmlFor={`fileInput-${cat.id}`}>
                        {/* Render based on whether a video exists for the category */}
                        {data &&
                        data?.some((item) => item.categories_id === cat.id) ? (
                          <Box
                            sx={{
                              border: "1px solid #80808054",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: { xs: "100%", sm: "150px" },
                              height: { xs: "150px", sm: "150px" },
                              borderRadius: "12px",
                              minWidth: "120px",
                            }}
                          >
                            <TaskAltIcon sx={{ fontSize: "2rem" }} />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              border: "1px solid #80808054",
                              width: { xs: "100%", sm: "150px" },
                              height: { xs: "150px", sm: "150px" },
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "12px",
                              minWidth: "120px",
                            }}
                          >
                            <AddOutlinedIcon
                              sx={{ fontSize: "3rem", fill: "#80808054" }}
                            />
                          </Box>
                        )}
                      </label>
                      <Box
                        sx={{
                          // border: "1px solid #80808054",
                          width: { xs: "100%", sm: "150px" },
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "12px",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          Add {cat.name} video
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
