import "./App.css";
import "react-phone-input-2/lib/style.css";
import RouteFile from "./common/Route";
import NewNavbar from "./component/Navbar/NewNavbar";
import Footer from "./component/Footer/Footer";
import { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import { theme } from "./common/Theme/Theme";
import { LanguageContext } from "./Context/LanguageContext";
import { ThemeProvider } from "@mui/material";
import apiHelper from "./common/API/ApiHelper";
import 'animate.css/animate.min.css';

let userInfoL;
try
{
  userInfoL = JSON.parse(localStorage.getItem("userInfo"));
} catch (error)
{
  userInfoL = null;
}


export const socket =
  userInfoL &&
  userInfoL.role !== 4 &&
  io.connect("https://api.aldera.app/chat", {
    transports: ["websocket"],
    query: { user_id: userInfoL?.id },
  });




function App() {
  const [themeMode, setThemeMode] = useState();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [userInfo, setuserInfo] = useState();
  const [googleLoginData, setGoogleLoginData] = useState();
  const [isLogInGuest, SetIsLoginGuest] = useState(true);
  const [userProfileFunctions, setUserProfileFunctions] = useState();
  const { NewChatCount, setNewChatCount } = useContext(LanguageContext);

  const handleOpen = () => {
    setOpenLoginModal(true);
  };

  const verifyUserLogin = () => {
    !openLoginModal && handleOpen();
  };

  useEffect(() => {
    let userInfoL;
    try
    {
      userInfoL = JSON.parse(localStorage.getItem("userInfo"));
    } catch (error)
    {
      userInfoL = null;
    }
    const isUserInfoLNull = !userInfoL;
    if (isUserInfoLNull)
    {
      SetIsLoginGuest(true);
      return
    }
    const isGuestUser = userInfoL?.role === 4 && userInfoL?.email === "guestuser@gmail.com";
    const isStatusInactive = userInfoL?.status === 2 || userInfoL?.status === -1 || userInfoL?.status === 0;
    const isEmptyAgencyWithRole1 = userInfoL?.agency?.length === 0 && userInfoL?.role === 1;

    if (isUserInfoLNull || isGuestUser || isStatusInactive || isEmptyAgencyWithRole1)
    {
      SetIsLoginGuest(true);
    } else
    {
      SetIsLoginGuest(false);
    }
  }, [userInfoL]);


  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    if (!(language === "en" || language === "ar"))
    {
      localStorage.setItem("i18nextLng", "en");
    }
  }, []);

  useEffect(() => {
    let storedThemeMode = localStorage?.getItem("themeMode")
    if (!storedThemeMode)
    {
      localStorage.setItem("themeMode", "light");
      storedThemeMode = "light";
    }

    if (storedThemeMode)
    {
      setThemeMode(storedThemeMode);
    }
  }, []);

  useEffect(() => {
    if (socket)
    {
      const handleConnect = () => {
        console.log(socket?.connected && "Socket connected");
      };

      socket?.on("connect", handleConnect);

      const handleDisconnect = () => {
        console.log("Socket disconnected");
      };

      const handleError = (error) => console.error("Socket error:", error);

      socket?.on("disconnect", handleDisconnect);
      socket?.on("connect_error", handleError);

      return () => {
        socket?.off("connect", handleConnect);
        socket?.off("disconnect", handleDisconnect);
        socket?.off("connect_error", handleError);
      }; //eslint-disable-next-line
    }
  }, [socket]);

  const checkForNewChat = async () => {
    try
    {
      const res = await apiHelper.GetChatList();
      const hasUnreadChats = res?.data?.r?.some(
        (chat) => chat?.unread_msg_count > 0
      );
      hasUnreadChats ? setNewChatCount(true) : setNewChatCount(false);
    } catch (error)
    {
      error?.response?.data?.message
        ? console.log(error?.response?.data?.message)
        : console.log(error.message);
    }
  };
  useEffect(() => {
    if (!isLogInGuest && socket)
    {
      !NewChatCount ? checkForNewChat() :
        socket?.on("get-msg", (m) => {
          checkForNewChat()
        })
    }
  }, [socket])

  return (
    <>
      <ThemeProvider
        theme={theme(
          localStorage.getItem("themeMode")
            ? localStorage.getItem("themeMode")
            : localStorage.setItem("themeMode", "light")
        )}
      >
        <NewNavbar
          setuserInfo={setuserInfo}
          userInfo={userInfo}
          openLoginModal={openLoginModal}
          setOpenLoginModal={setOpenLoginModal}
          handleOpen={handleOpen}
          setThemeMode={setThemeMode}
          themeMode={themeMode}

          setGoogleLoginData={setGoogleLoginData}
          SetIsLoginGuest={SetIsLoginGuest}
          isLogInGuest={isLogInGuest}
          setUserProfileFunctions={setUserProfileFunctions}
          userProfileFunctions={userProfileFunctions}
        />
        <RouteFile
          setuserInfo={setuserInfo}
          handleOpen={handleOpen}
          userInfo={userInfo}
          SetIsLoginGuest={SetIsLoginGuest}
          isLogInGuest={isLogInGuest}
          googleLoginData={googleLoginData}

          verifyUserLogin={verifyUserLogin}
          themeMode={themeMode}
          userProfileFunctions={userProfileFunctions}
        />
        <Footer themeMode={themeMode} />
      </ThemeProvider>

    </>
  );
}

export default App;
