import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import PropertyCard from "../Property/PropertyCard";
import Loader from "../../common/Loader";
import { NoData } from "../../common/Svg";
import apiHelper from "../../common/API/ApiHelper";
import { useTheme } from "@mui/material/styles";

import { useInView } from "react-intersection-observer";

let dataLength = 0;
export default function SearchedPropertyScreen() {
  const [fetchProperty, SetFetchedProperty] = useState([]);
  const [loading, setloading] = useState(true);
  const theme = useTheme();
  const [moreData, setMoreData] = useState(false);
  const { ref, inView } = useInView();
  const { type, cat, lat, lng } = useParams();
  const data = {
    category: cat,
    lat: lat,
    lng: lng,
    type: type,
  };

  const getPropertyList = async (count = 0) => {
    try {
      setloading(true);
      const result = await apiHelper.getPropertyListByFilter(data, count);
      if (result?.data?.r && result.status === 200) {
        setMoreData(true);
        if (count !== 0) {
          SetFetchedProperty((prev) =>
            prev.length ? [...prev, ...result?.data?.r] : result?.data?.r
          );
        } else {
          SetFetchedProperty(result.data.r);
        }
      } else {
        setMoreData(false);
      }
      setTimeout(() => {
        setloading(false);
      }, 100);
    } catch (error) {
      toast.error(error);
    }
  };

  // useEffect(() => {
  // }, []);

  useEffect(() => {
    getPropertyList(); //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fetchProperty.length > 28 && inView && moreData) {
      //eslint-disable-next-line
      dataLength = fetchProperty?.length;
      getPropertyList(dataLength);
    } //eslint-disable-next-line
  }, [inView]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  return (
    <>
      {loading && fetchProperty.length === 0 ? (
        <Box
          sx={{
            minHeight: `calc( 100vh)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.dark.main,
          }}
        >
          <Loader />
        </Box>
      ) : fetchProperty.length === 0 && !loading ? (
        <Box
          sx={{
            minHeight: `calc( 100vh)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.dark.main,
          }}
        >
          <NoData />
        </Box>
      ) : (
        <Box
          sx={{
            p: "6rem 1rem 1rem 1rem",
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.dark.main,
            height: `calc(100vh - 112px)`,
            overflow: "scroll",
          }}
        >
          <Grid
            container
            spacing={"1rem"}
            sx={{ minHeight: `calc( 100vh - 154px )`, mt: "0.9rem" }}
          >
            {fetchProperty &&
              fetchProperty.map((x, i) => {
                return (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <PropertyCard x={x} />
                  </Grid>
                );
              })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              mt: "1rem",
            }}
            ref={ref}
          >
            {loading ? (
              <CircularProgress color="error" />
            ) : (
              !moreData && "No more data."
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
